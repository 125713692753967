import React, { Component } from 'react'
import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from '../../../../BaseURL';

export default class AddGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
          category: "",
          option:"",
          cat_id:0,
          image:null,
          status:"",
          imgSrc:"",
        }

      }
      
    on_Change = (e) => {
      this.setState({
        image : e
      })
      console.log("Function executing")
      var file = e;
      var reader = new FileReader();
      console.log(file);
      var url = reader.readAsDataURL(file);

      reader.onloadend = function (e) {
          this.setState({
              imgSrc: [reader.result]
              
          })
        }.bind(this);
      } 
      
      componentDidMount() {
        fetch(API_URL + "/api/categories", {
          method: "get",
          headers:{
            mode: "no-cors",
            Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
            
          }
        }).then((response) => {
          response.json().then((result1) => {
            console.clear();
            console.log("api responsive", result1.categories);
            let arr = result1.categories.map((val, i) => {
              return (
                <option key={i} value={val.id}>
                  {val.name}
                </option>
              );
            });
    
            this.setState({ option: arr });
          });
        });
        //End Fetch
        
        $(".needs-validation").submit((event) => {
          var form = $(event.target);
         
          event.preventDefault();
          event.stopPropagation();
          if (!form[0].checkValidity() === false) {
            event.preventDefault();
            // Assuming only image
            const formData = new FormData();

            formData.append('image', this.state.image)
            formData.append('cat_id', this.state.cat_id)
            formData.append('status', this.state.status)
            axios
              .post(API_URL + "/api/store_images",formData, {
                headers:{
                  mode: "no-cors",
                  Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                  
                }
              })
              .then((response) => {
                // console.log("res", response);
                let status = JSON.stringify(response.status);
                console.clear();
                
                //console.clear();
                console.log(response.data.status);
                if (response.data.status == true) {
                  swal({
                    title: "Done!",
                    text: response.data.message + "",
                    icon: "success",
                    timer: 2000,
                    button: false,
                  });
                  this.props.history.push("/gallery");
                }
              })
    
              .catch(function (error) {
                swal({
                  title: "Opps!",
                  text: error+"",
                  icon: "error",
                  timer: 2000,
                  button: false,
                });
              });
    
            // console.log(this.state);
          }
          form.addClass("was-validated");
        });
      }

       
      
      render() {
      
        return (
          <div className="main-wrapper main-wrapper-1">
            <Header/>
            <Sidebar/>
            <div className="main-content">
              <section className="section">
                <div className="section-body">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Add Gallery</h4>
                        </div>
                        <div className="card-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-9 m-auto">
                                <form
                                  method="POST"
                                  action="#"
                                  className="needs-validation"
                                  enctype="multipart/form-data"
                                  noValidate
                                >
                                  <div className="row">
                                    <div className="form-group col-6 m-auto">
                                      <label htmlFor="frist_name">
                                        Image
                                      </label>
                                      <input
                                        id="frist_name"
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => {
                                          this.on_Change(e.target.files[0])
                                        }}
                                        required
                                      />
                                      <img src={this.state.imgSrc} className="img-responsive" height={200} width={200} />
                                      <div className="invalid-feedback">
                                        Please Upload image file
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-6 m-auto">
                                      <label htmlFor="last_name">Category</label>
                                      <select 
                                        className="form-control"
                                        required
                                        onChange={(event)=>{
                                          this.setState({
                                            cat_id:event.target.value
                                          })
                                        }}
                                        >
                                        <option value="NAN"> Select Category</option>
                                        {this.state.option}
                                      </select>
                                      <div className="invalid-feedback">
                                        Please select category
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-6 m-auto">
                                      <label htmlFor="last_name">Status</label>
                                      <select 
                                        className="form-control" 
                                        required
                                        onChange={(event) => {
                                          this.setState({
                                            status:event.target.value
                                          })
                                        }}
                                        >
                                        <option value=""> Select Status</option>
                                        <option value="active">Yes</option>
                                        <option value="deactive">No</option>
                                      </select>
                                      <div className="invalid-feedback">
                                        Please select status
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-4">
                                    <div className="col-md-6 m-auto ">
                                      <button
                                        type="submit"
                                        className="btn btn-primary btn-lg btn-block"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        );
      }
}
