import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PasswordInput from "react-password-strength-bar-indicator";
import "react-password-strength-bar-indicator/lib/main.css";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import $ from "jquery";
import axios from "axios";
import swal from "sweetalert";
import {API_URL} from "../BaseURL";
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phonenumber: 0,
      roleId: 2,
      password: "",
      passwordStrength: "", // To get the values of strenght
      externalId:0,
      type:"",
    };
  }
  componentDidMount() {
    $(document).ready(() => {
      $(".confirmpaswddiv").hide();

      $("#password2").keyup(function () {
        let pswd = $(".passwowrd-input").val();
        let cpswd = $("#password2").val();
        if (pswd == cpswd) {
          $(".confirmpaswddiv").show();
          $(".confirmpaswddiv").text("Password Match");
          $(".confirmpaswddiv").css("color", "green");
        } else {
          $(".confirmpaswddiv").show();
          $(".confirmpaswddiv").css("color", "red");
        }
      });

      $(".needs-validation").submit((event) => {
        var form = $(event.target);
        console.clear();
        // console.log();
        event.preventDefault();
        event.stopPropagation();
        if (!form[0].checkValidity() === false) {
          event.preventDefault();
          axios
            .post(API_URL+"/api/signup", this.state, {
              mode: "no-cors",
            })
            .then((response) => {
              // console.log("res", response);
              let status = JSON.stringify(response.status);
              // console.log(response.data.status);
              if (response.data.status == 200) {
                localStorage.setItem(
                  "authToken",
                  JSON.stringify(response.token)
                );

                localStorage.getItem("authToken");
                Array.from(document.querySelectorAll("input")).forEach(
                  (input) => (input.value = "")
                );
                swal({
                  title: "Done!",
                  text: "Registration Succesfull",
                  icon: "success",
                  timer: 2000,
                  button: false,
                });
                this.props.history.push("/login");
              }
            })

            .catch(function (error) {
              swal({
                title: "Opps!",
                text: error,
                icon: "error",
                timer: 2000,
                button: false,
              });
            });

          // console.log(this.state);
        }
        form.addClass("was-validated");
      });
    });
  }
  render() {
    const responseFacebook = (response) => {
      
      this.setState({
        firstName:response.first_name,
        lastName:response.last_name,
        email:response.email,
        password:response.userID,
        type:"facebook"
      })
      console.log(response.userID)
      console.log(this.state)
      axios.post(API_URL+"/api/SignUpWithFacebook", this.state, {
          mode: "no-cors",
        })
        .then((response) => {
          // console.log("res", response);
          let status = JSON.stringify(response.status);
          // console.log(response.data.status);
          if (response.data.status == 200) {
            localStorage.setItem("authToken", JSON.stringify(response.token));

            localStorage.getItem("authToken");
            Array.from(document.querySelectorAll("input")).forEach(
              (input) => (input.value = "")
            );
            swal({
              title: "Done!",
              text: "Registration Succesfull",
              icon: "success",
              timer: 2000,
              button: false,
            });
            this.props.history.push("/calendar");
          }
        })

        .catch(function (error) {
          swal({
            title: "Opps!",
            text: error,
            icon: "error",
            timer: 2000,
            button: false,
          });
        });
      console.log(response);
    };
    const responseGoogle = (response) => {
      console.log(response.profileObj)
      this.setState({
        firstName:response.profileObj.familyName,
        lastName:response.profileObj.givenName,
        email:response.profileObj.email,
        password:response.profileObj.googleId,
        type:"google"
      })
      console.log(this.state)
      axios.post(API_URL+"/api/SignUpWithGoogle", this.state, {
          mode: "no-cors",
        })
        .then((response) => {
          console.log("res", response);
          console.log(response.data.status);
          if (response.data.status == 200) {
            localStorage.setItem("authToken", JSON.stringify(response.token));

            localStorage.getItem("authToken");
            Array.from(document.querySelectorAll("input")).forEach(
              (input) => (input.value = "")
            );
            swal({
              title: "Done!",
              text: "Registration Succesfull",
              icon: "success",
              timer: 2000,
              button: false,
            });
            this.props.history.push("/calendar");
          }
        }).catch(function (error) {
          // swal({
          //   title: "Opps!",
          //   text: error,
          //   icon: "error",
          //   timer: 2000,
          //   button: false,
          // });
          alert(error)
        });
    }
    return (
      <section className="section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2">
              <div className="card card-primary">
                <div className="card-header">
                  <h4>Register</h4>
                </div>
                <div className="card-body">
                  <form
                    method="POST"
                    action="#"
                    className="needs-validation"
                    noValidate
                  >
                    <div className="row">
                      <div className="form-group col-6">
                        <label htmlFor="frist_name">First Name</label>
                        <input
                          id="frist_name"
                          type="text"
                          className="form-control"
                          onChange={(event) => {
                            this.setState({ firstName: event.target.value });
                          }}
                          required
                        />
                        <div className="invalid-feedback">
                          Please fill in your first name
                        </div>
                      </div>
                      <div className="form-group col-6">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          id="last_name"
                          type="text"
                          className="form-control"
                          onChange={(event) => {
                            this.setState({ lastName: event.target.value });
                          }}
                          required
                        />
                        <div className="invalid-feedback">
                          Please fill in your last name
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-6">
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          onChange={(event) => {
                            this.setState({ email: event.target.value });
                          }}
                          required
                        />
                        <div className="invalid-feedback"></div>
                      </div>
                      <div className="form-group col-6">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          id="phone"
                          type="number"
                          className="form-control"
                          onChange={(event) => {
                            this.setState({ phonenumber: event.target.value });
                          }}
                          required
                        />
                        <div className="invalid-feedback">
                          Please fill in your phone number
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-6">
                        <label htmlFor="password" className="d-block">
                          Password
                        </label>
                        <PasswordInput
                          value={this.state.password}
                          className="form-control"
                          style={{
                            borderRadius: "3px",
                            minWidth: "324px",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                          passwordStrength={(value) =>
                            this.setState({ passwordStrength: value })
                          }
                          placeholder="Password"
                          type="password"
                          required
                        />
                        <div className="invalid-feedback">
                          Please fill in your Password name
                        </div>
                      </div>
                      <div className="form-group col-6">
                        <label htmlFor="password2" className="d-block">
                          Password Confirmation
                        </label>
                        <input
                          id="password2"
                          type="password"
                          className="form-control"
                          name="password-confirm"
                          required
                        />
                        <div className="invalid-feedback">
                          Please fill in your password
                        </div>
                        <div className="confirmpaswddiv">
                          Password Not Match
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                  <div className="text-center mt-4 mb-3">
                    <div className="text-job text-muted">Login With Social</div>
                  </div>
                  <div className="row sm-gutters">
                    <div className="col-6">
                      {/* <a className="btn btn-primary btn-social btn-facebook">
                        <span className="fab fa-facebook" /> Facebook
                      </a> */}
                      <FacebookLogin
                        appId="2950024365323503" //APP ID NOT CREATED YET
                        fields="first_name,last_name,name,email,picture"
                        callback={responseFacebook}
                        className="btn btn-primary btn-social btn-facebook"
                        icon="fa-facebook fa-1x"
                        textButton="  Facebook"
                        cssClass="btn btn-lg btn-primary w-100"
                      />
                    </div>
                    <div className="col-6">
                      {/* <a className="btn  btn-light btn-social btn-twitter">
                        <span className="fab fa-twitter" /> Twitter
                      </a> */}
                      <GoogleLogin
                        clientId="458664555984-7siqcl4o6gfo5313k3hlo9mdc450pv8r.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                        className="btn google-btn btn-light w-100 text-center"
                        disabled={false}
                        theme="dark"
                        icon={true}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                      >
                        
                      </GoogleLogin>
                    </div>
                   
                  </div>
                </div>
                <div className="mb-4 text-muted text-center">
                  Already Registered? <Link to="/login">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(Register);
