import React, { Component } from "react";
import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";

class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:0,
      name:"",
      status:"",
      saloonId: localStorage.getItem("user_id")
    }
  }

  componentDidMount() {
    let saloonId = localStorage.getItem("user_id")
    fetch(API_URL+"/api/category/"+this.props.match.params.id + "?saloon_id=" + saloonId,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
    
          },

        }).then((response)=>{
            response.json().then((result)=>{
              console.clear()  
              console.log("Api Response Iss",result.category)
                this.setState({
                    id : result.category.id,
                    name:result.category.name,
                    status:result.category.status
                })
            });
        }).catch(err=> console.log(err));

      
    $(".needs-validation").submit((event) => {
      var form = $(event.target);
      console.clear();
      // console.log();
      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        event.preventDefault();
        axios
          .post(API_URL+"/api/update_category", this.state, {
            mode: "no-cors",
          })
          .then((response) => {
            let status = JSON.stringify(response.status);
            if (response.data.status == true) {
              swal({
                title: "Done!",
                text: response.data.status + "",
                icon: "success",
                timer: 2000,
                button: false,
              });
              this.props.history.push("/category");
            }
          })

          .catch(function (error) {
            swal({
              title: "Opps!",
              text: error,
              icon: "error",
              timer: 2000,
              button: false,
            });
          });

        // console.log(this.state);
      }
      form.addClass("was-validated");
    });
  }
  render() {
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header/>
        <Sidebar/>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Edit Category</h4>
                    </div>
                    <div className="card-body">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-9 m-auto">
                            <form
                              method="POST"
                              action="#"
                              className="needs-validation"
                              noValidate
                            >
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Category Name
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    value={this.state.name}
                                    onChange={
                                      (event)=>{
                                        this.setState({
                                          name :event.target.value
                                        })
                                      }
                                    }
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your Category name
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="last_name">Status</label>
                                  <select className="form-control"
                                   onChange={(event)=>{
                                     this.setState({
                                       status:event.target.value
                                     })
                                   }}
                                   value={this.state.status}
                                   required>
                                   <option value='active'>Yes</option>
                                    <option value='deactive'>NO</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    Please select status
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-6 m-auto ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default EditCategory;
