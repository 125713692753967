import React, { Component } from "react";
import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import Switch from "react-switch";
import { CirclePicker } from "react-color";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";

export default class AddStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      option: "",
      services: [],
      cat_id: 0,
      image: null,
      status: "",
      imgSrc: "",
      checked: false,
      firstName: "",
      lastName: "",
      staffTitle: "",
      notes: "",
      email: "",
      mobileNumber: "",
      employeeStartDate: "",
      employeeEndDate: "",
      workStartDate: "",
      booking: false,
      serviceCommission: "",
      productCommission: "",
      voucherCommission: "",
      paidCommission: "",
      staffPermission: "",
      color: "",
      service: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  on_Change = (e) => {
    this.setState({
      image: e,
    });
    console.log("Function executing");
    var file = e;
    var reader = new FileReader();
    console.log(file);
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      this.setState({
        imgSrc: [reader.result],
      });
    }.bind(this);
  };

  componentDidMount() {
    const { params } = this.props.match;
    let saloonId = localStorage.getItem("user_id")
    fetch(API_URL + "/api/staff/" + params?.memberId+"?saloon_id=" + saloonId, {
      method: "GET",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((response) => {
      response.json().then((result1) => {
        const staff = result1.staff;
        if (result1.status) {
          this.setState({
            imgSrc: staff.img,
            checked: staff.calendar_bookings == "Yes",
            firstName: staff.first_name,
            lastName: staff.last_name,
            staffTitle: staff.staff_title,
            notes: staff.notes,
            email: staff.email,
            mobileNumber: staff.phone,
            employeeStartDate: staff.start_date,
            employeeEndDate: staff.end_date,
            workStartDate: staff.start_date,
            services: [staff.services],
            booking: staff.calendar_bookings,
            serviceCommission: staff.service_commission,
            productCommission: staff.product_commission,
            voucherCommission: staff.voucher_sales_commission,
            paidCommission: staff.paid_plan_commission,
            staffPermission: staff.staff_permission,
            color: staff.calendar_color ? staff.calendar_color : "",
          });
        }
      });
    });

    fetch(API_URL + "/api/categories", {
      method: "get",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((response) => {
      response.json().then((result1) => {
        let arr = result1.categories.map((val, i) => {
          return (
            <option key={i} value={val.id}>
              {val.name}
            </option>
          );
        });

        this.setState({ option: arr });
      });
    });
    //End Fetch
    // Fetch Services
    fetch(API_URL + "/api/services-list?saloon_id="+saloonId, {
      method: "get",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((response) => {
      response.json().then((result1) => {
        console.log("api responsive service", result1.service);
        let data = result1.service.map((val, i) => {
          //   return val.name;
        });
        this.setState({ service: result1.service });
      });
    });

    // End Fetch Services

    $(".needs-validation").submit((event) => {
      var form = $(event.target);
      let saloonId = localStorage.getItem("user_id")
      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        console.log($(form[0]).find("input"));
        event.preventDefault();
        // Assuming only image
        const formdata = new FormData();
        formdata.append("saloon_id", saloonId);
        formdata.append("first_name", this.state.firstName);
        formdata.append("last_name", this.state.lastName);
        formdata.append("staff_title", this.state.staffTitle);
        this.state.image && formdata.append("profile_pic", this.state.image);
        formdata.append("notes", this.state.notes);
        formdata.append("email", this.state.email);
        this.state.services.forEach(value => {
          // console.log(i,val);
          formdata.append("service_id[]", value);
        });
        this.state.mobileNumber &&
          formdata.append("phone", this.state.mobileNumber);
        formdata.append("start_date", this.state.employeeStartDate);
        formdata.append("end_date", this.state.employeeEndDate);
        formdata.append("calendar_bookings", this.state.checked ? "Yes" : "No");
        formdata.append("calendar_color", this.state.color);
        formdata.append("service_commission", this.state.serviceCommission);
        formdata.append("product_commission", this.state.productCommission);
        formdata.append(
          "voucher_sales_commission",
          this.state.voucherCommission
        );
        formdata.append("paid_plan_commission", this.state.paidCommission);
        formdata.append("staff_permission", this.state.staffPermission);
        console.log(formdata);
        const obj = {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          staff_title: this.state.staffTitle,
          profile_pic: this.state.image ? this.state.image : null,
          notes: this.state.notes,
          email: this.state.email,
          service_id: this.state.services,
          phone: this.state.mobileNumber,
          start_date: this.state.employeeStartDate,
          end_date: this.state.employeeEndDate,
          calendar_bookings: this.state.checked ? "Yes" : "No",
          calendar_color: this.state.color,
          service_commission: this.state.serviceCommission,
          product_commission: this.state.productCommission,
          voucher_sales_commission: this.state.voucherCommission,
          paid_plan_commission: this.state.paidCommission,
          staff_permission: this.state.staffPermission,
        };
        console.log(
          `${API_URL}/api/staff/${params?.memberId ? params?.memberId : ""}`
        );
        console.log(this.state)
        axios
          .post(
            `${API_URL}/api/staff${params?.memberId ? `/${params?.memberId}` : ""
            }`,
            formdata,
            {
              method: "POST",
              headers:{
                mode: "no-cors",
                Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                
              }
            }
          )
          .then((response) => {
          
            let status = JSON.stringify(response.status);
          
            console.log(response.data.status);
            console.log(response.data);
            if (response.data.status == true) {
              swal({
                title: "Done!",
                text: response.data.message + "",
                icon: "success",
                timer: 2000,
                button: false,
              });
              this.props.history.push("/staff-list");
            }
          })

          .catch(function (error) {
            console.log(error);
            swal({
              title: "Opps!",
              text: error + "",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });

        // console.log(this.state);
      }
      form.addClass("was-validated");
    });
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  handleInputChange = (key, value) => {
    this.setState({ [key]: value });
  };

  HandleSelect = (value) => {
    console.log("change handle =>", value);
  };
  render() {
    const { services, service } = this.state;
    console.log(this.select2);
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Add Staff</h4>
                    </div>
                    <div className="card-body">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-10 m-auto">
                            <form
                              className="needs-validation"
                              method="POST"
                              action={"#"}
                              // action={`${API_URL}/api/staff/${params?.memberId ? params?.memberId : ""}`}
                              enctype="multipart/form-data"
                              noValidate
                            >
                              <div className="row">
                                <div className="col-md-4">
                                  {/* <div className="form-group col-4"> */}
                                  <label htmlFor="frist_name">Image</label>
                                  <img
                                    src={this.state.imgSrc}
                                    className="img-responsive"
                                    height={110}
                                    width={140}
                                  />
                                  <div className="invalid-feedback">
                                    Please Upload image file
                                  </div>
                                  <input
                                    id="frist_name"
                                    type="file"
                                    className="form-control"
                                    style={{
                                      border: "0px",
                                    }}
                                    onChange={(e) => {
                                      this.on_Change(e.target.files[0]);
                                    }}
                                  // required
                                  />

                                  {/* </div> */}
                                </div>
                                <div className="col-md-8">
                                  <div className="row">
                                    <div className="form-group col-6 ">
                                      <label htmlFor="last_name">
                                        First name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        required
                                        value={this.state.firstName}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "firstName",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <div className="invalid-feedback">
                                        Please enter first name
                                      </div>
                                    </div>
                                    <div className="form-group col-6 ">
                                      <label htmlFor="last_name">
                                        Last name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "lastName",
                                            e.target.value
                                          )
                                        }
                                        value={this.state.lastName}
                                      />
                                      <div className="invalid-feedback">
                                        Please enter last name
                                      </div>
                                    </div>
                                    <div className="form-group col-12">
                                      <label htmlFor="last_name">
                                        Staff title
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.staffTitle}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "staffTitle",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <div className="invalid-feedback">
                                        Please enter staff title
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label htmlFor="last_name">
                                    Notes (optional)
                                  </label>
                                  <textarea
                                    className="form-control"
                                    value={this.state.notes}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "notes",
                                        e.target.value
                                      )
                                    }
                                    rows={30}
                                  ></textarea>
                                </div>
                              </div>

                              <div className="row border-top mt-4">
                                <div className="col-md-12 text-left">
                                  <h5>Contact</h5>
                                  <p>
                                    Staff contacts are confidential and won't be
                                    shared with your clients.
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <label>Email</label>
                                  <input
                                    className="form-control"
                                    value={this.state.email}
                                    type="email"
                                    required
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "email",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Mobile Number</label>
                                  <input
                                    className="form-control"
                                    value={this.state.mobileNumber}
                                    type="text"
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "mobileNumber",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row border-top mt-4">
                                <div className="col-md-12 text-left">
                                  <h5>Employment</h5>
                                </div>
                                <div className="col-md-6">
                                  <label>Start Date</label>
                                  <input
                                    className="form-control"
                                    type="date"
                                    value={this.state.employeeStartDate}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "employeeStartDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {/* <div className="col-md-6">
                                  <label>End Date</label>
                                  <input className="form-control" type="date" value={this.state.employeeEndDate} onChange={(e) => this.handleInputChange("employeeEndDate", e.target.value)} />
                                </div> */}
                              </div>
                              <div className="row border-top mt-4">
                                <div className="col-md-12 text-left">
                                  <h5>Works at</h5>
                                  <p>
                                    Choose the locations where this staff member
                                    works.
                                  </p>
                                </div>
                                <div className="col-md-12">
                                  <label>Start Date</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={this.state.workStartDate}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "workStartDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row border-top mt-4">
                                <div className="col-md-12 text-left">
                                  <h5>Booking</h5>
                                </div>
                                {/* <div className="row"> */}
                                <div className="col-md-6">
                                  Allow calendar bookings
                                  <br></br>
                                  <small>
                                    Allow this staff member to receive bookings
                                    on the calendar
                                  </small>
                                </div>
                                <div className="col-md-2">
                                  <label>
                                    <Switch
                                      onChange={this.handleChange}
                                      checked={this.state.checked}
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="row border-top mt-4">
                                <div className="mt-2 col-md-12 ">
                                  <h5>Services</h5>
                                  <p>
                                    Add the services this staff member can offer
                                  </p>
                                </div>
                                {/* <div className="row"> */}
                                <div className="col-md-12">
                                  <Select2
                                    multiple
                                    ref={(ref) => this.select2 = ref}
                                    className="form-control"
                                    data={service.map((v) => ({
                                      text: v.name,
                                      id: v.id,
                                      value: v.id,
                                    }))}
                                    value={services}
                                    options={{
                                      placeholder: "Add Service",
                                    }}
                                    onUnselect={(e) => {
                                      console.log("unselect", e.params.data.id);
                                      let index = services.findIndex(x => x == e.params.data.id)
                                      // console.log("index", index);
                                      if (index !== -1) {
                                        services.splice(index, 1)
                                        this.setState({ services })
                                      }
                                    }}
                                    onSelect={(e) => {
                                      let arr = []
                                      $(e.target)
                                        .find("option:selected")
                                        .each((i, ele) => {
                                          arr.push($(ele).attr("value"));
                                        });
                                      this.setState({ services: arr });
                                    }}
                                  // onChange={(e) => this.handleInputChange("firstName", e.target.value)}
                                  />
                                </div>
                                {/* </div> */}
                              </div>
                              <div className="row border-top mt-4">
                                <div className="mt-2 col-md-12 ">
                                  <h5>Calendar colour</h5>
                                  <p>
                                    Choose a colour to see this staff memebers
                                    appointments in the calendar
                                  </p>
                                </div>
                                {/* <div className="row"> */}
                                <div className="col-md-12">
                                  <CirclePicker
                                    onChange={(e) =>
                                      this.handleInputChange("color", e.hex)
                                    }
                                    colors={[
                                      "#37D67A",
                                      "#2CCCE4",
                                      "#555555",
                                      "#dce775",
                                      "#ff8a65",
                                      "#ba68c8",
                                    ]}
                                  />
                                </div>
                                {/* </div> */}
                              </div>
                              <div className="row border-top mt-4">
                                <div className="col-md-12 text-left">
                                  <h5>Commission</h5>
                                  <p>
                                    Choose how much Permission this staff member
                                    makes.
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <label>Service commission</label>
                                  <input
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "serviceCommission",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Product commission</label>
                                  <input
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "productCommission",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Voucher sales commission</label>
                                  <input
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "voucherCommission",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Paid plan commission</label>
                                  <input
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "paidCommission",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="row border-top mt-4">
                                <div className="col-md-12 text-left">
                                  <h5>Permission</h5>
                                  <p>
                                    Choose how much permission this staff member
                                    makes.
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <label>Staff Permission</label>
                                  <select className="form-control">
                                    <option
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          "staffPermission",
                                          e.target.value
                                        )
                                      }
                                      value="NaN"
                                    >
                                      {" "}
                                      Select Access
                                    </option>
                                    <option value="no access">No access</option>
                                    <option value="basic">Basic</option>
                                    <option value="low">Low</option>
                                    <option value="high">High</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-6">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block"
                                  >
                                    Add Staff
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
