import React, { Component, forwardRef } from 'react'
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import Header from '../../../component/Header';
import Sidebar from '../../../component/Sidebar';
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Footer from '../../../component/Footer';
import { NavLink } from 'react-router-dom';
// import userimg from '../../assets/img/user.png'

import './index.css'
import $ from 'jquery'
import axios from 'axios';
class PlanList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      image: [],
      actions: [
        {
          icon: "edit",
          color: "green",
          tooltip: "Edit this user",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "green",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) =>
            this.props.history.push("/edit-staff/" + rowData.id),

        },
        {
          icon: "delete",
          tooltip: "Delete User",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "red",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) => {
            swal({
              title: "Are you sure?",
              text: "Are you sure that you want to delete this user?",
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                //   Delete APi
                fetch(
                  API_URL + "/api/staff/" + rowData.id,
                  {
                    method: "DELETE",
                    headers:{
                      mode: "no-cors",
                      Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                      
                    }
                  }
                ).then((result) => {
                  // console.clear();
                  console.log("Delete", result.status)
                  if (result.status == 0) {
                    swal(
                      "Error!",
                      "Something went wrong .. Please try again!",
                      "error"
                    );
                  }
                  else {
                    swal(
                      "Sucess!",
                      "Category Deleted Successfully !",
                      "success"
                    );
                    this.getData();
                  }
                  // 
                });
              }
            });
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.getData();
    $(document).ready(function () {

      $('button').on('click', function (e) {
        if ($(this).hasClass('list')) {
          $('#container ul').addClass('list').removeClass('grid');
          $('#container ul li').addClass('text-left').removeClass('text-center');
          $('.sidebar-user-details-card').addClass('list-content');

        } else if ($(this).hasClass('grid')) {
          $('#container ul').addClass('grid').removeClass('list');
          $('#container ul li').addClass('text-center').removeClass('text-left');
          $('.sidebar-user-details-card').removeClass('list-content');

        }
      });
    });
  }
  // Fetch Services Function
  getData() {
    fetch(API_URL + "/api/paid/plan/list/"+localStorage.getItem("user_id"), {
      method: 'GET',
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((data) => {
      return data.json()
    }).then((result) => {
      console.log("response of plan is => ", result)
      if (result.status == true) {
        console.log("staffs", result.PaidPlans)
        
        this.setState({
          data: result.PaidPlans
        })
    
      }

    
    });

  }

  deletePost = async (id) => {
    let res = await axios.post(`${API_URL}/api/staff/${id}`, {
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }, {
      method: "POST"
    })

    console.log(res.data)
  }

  render() {
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
    };


    const { data } = this.state
    const { actions } = this.state
    const columns = [
      { title: "Image", field: "profile_pic", render: rowData => <img style={{ border: "2px solid white" }} src={rowData.img} alt="" border="3" width="50" /> },
      { title: "Staff Member Name", field: "full_name" },
      { title: "Email", field: "email" },
      { title: "Calendar Booking", field: "calendar_bookings" },
    ];
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Paid Plan</h4>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-12 text-right">
                          <NavLink to="/add-plan" className="btn btn-primary">Create New Plan</NavLink>
                        </div>
                      </div>
                      <div id="container">
                        
                      <div className="row">
                          {data && data.length > 0 ? data.map((value) => {
                            return (
                            
                            <div className="col-md-6">
                            <NavLink to={"/edit-plan/"+value.id+"/"+value.saloon_id}>
                              <div className="card l-bg-purple">
                                <div className="card-statistic-3">
                                  <div className="card-icon card-icon-large">
                                    <i className="fa fa-globe" />  
                                  </div>
                                  <div className="card-content">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <p className="text-white"><span><svg style={{height:"25px",width:"47px"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path d="M9.333 4.667A4.68 4.68 0 0114 9.333 4.68 4.68 0 019.333 14a4.68 4.68 0 01-4.666-4.667 4.68 4.68 0 014.666-4.666zm.187 2.8H8.4v2.8h2.8v-1.12H9.52v-1.68zM11.2 0c1.03 0 1.867.836 1.867 1.867l-.001 3.266h-.933v-.934L.933 4.2v4.2c0 .479.36.873.825.927l.109.006h1.399v.933h-1.4A1.867 1.867 0 010 8.4V1.867C0 .836.836 0 1.867 0H11.2zm0 .933H1.867a.933.933 0 00-.927.825l-.007.109v1.4l11.2-.001v-1.4A.933.933 0 0011.31.94L11.2.933z" fill="#FFF" fill-rule="evenodd"></path></svg></span>
                                          {value.validity}
                                        </p>
                                      </div>
                                      <div className="col-md-6 text-right">
                                      <h4 className="text-white">{value.price} USD</h4>
                                      </div>
                                    </div>
                                    <h3 className="card-title text-white text-bold">{value.plan_name}</h3>
                                    <h6 className="text-white"><span>{value.session}</span></h6>
                                    <h6 className="text-white mb-0 text-sm">
                                      <span className="text-nowrap">{value.service?.name}</span>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              </NavLink>
                            </div>
                            // </NavLink>
                            )
                          }) :  <div className="col-md-12 text-center">
                                  <p className="bg-warning p-3">
                                    No Plan Available
                                  </p>
                                </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
export default PlanList