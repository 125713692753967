import React, { Component, forwardRef } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import $ from "jquery";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      actions: [
        {
          icon: "visibility",
          color: "green",
          tooltip: "View the user Details",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "green",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) =>
            this.props.history.push("/client-profile/"+ rowData.id),
        },
        {
          icon: "delete",
          tooltip: "Delete User",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "red",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) => {
            swal({
              title: "Are you sure?",
              text: "Are you sure that you want to delete this client?",
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                //   Delete APi
                fetch(
                  API_URL+"/api/destroy/client/" + rowData.id,
                  {
                    method: "GET",
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer'+" "+ JSON.parse(localStorage.getItem('authToken'))
                    },
                  }
                ).then((result) => {
                  // console.clear();
                  console.log("Delete",result.status)
                  if(result.status == 0){
                    swal(
                      "Error!",
                      "Something went wrong .. Please try again!",
                      "error"
                    );
                  }
                  else{
                    swal(
                      "Sucess!",
                      "Client Deleted Successfully !",
                      "success"
                    );
                    this.getData();
                  }
                  // 
                });

                
              }
            });
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.getData();
  }

  // Fetch Categories Function
  getData()
  {
      fetch(API_URL+"/api/clients",{
          method: 'get',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))

            },
      }).then((data)=>{
          data.json().then((result)=>{
            if(result.status == true){
              console.clear()
              this.setState({data:result.clients})
            }
              console.log("Status is " ,result.clients)
           });
      });
  }
  render() {
    
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
    };

    const columns = [
      { title: "Name", field:'first_name' },
      { title: "Mobile Number", field:'mobile'},
      { title: "Email", field:'email'},
      { title: "Gender", field:'gender'},

    ];
    const {data} = this.state;
    // const data = [
    //     {img: <img src="assets/img/user.png" height={50} width={50} class="img-responsive"/>, name : 'John Doe' , mobilenumber:'3330030', email:'john@gmail.com',gender:'male'},
    //     {img: <img src="assets/img/user.png" height={50} width={50} class="img-responsive"/>, name : 'Die Doe' , mobilenumber:'3232330', email:'die@gmail.com',gender:'female'},

    // ];
    const {actions} = this.state
    
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">
            
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Category</h4>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-12 text-right">
                          <NavLink
                            to="/add-client"
                            className="btn btn-primary"
                          >
                            Add new
                          </NavLink>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <MaterialTable
                          title=""
                          data={data ? data : []}
                          columns={columns}
                          actions={actions}
                          icons={tableIcons}
                          options={{
                            actionsColumnIndex: -1,
                            sorting: true,
                          }}
                          onRowClick={(event, rowData) => {
                            this.props.history.push(
                              "/client-profile/"+ rowData.id
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default withRouter(Client);
