import React, { Component } from "react";

import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import { NavLink, withRouter } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
class ClientDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
          id:"",
          firstName:"",
          lastName:"",
          mobile:0,
          telephone:"",
          email:"",
          dob:"",
          
        }
    }
    componentDidMount(){
      fetch(API_URL+"/api/client/"+this.props.match.params.id,
      {
          method:"GET",
          headers:{
            mode: "no-cors",
            Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
            
          }
      }).then((response)=>{
          response.json().then((result)=>{
            console.clear()  
            console.log("Api Response Iss",result.client)
            this.setState({
              id:result.client.id,
              firstName:result.client.first_name,
              lastName:result.client.last_name,
              mobile:result.client.mobile,
              telephone:result.client.telephone,
              email:result.client.email,
              dob:result.client.dob,
            }) 
          });
      }).catch(err=> console.log(err));


        $(".needs-validation").submit((event) => {
            var form = $(event.target);
            console.clear();
            event.preventDefault();
            event.stopPropagation();
            if (!form[0].checkValidity() === false) {
              event.preventDefault();
              axios
                .post(API_URL+"/api/store_category", this.state, {
                  headers:{
                    mode: "no-cors",
                    Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                    
                  }
                })
                .then((response) => {
                  let status = JSON.stringify(response.status);
                  if (response.data.status == true) {
                    swal({
                      title: "Done!",
                      text: response.data.status + "",
                      icon: "success",
                      timer: 2000,
                      button: false,
                    });
                    this.props.history.push("/category");
                  }
                })
    
                .catch(function (error) {
                  swal({
                    title: "Opps!",
                    text: error,
                    icon: "error",
                    timer: 2000,
                    button: false,
                  });
                });
    
              // console.log(this.state);
            }
            form.addClass("was-validated");
          });
    }
  render() {
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">
            <div className="section-body">
  <div className="row mt-sm-4">
    <div className="col-12 col-md-12 col-lg-4">
      <div className="card author-box">
        <div className="card-body">
          <div className="author-box-center">
            <img alt="image" src="../assets/img/users/user-1.png" className="rounded-circle author-box-picture" />
            <div className="clearfix" />
            <div className="author-box-name">
              <a href="#">{this.state.firstName + " " + this.state.lastName}</a>
            </div>
            <div className="author-box-job">
            New client
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 text-right">
              <NavLink to={`/edit-client/${this.state.id}`} className="btn btn-social-icon mr-1 btn-facebook">
                <i className="fa fa-pencil" />
                Edit
              </NavLink>
            </div>
            <div className="col-md-8">
              <a href="#" className="btn btn-social-icon mr-1 btn-twitter">
                <i className="fa fa-add" /> Book an appointment
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4>Personal Details</h4>
        </div>
        <div className="card-body">
          <div className="py-4">
            <p className="clearfix">
              <span className="float-left">
                Birthday
              </span>
              <span className="float-right text-muted">
                {this.state.dob}
              </span>
            </p>
            <p className="clearfix">
              <span className="float-left">
                Phone
              </span>
              <span className="float-right text-muted">
                {this.state.mobile}
              </span>
            </p>
            <p className="clearfix">
              <span className="float-left">
                Mail
              </span>
              <span className="float-right text-muted">
                {this.state.email}
              </span>
            </p>
            
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-12 col-lg-8">
      <div className="card">
        <div className="padding-20">
          {/* <div className="tab-content tab-bordered" id="myTab3Content"> */}
            {/* <div className="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="home-tab2"> */}
              <div className="row">
                <div className="col-md-3 col-6 b-r">
                  <strong>All Booking</strong>
                  <br />
                  <p className="text-muted">10</p>
                </div>
                <div className="col-md-3 col-6 b-r">
                  <strong>Completed</strong>
                  <br />
                  <p className="text-muted">0</p>
                </div>
                <div className="col-md-3 col-6 b-r">
                  <strong>Cancelled</strong>
                  <br />
                  <p className="text-muted">0</p>
                </div>
                <div className="col-md-3 col-6">
                  <strong>No Shows</strong>
                  <br />
                  <p className="text-muted">0</p>
                </div>
              </div>
            {/* </div> */}
           
          </div>
        </div>
        <div className="col-md-12">
        <div className="card">
          <div className="card-body">
          <Tabs>
          <TabList>
            <Tab>Appointments</Tab>
            <Tab>Consultation forms</Tab>
            <Tab>Products</Tab>
            <Tab>Invoices</Tab>
          </TabList>
          <TabPanel>
            <div className="container">
                <div className="row">
                  <div className="col-md-12">
                      <h2>Past (1)</h2>
                      <table className="table">
                        <tr>
                          <td>29 Jun Sun 11:00</td>
                          <td>Blow Dry</td>
                          <td>1h 30min with Carla</td>
                          <td>DKK 0</td>
                        </tr>
                      </table>  
                  </div>
                </div>
            </div>
          </TabPanel>
          </Tabs>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>


        </section>
        </div>
      </div>
    );
  }
}
export default ClientDetail;
