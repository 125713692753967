import React, { Component } from "react";
import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import Switch from "react-switch";
import { CirclePicker } from "react-color";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";

export default class AddPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saloon_id: localStorage.getItem("user_id"),
      plan_name: "",
      description: "",
      service_id: "",
      session: "",
      no_session: "",
      validity: "",
      price: 0,
      color: "",
      rules: "",
      option: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    const { params } = this.props.match;
    fetch(
      API_URL +
        "/api/paid/plan/show/" +
        params?.memberId +
        "/" +
        params?.saloon_id,
      {
        method: "GET",
        headers:{
          mode: "no-cors",
          Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
          
        }
      }
    ).then((response) => {
      response.json().then((result1) => {
        //  ;
        console.log("api responsive", result1);
       
        console.log(result1);
          this.setState({
            plan_name: result1.PaidPlan.plan_name,
            description: result1.PaidPlan.description,
            service_id: result1.PaidPlan.service_id,
            session: result1.PaidPlan.session,
            no_session: result1.PaidPlan.no_session,
            validity: result1.PaidPlan.validity,
            price: result1.PaidPlan.price,
            color: result1.PaidPlan.color,
            rules: result1.PaidPlan.rules,
            option: result1.PaidPlan.service_id,
          });
           
          console.log("store data" , this.state)
        // 
      });
    });

    fetch(API_URL + "/api/categories", {
      method: "get",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((response) => {
      response.json().then((resultcat) => {
        //  ;
        console.log("api cate", resultcat);
        let arr = resultcat.categories.map((val, i) => {
          return (
            <option key={i} value={val.id}>
              {val.name}
            </option>
            
          );
        });

        this.setState({ option: arr });
      });
    });
    //End Fetch

    $(".needs-validation").submit((event) => {
      var form = $(event.target);

      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        console.log($(form[0]).find("input"));
        event.preventDefault();
        // Assuming only image
        const formdata = new FormData();
        formdata.append("saloon_id", this.state.saloon_id);
        formdata.append("plan_name", this.state.plan_name);
        formdata.append("description", this.state.description);
        formdata.append("service_id", this.state.service_id);
        formdata.append("session", this.state.session);
        formdata.append("no_session", this.state.no_session);
        formdata.append("validity", this.state.validity);
        formdata.append("price", this.state.price);
        formdata.append("color", this.state.color);
        formdata.append("rules", this.state.rules);
        axios
          .post(`${API_URL}/api/paid/plan${params?.memberId ? "/update/" + params?.memberId : ""}`, formdata, {
            method: "POST",
            headers:{
              mode: "no-cors",
              Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
              
            }
          })
          .then((response) => {
            console.log("res", response);
            let status = JSON.stringify(response.status);
             ;

            // ;
            console.log(response.data.status);
            console.log(response.data);
            if (response.data.status == true) {
              swal({
                title: "Done!",
                text: response.data.message + "",
                icon: "success",
                timer: 2000,
                button: false,
              });
              this.props.history.push("/paid-plan");
            }
          })

          .catch(function (error) {
            console.log(error);
            swal({
              title: "Opps!",
              text: error + "",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });

        // console.log(this.state);
      }
      form.addClass("was-validated");
    });
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  handleInputChange = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Create a paid plan</h4>
                    </div>
                    <div className="card-body">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-10 m-auto">
                            <form
                              className="needs-validation"
                              method="POST"
                              action={"#"}
                              // action={`${API_URL}/api/staff/${params?.memberId ? params?.memberId : ""}`}
                              enctype="multipart/form-data"
                              noValidate
                            >
                              <div className="row">
                                <div className="col-md-12 text-left">
                                  <h5>Basic Info</h5>
                                </div>
                                <div className="col-md-12">
                                  <label>Paid plan name</label>
                                  <input
                                    className="form-control"
                                    value={this.state.plan_name}
                                    type="text"
                                    required
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "plan_name",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-12 mt-2">
                                  <label>Paid plan description</label>
                                  <textarea
                                    className="form-control"
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    value={this.state.description}
                                  >
                                    {this.state.description}
                                  </textarea>
                                </div>
                              </div>
                              <div className="row border-top mt-4">
                                <div className="col-md-12 text-left">
                                  <h5>Services and sessions</h5>
                                  <p>
                                    Add the services and sessions included in
                                    the paid plan.
                                  </p>
                                </div>
                                <div className="col-md-12">
                                  <label>Included services</label>
                                  <select
                                    className="form-control"
                                    value={this.state.service_id}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "service_id",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="NaN">Select Service</option>
                                    {this.state.option}
                                  </select>
                                </div>
                                <div className="col-md-6 mt-2">
                                  <label>Sessions</label>
                                  <select
                                    class="form-control"
                                    value={this.state.session}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "session",
                                        e.target.value
                                      )
                                    }>
                                    <option value="NaN">Select Session</option>
                                    <option value="limited">Limited</option>
                                    <option value="unlimited">unlimited</option>
                                  </select>
                                </div>
                                <div className="col-md-6 mt-2">
                                  <label>Number of sessions</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={this.state.no_session}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "no_session",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row border-top mt-4">
                                <div className="col-md-12 text-left">
                                  <h5>Pricing and payment</h5>
                                  <p>
                                    Choose how you'd like your clients to pay.
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <label>Valid for</label>
                                  <select
                                    class="form-control"
                                    value={this.state.validity}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "validity",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="NaN">Select duration</option>
                                    <option value="7 Days">7 days</option>
                                    <option value="14 Days">14 days</option>
                                    <option value="1 Months">1 month</option>
                                    <option value="2 Months">2 months</option>
                                    <option value="3 Months">3 months</option>
                                    <option value="6 Months">6 months</option>
                                    <option value="1 Years">1 year</option>
                                    <option value="18 Months">18 months</option>
                                    <option value="2 Years">2 years</option>
                                    <option value="5 Years">5 years</option>
                                  </select>
                                </div>
                                <div className="col-md-6 mt-2">
                                  <label>Price</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={this.state.price}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "price",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row border-top mt-4">
                                <div className="mt-2 col-md-12 ">
                                  <h5>Colour customisation</h5>
                                  <p>
                                    Select a colour that matches your business.
                                  </p>
                                </div>
                                {/* <div className="row"> */}
                                <div className="col-md-12">
                                  <CirclePicker
                                    onChange={(e) =>
                                      this.handleInputChange("color", e.hex)
                                    }
                                    colors={[
                                      "#37D67A",
                                      "#2CCCE4",
                                      "#555555",
                                      "#dce775",
                                      "#ff8a65",
                                      "#ba68c8",
                                    ]}
                                  />
                                </div>
                                {/* </div> */}
                              </div>
                              <div className="row border-top mt-4">
                                <div className="mt-2 col-md-12 ">
                                  <h5>Terms & Conditions</h5>
                                  <p>
                                    If there are any rules attached to your paid
                                    plan it's a good place to mention them.
                                  </p>
                                </div>
                                <div className="col-md-12">
                                  <textarea
                                    className="form-control"
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        "rules",
                                        e.target.value
                                      )
                                    }
                                    value= {this.state.rules}
                                  >
                                    {this.state.rules}
                                  </textarea>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-6">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block"
                                  >
                                    Create Paid Plan
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
