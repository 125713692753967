import React, { Component } from "react";
import $, { param } from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
class EditCloseDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      cat_id: 0,
      category: "",
      duration: 0,
      price: 0.0,
      description: "",
      status: "",
      option: "",
      startDate: "",
      endDate: "",
      description: "",
      saloon_id:localStorage.getItem('role_id')
    };
  }
  componentDidMount() {
    //Fetch Category
    const { params } = this.props.match
    let saloonId = localStorage.getItem("user_id")
    fetch(API_URL + "/api/close/date/" + params?.id + "?saloon_id=" + saloonId, {
      method: "get",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((response) => {
      response.json().then((result1) => {
        console.clear();
        console.log("api responsive", result1);


        this.setState({
          startDate: result1.data.start_date,
          endDate: result1.data.end_date,
          description: result1.data.description,
        });
      });
    });

    $(".needs-validation").submit((event) => {
      var form = $(event.target);
      console.clear();
      // console.log();
      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        event.preventDefault();
        console.clear();
        console.log(this.state);
        let saloonId = localStorage.getItem("user_id")
        axios
          .post(API_URL + "/api/close/date/update/" + params?.id + "?saloon_id=" + saloonId, {
            end_date: this.state.endDate,
            id: params?.id,
            saloon_id: saloonId,
            start_date: this.state.startDate,
            description: this.state.description
          }, {
            headers:{
              mode: "no-cors",
              Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
              
            }
          })
          .then((response) => {
            let status = JSON.stringify(response.status);
            console.clear();
            console.log(response);
            if (response.data.status == true) {
              swal({
                title: "Done!",
                text: response.data.message + "",
                icon: "success",
                timer: 2000,
                button: false,
              });
              this.props.history.push("/close-date");
            }
          })
          .catch(function (error) {
            swal({
              title: "Opps!",
              text: error + "",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });
      }
      form.addClass("was-validated");
    });
  }

  handleInputChange = (key, value) => {
    this.setState({ [key]: value })
  }

  render() {
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">

            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Add New Open Date</h4>
                    </div>
                    <div className="card-body">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-9 m-auto">
                            <form
                              method="POST"
                              action="#"
                              className="needs-validation"
                              noValidate
                            >
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Start Date
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange("startDate", e.target.value)}
                                    value={this.state.startDate}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your start date
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    End Date
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange("endDate", e.target.value)}
                                    value={this.state.endDate}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your end date
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Description
                                  </label>
                                  <textarea
                                    id="frist_name"
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange("description", e.target.value)}
                                    value={this.state.description}
                                    required
                                  ></textarea>
                                  <div className="invalid-feedback">
                                    Please fill in your description
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-md-6 m-auto ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default EditCloseDate;
