import React, { Component } from 'react'
import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from '../../../../BaseURL';

export default class AddBusinessHour extends Component {
    constructor(props) {
        super(props);
        this.state  = {
          opening_time:[
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ],
          closing_time:[
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ],
         
          saloon_id:localStorage.getItem('user_id'),
          day : [
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday"
          ]
        }
      }
      componentDidMount() {
        console.log(this.state.day)
        $(".needs-validation").submit((event) => {
          var form = $(event.target);
          console.clear();
          // console.log();
          event.preventDefault();
          event.stopPropagation();
          if (!form[0].checkValidity() === false) {
            event.preventDefault();
            const config = {
              headers:{
                mode: "no-cors",
                Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                
              }
            }
            axios
              .post(API_URL+"/api/store_saloon_timing", this.state,config)
              .then((response) => {
                // console.log("res", response);
                let status = JSON.stringify(response.status);
                console.log(response);
                if (response.data.status == true) {
                  swal({
                    title: "Done!",
                    text: response.data.message + "",
                    icon: "success",
                    timer: 2000,
                    button: false,
                  });
                  this.props.history.push("/business-hour");
                }
              })
    
              .catch(function (error) {
                swal({
                  title: "Opps!",
                  text: error + "",
                  icon: "error",
                  timer: 2000,
                  button: false,
                });
              });
    
            // console.log(this.state);
          }
          form.addClass("was-validated");
        });
      }
      render() {
        const { opening_time, closing_time } = this.state
        return (
          <div className="main-wrapper main-wrapper-1">
            <Header/>
            <Sidebar/>
            <div className="main-content">
              <section className="section">
                <div className="section-body">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Add Business Hour</h4>
                        </div>
                        <div className="card-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-9 m-auto">
                                <form
                                  method="POST"
                                  action="#"
                                  className="needs-validation"
                                  noValidate
                                >
                                  <div className="row">
                                    <div className="form-group col-2 m-auto">
                                      <label htmlFor="frist_name">
                                        Monday
                                      </label>
                                    </div>
                                    <div className="form-group col-4">
                                      <input type="time"
                                       className="form-control"
                                       name="opening_time[]"
                                       onChange={(event)=>{
                                        opening_time[0] = event.target.value
                                         this.setState({
                                           opening_time: opening_time
                                         })
                                       }}
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        closing_time[0] = event.target.value
                                        this.setState({
                                          closing_time: closing_time
                                        })
                                      }}
                                      name="closing_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-2 m-auto">
                                      <label htmlFor="frist_name">
                                        Tuesday
                                      </label>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        opening_time[1] = event.target.value
                                        this.setState({
                                          opening_time: opening_time
                                        })
                                      }}
                                      name="opening_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        closing_time[1] = event.target.value
                                        this.setState({
                                          closing_time: closing_time
                                        })
                                      }}
                                      name="closing_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-2 m-auto">
                                      <label htmlFor="frist_name">
                                        Wednesday
                                      </label>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        opening_time[2] = event.target.value
                                        this.setState({
                                          opening_time: opening_time
                                        })
                                      }}
                                      name="opening_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        closing_time[2] = event.target.value
                                        this.setState({
                                          closing_time: closing_time
                                        })
                                      }}
                                      name="closing_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-2 m-auto">
                                      <label htmlFor="frist_name">
                                        Thursday                                      
                                      </label>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        opening_time[3] = event.target.value
                                        this.setState({
                                          opening_time:opening_time
                                        })
                                      }}
                                      name="opening_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        closing_time[3] = event.target.value
                                        this.setState({
                                          closing_time: closing_time
                                        })
                                      }}
                                      name="closing_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-2 m-auto">
                                      <label htmlFor="frist_name">
                                        Friday
                                      </label>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        opening_time[4] = event.target.value
                                        this.setState({
                                          opening_time: opening_time
                                        })
                                      }}
                                      name="opening_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        closing_time[4] = event.target.value
                                        this.setState({
                                          closing_time: closing_time
                                        })
                                      }}
                                      name="closing_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-2 m-auto">
                                      <label htmlFor="frist_name">
                                        Saturday
                                      </label>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        opening_time[5] = event.target.value
                                        this.setState({
                                          opening_time:opening_time
                                        })
                                      }}
                                      name="opening_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        closing_time[5] = event.target.value
                                        this.setState({
                                          closing_time: closing_time
                                        })
                                      }}
                                      name="closing_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-2 m-auto">
                                      <label htmlFor="frist_name">
                                        Sunday
                                      </label>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        opening_time[6] = event.target.value
                                        this.setState({
                                          opening_time: opening_time
                                        })
                                      }}
                                      name="opening_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                    <div className="form-group col-4">
                                    <input type="time"
                                       className="form-control"
                                       onChange={(event)=>{
                                        closing_time[6] = event.target.value
                                        this.setState({
                                          closing_time:closing_time
                                        })
                                      }}
                                      name="closing_time[]"
                                       required />
                                      <div className="invalid-feedback">
                                        Please select valid hour
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-4">
                                    <div className="col-md-6 m-auto ">
                                      <button
                                        type="submit"
                                        className="btn btn-primary btn-lg btn-block"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        );
      }
}
