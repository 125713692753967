import React, { Node, Component, forwardRef } from "react";
import $ from 'jquery';
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import Header from '../../../component/Header';

import Sidebar from '../../../component/Sidebar';
import Nestable from 'react-nestable';
import { NavLink } from 'react-router-dom';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./index.css"
import moment from 'moment'

const styles = {
  position: "relative",
  padding: "10px 15px",
  fontSize: "20px",
  border: "1px solid #f9fafa",
  background: "#f9fafa",
  cursor: "pointer"
};
class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      example: 1,
      dropdownOpen: false,
      selectedDropDown: 0,
      defaultCollapsed: false,
      item: [],
    };
  }

  getData() {
    let saloonId = localStorage.getItem("user_id")
    fetch(API_URL + "/api/services?saloon_id=" + saloonId, {
      method: 'get',
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((data) => {
      data.json().then((result) => {
        if (result.status == true) {
          console.clear()

          this.setState({ item: result.service })
        }
        console.log("Status is ", result, this.state.item)
      });
    });
  }

  componentDidMount() {
    this.getData();
  }

  collapse = (collapseCase) => {
    if (this.refNestable) {
      switch (collapseCase) {
        case 0:
          this.refNestable.collapse('NONE');
          break;
        case 1:
          this.refNestable.collapse('ALL');
          break;
        case 2:
          this.refNestable.collapse([1]);
          break;
      }
    }
  };

  renderItem = ({ item, handler }) => {
    return (

      <div style={styles}>
        {handler}
        {item.price ? <NavLink to={"/edit-service/" + item.id}>
          <div className="row">
            <div className="col-md-4 text-left">
              <p><span><i className="fa fa-bars mr-2" /></span>{item.name}</p>
            </div>
            <div className="col-md-3">
              <p>{item.price ? item.price + "DKK" : ""}</p>
            </div>
            <div className="col-md-3">
              {/* <p>{moment(item.duration, "HH:mm:ss").format('hh:mm a')}</p> */}
              <p>{item.hours}</p>
            </div>
            {item.duration ? "" :
              <div className="col-md-2 text-right">
                <ButtonDropdown direction="left"
                  ref={(ref) => this[`dropdow${item}`] = ref}
                  key={"id" + item.id}

                  isOpen={this.state.selectedDropDown == item.id}
                  toggle={() => {
                    this.setState({ dropdownOpen: !this.state.dropdownOpen, selectedDropDown: this.state.selectedDropDown == item.id ? 0 : item.id }); console.log("selecte", this.state.selectedDropDown, item.id);
                  }}>
                  <DropdownToggle id={"dropdownButton"} caret={false}
                    // style={{ border: "none", boxShadow: "none", fontSize: 20 }} 
                    className="bg-transparent text-dark b-none" onClick={() => {
                      // console.log(item.id)
                      this.setState({ selectedDropDown: this.state.selectedDropDown == item.id ? 0 : item.id })
                    }}>
                    ...
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => { this.props.history.push("/edit-category/" + item.id) }}>
                      Edit Category
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                      swal({
                        title: "Are you sure?",
                        text: "Are you sure that you want to delete this user?",
                        icon: "warning",
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          //   Delete APi
                          let saloonId = localStorage.getItem("user_id")
                          fetch(
                            API_URL + "/api/delete_category/" + item.id + "?saloon_id=" + saloonId,
                            {
                              method: "GET",
                            }
                          ).then((result) => {
                            // console.clear();
                            console.log("Delete", result.status)
                            if (result.status == 0) {
                              swal(
                                "Error!",
                                "Something went wrong .. Please try again!",
                                "error"
                              );
                            }
                            else {
                              swal(
                                "Sucess!",
                                "Category Deleted Successfully !",
                                "success"
                              );
                              this.getData();
                            }
                            // 
                          });
                        }
                      });
                    }}>
                      Delete Service
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

            }
          </div>
        </NavLink> :
          <div className="row">
            <div className="col-md-4 text-left">
              <p><span><i className="fa fa-bars mr-2" /></span>{item.name}</p>
            </div>
            <div className="col-md-3">
              <p>{item.price ? item.price + "USD" : ""}</p>
            </div>
            <div className="col-md-3">
              <p>{item.duration}</p>
            </div>
            {item.duration ? "" :
              <div className="col-md-2 text-right">
                <ButtonDropdown direction="left"
                  ref={(ref) => this[`dropdow${item}`] = ref}
                  key={"id" + item.id}

                  isOpen={this.state.selectedDropDown == item.id}
                  toggle={() => {
                    this.setState({ dropdownOpen: !this.state.dropdownOpen, selectedDropDown: this.state.selectedDropDown == item.id ? 0 : item.id }); console.log("selecte", this.state.selectedDropDown, item.id);
                  }}>
                  <DropdownToggle id={"dropdownButton"} caret={false}
                    // style={{ border: "none", boxShadow: "none", fontSize: 20 }} 
                    className="bg-transparent text-dark b-none" onClick={() => {
                      // console.log(item.id)
                      this.setState({ selectedDropDown: this.state.selectedDropDown == item.id ? 0 : item.id })
                    }}>
                    ...
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => { this.props.history.push("/edit-category/" + item.id) }}>
                      Edit category
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                      swal({
                        title: "Are you sure?",
                        text: "Are you sure that you want to delete this user?",
                        icon: "warning",
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          //   Delete APi
                          let saloonId = localStorage.getItem("user_id")
                          fetch(
                            API_URL + "/api/delete_category/" + item.id + "?saloon_id=" + saloonId,
                            {
                              method: "GET",
                            }
                          ).then((result) => {
                            // console.clear();
                            console.log("Delete", result.status)
                            if (result.status == 0) {
                              swal(
                                "Error!",
                                "Something went wrong .. Please try again!",
                                "error"
                              );
                            }
                            else {
                              swal(
                                "Sucess!",
                                "Category Deleted Successfully !",
                                "success"
                              );
                              this.getData();
                            }
                            // 
                          });
                        }
                      });
                    }}>
                      Delete Service
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

            }
          </div>
        }

      </div>

    );
  };

  isCollapsed = () => {
    const form = document.forms[0] || null;

    return form && form.elements["collapsed"].checked;
  };


  render() {


    const items = this.state.item


    const { defaultCollapsed } = this.state;
    const onDefaultCollapsed = () => this.setState({
      defaultCollapsed: !defaultCollapsed
    });

    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">

            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Service</h4>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-6 text-left">
                          <button className="btn btn-light border-dark mr-3" type="button" onClick={() => this.collapse(0)}>
                            <i className="fa fa-th-large" />
                          </button>
                          <button className="btn btn-light border-dark" type="button" onClick={() => this.collapse(1)}>
                            <i className="fa fa-bars" />
                          </button>
                        </div>
                        <div className="col-md-6 text-right">
                          <NavLink to="/add-service" className="btn btn-primary">Add new</NavLink>
                        </div>
                      </div>
                      <div class="">
                        <Nestable
                          items={items}
                          collapsed={defaultCollapsed}
                          renderItem={this.renderItem}
                          ref={el => this.refNestable = el}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
export default Service;