import React, { Component } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import $ from "jquery";
class Index extends Component {
  componentDidMount() {}
  render() {
    return (
      <>
        <Header />
       
        <div className="contaienr">
            <div className="">
              <div className="col-md-12 my-5 text-center">
                  <h1>Comming Soon</h1>
                  <p>Work is in Progress....</p>
              </div>
            </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default Index;
