import React, { Component } from 'react'
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import { extend, Internationalization, removeClass, isNullOrUndefined, L10n } from '@syncfusion/ej2-base';
import { ScheduleComponent, ResourcesDirective, ResourceDirective, Day, Week, WorkWeek, Month, Agenda, MonthAgenda, Inject, ViewsDirective, ViewDirective, Resize, DragAndDrop } from "@syncfusion/ej2-react-schedule";
import dataSource from './datasource.json'
import { SampleBase } from './sample-base';
import "./calendar.css"
import { API_URL } from '../../../../BaseURL';
import moment from 'moment'
import $, { data } from "jquery"
import ReactTooltip from 'react-tooltip';
import { Calendar as FuesCalendar } from '@syncfusion/ej2-calendars'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import axios from 'axios';

function getCurrentWeek(date) {
    var currentDate = moment(date);

    var weekStart = currentDate.clone().startOf('week');
    var weekEnd = currentDate.clone().endOf('week');

    var days = [];

    for (var i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format("ddd D MMMM"));
    }

    const rangeString = `${weekStart.format("D")} - ${weekEnd.format("D")} ${weekStart.format("MMMM YYYY")}`

    console.log("range", rangeString)
    console.log(days);

    return {
        days,
        rangeString,
        weekStart,
        weekEnd
    }
}


function divid24Hours() {
    var x = 5; //minutes interval
    var times = []; // time array
    var tt = 0; // start time
    var ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        var mm = (tt % 60); // getting minutes of the hour in 0-55 format
        times[i] = ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2)  // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }
    return times
}


L10n.load({
    'en-US': {
        'schedule': {
            'deleteButton': '',
            'newEvent': 'Add Appointment',
            'editEvent': 'Edit Appointment'
        },
    }
});


// divid24Hours(moment())
export default class Calendar extends SampleBase {
    constructor() {
        // super(...arguments);
        super(...arguments);
        this.scheduleData = extend([], dataSource.quickInfoTemplateData, undefined, true);
        this.dragStartDate = {}

        this.intl = new Internationalization();
        this.roomData = [];
        this.state = {
            data: [],
            option: "",
            selectedData: [],
            selectedDate: "",
            setIsOpen: false,
            date: moment(),
            dateRange: "",
            weekArr: [],
            isLoading: false,
            weekStart: "",
            weekEnd: "",
            seviceoption: [],
            selectedService: null,
            staffoption: "",
            appointments: [],
            dragStartData: null
        }
    }


    componentDidMount() {
        // Fetch Services 
        fetch(API_URL + "/api/services", {
            method: "get",
            headers:{
                Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
            }
        }).then((response) => {
            response.json().then((result1) => {
                console.log("api responsive", result1);
                this.setState({ seviceoption: result1.service });
            });
        });
        // End Fetch Services


        this.getData()

        const { date } = this.state
        const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(date)
        this.setState({ dateRange: rangeString, weekArr: days, weekStart, weekEnd }, () => {
            this.getData()
        })
        console.log("FuesCalendar", this.FuesCalendar)
    }

    // Fetch Staff Function
    getData = () => {
        const { date, weekEnd, weekStart, weekArr, selectedData } = this.state

        var bodyData = {}
        if (this.scheduleObj.currentView == "Day") {
            bodyData = {
                to: moment(date).format("YYYY-MM-DD"),
                from: moment(date).format("YYYY-MM-DD")
            }
        } else {
            bodyData = {
                to: moment(weekEnd).format("YYYY-MM-DD"),
                from: moment(weekStart).format("YYYY-MM-DD")
            }
        }

        fetch(API_URL + "/api/appointment-list",
        {
            headers:{
            mode: "no-cors",
            Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
            }
        }).
            then((data) => {
                return data.json()
            })
            .then((result) => {
                if (result.status == true) {
                    //  
                    const appointments = result.message.map(val => (
                        {
                            Id: val.id,
                            Subject: val.note,
                            StartTime: val.start_time_format,
                            Duration: val.duration,
                            EndTime: val.end_time_format,
                            RoomId: val.saloon_staff_id.toString(),
                            ServceId: val.saloon_service_id
                        }
                    ))
                    console.log("Event Status is ", appointments)
                    this.setState({ appointments })
                    console.log("state data",this.state.appointments)
                }
            })
            .catch(error => console.log("appointmentListError", error))



        fetch(API_URL + "/api/staff/working/hour/list", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
            },
            body: JSON.stringify(bodyData)
        }).then((data) => {
            return data.json()
        }).then((result) => {
            if (result.status == true) {
                console.log("staffs", result);
                let api = result.message;
                let arr = result.message.map((val, i) => {
                    return (
                        <option key={i} value={val.id}>
                            {val.full_name}
                        </option>
                    );
                });

                // this.setState({  })                
                this.setState({ option: arr, });


                this.setState({
                    data: api.map((val) => {

                        return {
                            Name: `${val.full_name}`, Id: `${val.id}`,
                            Capacity: 7,
                            Color: `${val.calendar_color}`,
                            Type: 'Cabin',
                            StartHour: val[moment(date).format("ddd").toLowerCase()] ? val[moment(date).format("ddd").toLowerCase()].shift_start : "",
                            EndHour: val[moment(date).format("ddd").toLowerCase()] ? val[moment(date).format("ddd").toLowerCase()].shift_end : ""
                        }
                    })
                });


                // this.roomData = this.state.data
                // console.log("staffs map => state", this.roomData);

            }
        })

    }

    onPopupClose = (arg) => {
        console.log("popup close", arg);
        // arg.ele
        if (arg.type == "Editor" && $(arg.element).find(".e-title-text")[0].outerText == "Add Appointment" && !isNullOrUndefined(arg.data)) {
            let startTimeElement = arg.element.querySelector('#StartTime');
            let StartTimeValue = startTimeElement.value
            console.log("StartTimeValue", StartTimeValue);
            let ServiceElement = arg.element.querySelector('#Service');
            let ServiceValue = ServiceElement.value
            console.log("ServiceValue", ServiceValue);
            let durationElement = arg.element.querySelector('#duration');
            let durationValue = durationElement.value
            console.log("ServiceValue", durationValue);



            let RoomIdElement = arg.element.querySelector('#RoomId');
            let RoomId = RoomIdElement.value
            console.log("RoomId", RoomId);

            let NoteElement = arg.element.querySelector('#Description');
            let NoteValue = NoteElement.value
            console.log("NoteValue", NoteValue);
            // return
            // let DurationElement = arg.element.querySelector('#Duration');
            // let Duration = DurationElement.text
            // console.log("Duration", Duration);

            let dateElement = arg.element.querySelector('#date');
            let date = dateElement.value
            const config = {
                headers:{
                  mode: "no-cors",
                  Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                  
                }
              }
            axios
                .post(API_URL + "/api/appointment", {
                    duration: durationValue,
                    start_time: StartTimeValue,
                    saloon_service_id: ServiceValue,
                    // end_time: EndTime,
                    client_id: localStorage.getItem('user_id'),
                    date: date,
                    note: NoteValue,
                    // duration:Duration,
                    saloon_staff_id: RoomId,


                },
                config
                )
                .then((response) => {
                    // console.log("res", response);
                    let status = JSON.stringify(response.status);
                    console.log(response);
                    if (response.data.status == true) {
                        swal({
                            title: "Done!",
                            text: response.data.message + "",
                            icon: "success",
                            timer: 2000,
                            button: false,
                        });
                        this.getData()

                    }
                })

                .catch(function (error) {
                    swal({
                        title: "Opps!",
                        text: error + "",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    });
                });
        }
        else if (arg.type == "Editor" && $(arg.element).find(".e-title-text")[0].outerText == "Edit Appointment" && !isNullOrUndefined(arg.data)) {
            let startTimeElement = arg.element.querySelector('#StartTime');
            let StartTimeValue = startTimeElement.value
            console.log("StartTimeValue", StartTimeValue);
            let ServiceElement = arg.element.querySelector('#Service');
            let ServiceValue = ServiceElement.value
            console.log("ServiceValue", ServiceValue);
            let durationElement = arg.element.querySelector('#duration');
            let durationValue = durationElement.value
            // let durationValue = arg.data.duration
            console.log("durationValue", durationValue);

            let IdElement = arg.element.querySelector('#Id');
            let Id = IdElement.value
            console.log("Id", Id);

            let RoomIdElement = arg.element.querySelector('#RoomId');
            let RoomId = RoomIdElement.value
            console.log("RoomId", RoomId);

            let NoteElement = arg.element.querySelector('#Description');
            let NoteValue = NoteElement.value
            console.log("NoteValue", NoteValue);
            // return
            // let DurationElement = arg.element.querySelector('#Duration');
            // let Duration = DurationElement.text
            // console.log("Duration", Duration);

            let dateElement = arg.element.querySelector('#date');
            let date = dateElement.value
            // console.log("date", date);
            const config = {
                headers:{
                  mode: "no-cors",
                  Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                  
                }
              }

            axios
                .post(API_URL + "/api/appointment/" + Id, {
                    duration: durationValue,
                    start_time: StartTimeValue,
                    saloon_service_id: ServiceValue,
                    // end_time: EndTime,
                    client_id: localStorage.getItem('user_id'),
                    date: date,
                    note: NoteValue,
                    // duration:Duration,
                    saloon_staff_id: RoomId,


                }, {
                    mode: "no-cors",
                    Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                })
                .then((response) => {
                    // console.log("res", response);
                    let status = JSON.stringify(response.status);
                    console.log(response);
                    if (response.data.status == true) {
                        swal({
                            title: "Done!",
                            text: response.data.message + "",
                            icon: "success",
                            timer: 2000,
                            button: false,
                        });
                        this.getData()
                    }
                })

                .catch(function (error) {
                    swal({
                        title: "Opps!",
                        text: error + "",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    });
                });
        }
    }

    onRenderCell(args) {
        if (args.elementType === "dateHeader" || args.elementType === "monthCells") {
            removeClass(args.element.childNodes, "e-navigate");
        }
    }

    getAirlineImage(value) {
        let airlineName = this.getAirlineName(value);
        return airlineName.replace(' ', '-').toLowerCase();
    }

    getAirlineName(value) {
        return ((value.resourceData) ?
            value.resourceData[value.resource.textField] :
            value.resourceName);
    }

    getAirlineModel(value) {
        let airlineName = this.getAirlineName(value);
        return (airlineName === 'Airways 1') ? 'CRJ 700' : (airlineName === 'Airways 2') ? 'Airbus A330' : 'ATR 72-600';
    }

    getAirlineSeats(value) {
        let airlineName = this.getAirlineName(value);
        return (airlineName === 'Airways 1') ? 50 : (airlineName === 'Airways 2') ? 75 : 100;
    }

    resourceHeaderTemplate(props) {
        return (
            <div className="text-center" >
                <div className={""} >
                    <figure className="avatar mr-2 avatar-lg bg-sky text-dark text-bold" style={{ textTransform: "uppercase", fontWeight: 500 }} data-initial={props.resourceData.Name.slice(0, 1)}></figure>
                </div>
                <div className="airline-details">
                    <p style={{ fontWeight: 500, color: "#000", wordBreak: "break-all" }}>{props.resourceData.Name}</p>
                </div>
            </div>
        );
    }

    onHover(args, s) {
        // console.log(args, s);
        const date = $(args.element).attr("data-date")
        const formatedTime = moment(Number(date)).format("HH:mm")
        if (args.element.classList.contains('e-work-cells')) {
            let workCells = document.querySelectorAll('.e-work-cells');
            // console.log("workCells", workCells);
            [].forEach.call(workCells, function (ele) {
                // ele.classList.remove("hoverText");
                ele.innerHTML = ""
            });
            // args.element.classList.add('hoverText');
            // args.element.innerHTML = args.element.classList.contains('e-work-hours') ? `${formatedTime}` : "closed";
            args.element.innerHTML = `${formatedTime}`;
        }
    }


    DateRangePicker = (date) => {
        // const { date } = this.state
        this.scheduleObj.changeDate(date)
        const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(date)
        this.setState({ dateRange: rangeString, weekArr: days, weekStart, weekEnd, date }, () => {
            this.getData()
        })
    }

    handleNextPrev = (isNext) => {
        const { date } = this.state
        if (this.scheduleObj && this.scheduleObj.currentView == "Week") {
            const nextWeek = moment(date).add(1, "week")
            const prev = moment(date).subtract(1, "week")
            const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(isNext ? nextWeek.toDate() : prev.toDate())
            this.scheduleObj.changeDate(weekStart)
            this.setState({
                dateRange: rangeString,
                weekArr: days,
                date: isNext ? nextWeek : prev,
                weekStart, weekEnd
            }, () => {

                this.getData()
            })
        } else {
            const next = moment(date).add(1, "day")
            const prev = moment(date).subtract(1, "day")
            const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(isNext ? next.toDate() : prev.toDate())
            this.scheduleObj.changeDate(isNext ? next : prev)
            this.setState({
                dateRange: rangeString,
                weekArr: days,
                date: isNext ? next : prev,
                weekStart, weekEnd
            }, () => {

                this.getData()
            })
        }



    }

    handleToday = () => {
        const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(moment().toDate())
        this.scheduleObj.changeDate(moment())
        this.setState({
            dateRange: rangeString,
            weekArr: days,
            date: moment(),
            weekStart,
            weekEnd
        }, () => {
            this.getData()
        })
    }


    submitchange = (e) => {
        this.setState({ selectedService: e.target.value })

    }

    onPopupOpen = (args) => {
        const { seviceoption } = this.state
        console.log("args", args);
        if (args.type == "Editor") {
            let startTimeElement = args.element.querySelector('#StartTime');
            startTimeElement.value = moment(args.data.StartTime).format("HH:mm")
            // console.log("StartTimeValue", StartTimeValue);
            let ServiceElement = args.element.querySelector('#Service');
            ServiceElement.value = args.data.ServceId

            let ind = seviceoption.findIndex(x => x.id == args.data.ServceId)
            console.log(ind)
            if (ind !== -1) {
                seviceoption[ind].children.map((val) => {
                    $("#duration").append(`<option ${val.duration == args.data.Duration ? "selected='selected'" : ""} value="${val.duration}">${val.duration}</option>`)
                })
            }
            // console.log("ServiceValue", ServiceValue);
            // let durationElement = arg.element.querySelector('#duration');
            // let durationValue = durationElement.value
            // console.log("ServiceValue", durationValue);

            // let EndTimeElement = arg.element.querySelector('#EndTime');
            // let EndTime = EndTimeElement.value
            // EndTime = 
            // console.log("EndTime", moment(StartTimeValue, "HH:ss").add(durationValue).utc().format());

            // let RoomIdElement = arg.element.querySelector('#RoomId');
            // let RoomId = RoomIdElement.value
            // console.log("RoomId", RoomId);

            let NoteElement = args.element.querySelector('#Description');
            NoteElement.value = args.data.Subject ? args.data.Subject : ""
            // console.log("NoteValue", NoteValue);
            // return
            // let DurationElement = arg.element.querySelector('#Duration');
            // let Duration = DurationElement.text
            // console.log("Duration", Duration);

            // let dateElement = arg.element.querySelector('#date');
            // let date = dateElement.value
            // console.log("date", date);

        }

    }

    handleDragStop = async (e) => {
        console.log("dragStop", e)
        const { dragStartData } = this.state
        let duraton = moment.duration(moment(e.data.EndTime).diff(moment(e.data.StartTime)))

        let durationFormat = `${duraton.get("hour")}:${duraton.get("minute")}:${duraton.get("second")}`
        console.log("duration", durationFormat, this.dragStartDate)
        const config = {
            headers:{
              mode: "no-cors",
              Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
              
            }
          }
        let { data } = await axios.post(`${API_URL}/api/appointment/${e.data.Id}${this.dragStartDate.RoomId == e.data.RoomId ? "" : `/${this.dragStartDate.RoomId}/${e.data.RoomId}`}`,config, {
            start_time: moment(e.data.StartTime).format("HH:mm:ss"),
            duration: durationFormat,
        })

        console.log(data);
        if (data.status) {
        }

    }

    onResize = async (e) => {
        console.log("onResize", e)
        let duraton = moment.duration(moment(e.data.EndTime).diff(moment(e.data.StartTime)))

        let durationFormat = `${duraton.get("hour")}:${duraton.get("minute")}:${duraton.get("second")}`
        console.log("duration", durationFormat)
        const config = {
            headers:{
              mode: "no-cors",
              Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
              
            }
          }


        let { data } = await axios.post(`${API_URL}/api/appointment/${e.data.Id}`, config ,{
            start_time: moment(e.data.StartTime).format("HH:mm:ss"),
            duration: durationFormat,
        })

        console.log(data);
    }

    editorTemplate = (props) => {
        const { seviceoption, selectedService } = this.state
        console.log("editor template", props);

        return (
            <table className="custom-event-editor" style={{ width: '100%', cellpadding: '5' }}>
                <tbody>
                    <tr>
                        <td className="e-textlabel">
                            Start Time
                </td>
                        <td colSpan={4}>
                            <select className="form-control" name="StartTime" id="StartTime" >
                                {divid24Hours().map(val => <option key={val} value={val}>
                                    {val}
                                </option>)}
                            </select>

                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel">Service</td>
                        <td colSpan={4}>
                            <input hidden id="EndTime" readOnly value={moment(props.EndTime).format("HH:MM:SS")} type="text" />
                            <input hidden id="RoomId" readOnly value={props.RoomId} type="text" />
                            <input hidden id="Id" readOnly value={props.Id} type="text" />
                            <input hidden id="date" readOnly value={moment(props.StartTime).format("YYYY-MM-DD")} type="text" />
                            <select id="Service" className="form-control" onChange={(e) => {
                                // this.submitchange(e)
                                let ind = seviceoption.findIndex(x => x.id == e.target.value)
                                console.log(ind)
                                if (ind !== -1) {
                                    $("#duration").children().remove()
                                    seviceoption[ind].children.map((val) => {
                                        $("#duration").append(`<option value="${val.duration}">${val.duration}</option>`)
                                    })
                                }
                            }}>
                                <option className="form-control">Choose a service</option>

                                {this.state.seviceoption.map((val, i) => {
                                    return (
                                        <option key={i} value={val.id}>
                                            {val.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel">Duration</td>
                        <td colSpan={4}>
                            <select id="duration" className="form-control">
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel">Appointment notes</td>
                        <td colSpan={4}>
                            <textarea id="Description" className="e-field e-input" name="Description" rows={3} cols={50} style={{ width: '100%', height: '60px !important', resize: 'vertical' }}></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>);
    }

    render() {
        const { data, selectedData, selectedService, appointments } = this.state
        console.log("scheduleData", selectedService);

        return (
            <div className="main-wrapper main-wrapper-1">
                <Header />
                <Sidebar />
                <ReactTooltip />
                <div className="main-content">
                    <div className='schedule-control-section'>
                        <div className='col-lg-12 control-section'>
                            <div className='control-wrapper'>
                                <div className="row">
                                    <div className="col-md-3 ">
                                        <select className="form-control" onChange={(e) => {
                                            let findDataIndex = data.findIndex(x => {
                                                // console.log(x.Id, e.target.value);
                                                return x.Id == e.target.value
                                            })
                                            // console.log(findDataIndex, "findDataIndex");
                                            if (findDataIndex !== -1) {
                                                const val = data[findDataIndex]
                                                // console.log(val);
                                                this.setState({
                                                    selectedData: [{ ...val }]
                                                })
                                            } else {
                                                this.setState({ selectedData: [] })
                                            }
                                        }}>
                                            <option value="NaN">All Staff</option>
                                            {this.state.option}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <nav aria-label="Page navigation example">
                                                        <ul className="pagination">
                                                            <li className="page-item"><button className="page-link" onClick={() => this.handleNextPrev(false)}><i className="fa fa-chevron-left text-bold" ></i></button></li>
                                                            <li className="page-item"><button className="page-link" onClick={() => { this.handleToday() }}>Today</button></li>
                                                            <li className="page-item">
                                                                <button className="page-link" onClick={() => { this.dataPicker.setOpen(true) }}>
                                                                    {(this.scheduleObj && this.scheduleObj.currentView == "Week") ? this.state.dateRange : moment(this.state.date).format("dddd D MMM, YYYY")}
                                                                    <DatePicker ref={ref => this.dataPicker = ref} className={"d-none"} selected={this.state.startDate} onChange={date => this.DateRangePicker(date)} />
                                                                </button>
                                                            </li>
                                                            <li className="page-item"><button className="page-link" onClick={() => this.handleNextPrev(true)}><i className="fa fa-chevron-right text-bold" ></i></button></li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <select className="form-control" onChange={(e) => {
                                            this.scheduleObj.changeView(e.target.value)
                                            this.getData()
                                        }}>
                                            <option value="Day">Day</option>
                                            <option value="Week">Week</option>
                                        </select>
                                    </div>
                                </div>
                                <ScheduleComponent
                                    cssClass='schedule-group'
                                    width='100%'
                                    showHeaderBar={false}
                                    hover={this.onHover.bind(this)}
                                    // renderCell={this.onRenderCell.bind(this)}
                                    cellDoubleClick={(e) => {
                                        console.log("cell data", e);
                                        console.log("cell data", data[e.groupIndex], moment(e.startTime).format("HH:mm"))
                                        // $("#StartTime").val(moment(e.startTime).format("HH:mm")).change()
                                        setTimeout(() => {$(`#StartTime option[value="${moment(e.startTime).format("HH:mm")}:00"]`).attr('selected', 'selected');}, 500);
                                    }}
                                    
                                    editorTemplate={this.editorTemplate.bind(this)}
                                    showQuickInfo={false}
                                    renderCell={this.onRenderCell.bind(this)}
                                    popupClose={this.onPopupClose}
                                    popupOpen={this.onPopupOpen}
                                    resizeStop={this.onResize}
                                    height='650px'
                                    ref={(schedule) => this.scheduleObj = schedule}
                                    eventSettings={{
                                        dataSource: appointments,
                                        fields: {
                                            startTime: { name: "StartTime", title: "Start Time" },
                                            endTime: { name: "EndTime" }
                                        },
                                        allowDeleting: false
                                    }}
                                    showWeekend
                                    dragStart={e => {
                                        this.dragStartDate = e.data
                                    }}
                                    drag={e => console.log("drag", e)}
                                    dragStop={this.handleDragStop}
                                    group={{
                                        byGroupID: false,
                                        resources: ["MeetingRoom"],

                                    }}
                                    enableAllDayScroll
                                    resourceHeaderTemplate={this.resourceHeaderTemplate.bind(this)}
                                    startHour='00:00'
                                    endHour="24:00"
                                    timeScale={{
                                        slotCount: 4,
                                        interval: 60,
                                        majorSlotTemplate: (props) => moment(props.date).format("HH:mm"),
                                    }}
                                    workDays={[1, 2, 3, 4, 5, 6, 7]}

                                >
                                    <ResourcesDirective>
                                        <ResourceDirective field='RoomId' startHourField="StartHour" endHourField="EndHour" title='Room Type' name='MeetingRoom' textField='Name' idField='Id' colorField='Color' dataSource={selectedData.length > 0 ? selectedData : this.state.data} ></ResourceDirective>
                                        {/* <ResourceDirective field='MeetingRoomId' startHourField="StartHour" endHourField="EndHour" title='Room Type' name='MeetingRoom' allowMultiple={true} dataSource={selectedData.length > 0 ? selectedData : this.state.data} textField='Name' idField='Id' colorField='Color'>
                                        </ResourceDirective> */}
                                    </ResourcesDirective>
                                    <ViewsDirective>
                                        <ViewDirective isSelected option='Day' />
                                        <ViewDirective option='Week' />
                                        <ViewDirective option='Agenda' />
                                    </ViewsDirective>
                                    <Inject services={[Day, Week, Resize, DragAndDrop, Agenda]} />
                                </ScheduleComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}