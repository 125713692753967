import logo from './logo.svg';
import './App.css'
import ReactTooltip from 'react-tooltip';
import ProtectedRoute from './admin/pages/ProtectedRoute';    
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Login from './auth/Login';
import Register from './auth/Register';
import Forget from './auth/Forget';
import ResetPassword from './auth/ResetPassword';
import Service from './admin/pages/Saloon/Service/Service';
import Category from './admin/pages/Saloon/Category/Category';
import Gallery from './admin/pages/Saloon/Gallery/Gallery';
import BusinessHour from './admin/pages/Saloon/BusinessHour/BusinessHour';
import AddCategory from './admin/pages/Saloon/Category/AddCategory';
import EditCategory from './admin/pages/Saloon/Category/EditCategory';
import AddGallery from './admin/pages/Saloon/Gallery/AddGallery';
import EditGallery from './admin/pages/Saloon/Gallery/EditGallery';
import EditBusinessHour from './admin/pages/Saloon/BusinessHour/EditBusinessHour';
import AddBusinessHour from './admin/pages/Saloon/BusinessHour/AddBusinessHour';
import AddService from './admin/pages/Saloon/Service/AddService';
import EditService from './admin/pages/Saloon/Service/EditService';
import Index from './web/pages/Home/Index';
import SaloonList from './web/pages/Saloon/SaloonList';
import Client from './admin/pages/Saloon/Client/Client';
import AddClient from './admin/pages/Saloon/Client/AddClient';
import ClientDetail from './admin/pages/Saloon/Client/ClientDetail';
import EditClient from './admin/pages/Saloon/Client/EditClient';
import CloseDate from './admin/pages/Saloon/Staff/CloseDate';
import AddCloseDate from './admin/pages/Saloon/Staff/AddCloseDate';
import EditCloseDate from './admin/pages/Saloon/Staff/EditCloseDate';
import UserPermission from './admin/pages/Saloon/Staff/UserPermission';
import StaffList from './admin/pages/Saloon/Staff/StaffList';
import AddStaff from './admin/pages/Saloon/Staff/AddStaff';
import PlanList from './admin/pages/Saloon/Plan/PlanList';
import TimeSlot from './admin/pages/Saloon/Staff/TimeSlot';
import AddPlan from './admin/pages/Saloon/Plan/AddPlan';
import Calendar from './admin/pages/Saloon/Calendar/Calendar';
import Admin from './admin/pages/Admin/Admin';
import EditSaloon from './admin/pages/Admin/EditSaloon';
import AddSaloon from './admin/pages/Admin/AddSaloon';
function App() {
  return (
    <>
    <Router>
      <Switch>
        {/* Auth Routes */}
        <Route exact={true} path="/login" component={Login}/>
        <Route exact={true} path="/register" component={Register}/>
        <Route exact={true} path="/forget-password" component={Forget}/>
        <Route exact={true} path="/reset-password" component={ResetPassword}/>
        {/* Auth Routes End */}
        {/* Client Side Route */}
        <Route exact={true} path="/" component={Index}/>
        <Route exact={true} path="/saloon-list" component={SaloonList}/>
        {/* Client Side Route End */}
        {/* Saloon Managment Routes */}
        {/* <ProtectedRoute exact={true} path="/customer" component={Customer} /> */}
        <ProtectedRoute exact={true} path="/service" component={Service} />
        <ProtectedRoute exact={true} path="/category" component={Category}/>
        <ProtectedRoute exact={true} path="/gallery" component={Gallery}/>
        <ProtectedRoute exact={true} path="/business-hour" component={BusinessHour}/>
        <ProtectedRoute exact={true} path="/add-category" component={AddCategory}/>
        <ProtectedRoute exact={true} path="/edit-category/:id" component={EditCategory}/>
        <ProtectedRoute exact={true} path="/add-gallery" component={AddGallery}/>
        <ProtectedRoute exact={true} path="/edit-gallery/:id" component={EditGallery}/>
        <ProtectedRoute exact={true} path="/add-business-hour" component={AddBusinessHour}/>
        <ProtectedRoute exact={true} path="/edit-business-hour/:id" component={EditBusinessHour}/>
        <ProtectedRoute exact={true} path="/add-service" component={AddService}/>
        <ProtectedRoute exact={true} path="/edit-service/:id" component={EditService}/>
        <ProtectedRoute exact={true} path="/client" component={Client}/>
        <ProtectedRoute exact={true} path="/add-client" component={AddClient}/>
        <ProtectedRoute exact={true} path="/client-profile/:id" component={ClientDetail}/>
        <ProtectedRoute exact={true} path="/edit-client/:id" component={EditClient}/>
        <ProtectedRoute exact={true} path="/close-date" component={CloseDate}/>
        <ProtectedRoute exact={true} path="/add-close-date" component={AddCloseDate}/>
        <ProtectedRoute exact={true} path="/edit-close-date/:id" component={EditCloseDate}/>
        <ProtectedRoute exact={true} path="/staff-list" component={StaffList}/>
        <ProtectedRoute exact={true} path="/add-staff" component={AddStaff}/>
        <ProtectedRoute exact={true} path="/edit-staff/:memberId" component={AddStaff}/>
        <ProtectedRoute exact={true} path="/user-permission" component={UserPermission}/>
        <ProtectedRoute exact={true} path="/time-slot" component={TimeSlot}/>
        <ProtectedRoute exact={true} path="/paid-plan" component={PlanList} />
        <ProtectedRoute exact={true} path="/add-plan" component={AddPlan} />
        <ProtectedRoute exact={true} path="/edit-plan/:memberId/:saloon_id" component={AddPlan} />
        <ProtectedRoute exact={true} path="/calendar" component={Calendar} />
        {/* Saloon Managment Routes End */}
        {/* Admin Route */}
        <ProtectedRoute exact={true} path="/saloon" component={Admin} />
        <ProtectedRoute exact={true} path="/add-saloon" component={AddSaloon} />
        <ProtectedRoute exact={true} path="/edit-saloon/:id" component={EditSaloon} />
        {/* Admin Route END */}
      </Switch>
    </Router>
    </>
  );
}

export default App;
