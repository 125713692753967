import React , {useEffect,useState} from 'react'
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert'
import { API_URL } from '../BaseURL';
export default function ResetPassword(props) {
console.log("Email from props",props.location.state.email)
  const [email,setEmail] = useState(props.location.state.email);
  const [password,setPassword] = useState("");
  const [token,setToken] = useState("");
  console.log(email.email);
 const handleSubmit = (event) =>{
    // $(".needs-validation").submit((event) => {
        var form = $(event.target);
        console.clear();
        console.log();
        event.preventDefault();
        event.stopPropagation();
        if (!form[0].checkValidity() === false) {
            const requestOptions = {
      
                email:email.email,
                token:token,
                password:password
              }
              
              axios.post(API_URL+'/api/ResetPassword' , requestOptions,{
                mode:"no-cors"
                
              })
              .then((response)=>{
                console.log(response)
                // console.log("response is " + JSON.stringify(response.data.status))
                let status = response.data.status;
                console.log(response.data.status,"status");
                  if(status != false )
                  {
                    swal({
                        title: "Done!",
                        text: response.data.message+ "",
                        icon: "success",
                        timer: 2000,
                        button: false,
                      });  
                    props.history.push('/login')

                  }else{
                    swal({
                        title: response.data.message+ "",
                        icon: "error",
                      }); 
                  }
                  
                //   console.warn("data", data)
          })
        }
        form.addClass("was-validated");
    //   });
  }
return (
    <section className="section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="card card-primary ">
              <div className="card-header">
                <h4 className="text-center">Reset Password </h4>
              </div>
              <div className="card-body">
                <form
                  method="POST"
                  action="#"
                  className="needs-validation"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      tabIndex={1}
                      value={email.email}
                      disabled
                      onChange={(event) => setEmail(event.target.value)}
                      required
                      autofocus
                    />
                    <div className="invalid-feedback">
                      Please fill in your email
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-block">
                      <label htmlFor="password" className="control-label">
                        New Password
                      </label>
                    </div>
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      onChange={(event) => setPassword(event.target.value)}
                      tabIndex={2}
                      required
                    />
                    <div className="invalid-feedback">
                      please fill in your password
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-block">
                      <label htmlFor="password" className="control-label">
                        Reset Password Code
                      </label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(event) => setToken(event.target.value)}
                      tabIndex={2}
                      required
                    />
                    <div className="invalid-feedback">
                      please fill reset Password
                    </div>
                  </div>
                  
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                      tabIndex={4}
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
