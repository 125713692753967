import React, { Component,forwardRef } from 'react'
import Header from '../../../component/Header';
import Sidebar from '../../../component/Sidebar';
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import { NavLink } from 'react-router-dom';
class BusinessHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      actions: [
        {
          icon: "edit",
          color: "green",
          tooltip: "Edit this user",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "green",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) =>
            this.props.history.push("/edit-business-hour/" + rowData.id),
        },
        {
          icon: "delete",
          tooltip: "Delete User",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "red",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) => {
            swal({
              title: "Are you sure?",
              text: "Are you sure that you want to delete this category?",
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                //   Delete APi
                fetch(
                  API_URL+"/api/delete_saloon_timing/" + rowData.id,
                  {
                    method: "GET",
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer'+" "+ JSON.parse(localStorage.getItem('authToken'))
                    },
                  }
                ).then((result) => {
                  // console.clear();
                  console.log("Delete",result.status)
                  if(result.status == 0){
                    swal(
                      "Error!",
                      "Something went wrong .. Please try again!",
                      "error"
                    );
                  }
                  else{
                    swal(
                      "Sucess!",
                      "Category Deleted Successfully !",
                      "success"
                    );
                    this.getData();
                  }
                  // 
                });

                
              }
            });
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.getData();
  }

  // Fetch Categories Function
  getData()
  {
      fetch(API_URL+"/api/saloon_timings?saloon_id="+localStorage.getItem('user_id'),{
          method: 'get',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer'+ localStorage.getItem('authToken')
            },
      }).then((data)=>{
          data.json().then((result)=>{
            if(result.status == true){
              console.clear()
              this.setState({data:result.saloonTimings})
            }
              console.log("Status is " ,result.saloonTimings)
           });
      });
  }
    render() {
      const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => (
          <DeleteOutline {...props} ref={ref} />
        )),
        DetailPanel: forwardRef((props, ref) => (
          <ChevronRight {...props} ref={ref} />
        )),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => (
          <ChevronRight {...props} ref={ref} />
        )),
        PreviousPage: forwardRef((props, ref) => (
          <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
          <ArrowDownward {...props} ref={ref} />
        )),
        ThirdStateCheck: forwardRef((props, ref) => (
          <Remove {...props} ref={ref} />
        )),
        ViewColumn: forwardRef((props, ref) => (
          <ViewColumn {...props} ref={ref} />
        )),
      };
  
      const columns = [
        { title: "Sno", field: "id" },
        { title: "Day", field: "day" },
        { title: "Opening time", field: "opening_time" },
        { title: "Closing time", field: "closing_time" },
        { title: "Status", field: "status" },
      ];
      const {data} = this.state
      const {actions} = this.state
  
        return (
            <div className="main-wrapper main-wrapper-1">
            <Header />
            <Sidebar />
            <div className="main-content">
              <section className="section">
                <div className="section-body">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Business Hour</h4>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                              <div className="col-md-12 text-right">
                                      <NavLink to="/add-business-hour" className="btn btn-primary mr-2">Add new</NavLink>
                                      <NavLink to="/add-close-date" className="btn btn-primary">Add Close Date</NavLink>
                              </div>
                          </div>
                          <div class="table-responsive">
                          <MaterialTable
                          title=""
                          data={data ? data : []}
                          columns={columns}
                          actions={actions}
                          icons={tableIcons}
                          options={{
                            actionsColumnIndex: -1,
                            sorting: true,
                          }}
                          onRowClick={(event, rowData) => {
                            this.props.history.push(
                              "/edit-business-hour/" + rowData.id
                            );
                          }}
                        />
                            {/* <table className="table table-striped" id="time">
                            <thead>
                                <tr>
                                  <th>Day of Week</th>
                                  <th>Opening Time</th>
                                  <th>Closing Time</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td >
                                    Monday
                                  </td>
                                  <td>12:00 AM</td>
                                  <td>12:00 PM</td>
                                  <td>
                                    <div className="badge badge-success badge-shadow">
                                      OPEN
                                    </div>
                                  </td>
                                  <td>
                                    <a href="#" className="btn btn-sm btn-primary mr-1">
                                      <i className="fa fa-edit"></i>
                                    </a>
                                    <a href="#" className="btn btn-sm btn-danger">
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td >
                                    Tuesday
                                  </td>
                                  <td>12:00 AM</td>
                                  <td>12:00 PM</td>
                                  <td>
                                    <div className="badge badge-success badge-shadow">
                                      OPEN
                                    </div>
                                  </td>
                                  <td>
                                    <a href="#" className="btn btn-sm btn-primary mr-1">
                                      <i className="fa fa-edit"></i>
                                    </a>
                                    <a href="#" className="btn btn-sm btn-danger">
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td >
                                    Wednesday
                                  </td>
                                  <td>12:00 AM</td>
                                  <td>12:00 PM</td>
                                  <td>
                                    <div className="badge badge-success badge-shadow">
                                      OPEN
                                    </div>
                                  </td>
                                  <td>
                                    <a href="#" className="btn btn-sm btn-primary mr-1">
                                      <i className="fa fa-edit"></i>
                                    </a>
                                    <a href="#" className="btn btn-sm btn-danger">
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td >
                                    Thursday
                                  </td>
                                  <td>12:00 AM</td>
                                  <td>12:00 PM</td>
                                  <td>
                                    <div className="badge badge-success badge-shadow">
                                      OPEN
                                    </div>
                                  </td>
                                  <td>
                                    <a href="#" className="btn btn-sm btn-primary mr-1">
                                      <i className="fa fa-edit"></i>
                                    </a>
                                    <a href="#" className="btn btn-sm btn-danger">
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td >
                                    Friday
                                  </td>
                                  <td>12:00 AM</td>
                                  <td>12:00 PM</td>
                                  <td>
                                    <div className="badge badge-success badge-shadow">
                                      OPEN
                                    </div>
                                  </td>
                                  <td>
                                    <a href="#" className="btn btn-sm btn-primary mr-1">
                                      <i className="fa fa-edit"></i>
                                    </a>
                                    <a href="#" className="btn btn-sm btn-danger">
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td >
                                    Saturday
                                  </td>
                                  <td>12:00 AM</td>
                                  <td>12:00 PM</td>
                                  <td>
                                    <div className="badge badge-success badge-shadow">
                                      OPEN
                                    </div>
                                  </td>
                                  <td>
                                    <NavLink to="/edit-business-hour" className="btn btn-sm btn-primary mr-1">
                                      <i className="fa fa-edit"></i>
                                    </NavLink>
                                    <a href="#" className="btn btn-sm btn-danger">
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                               
                              </tbody>
                            </table> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )
    }
}
export default BusinessHour;