import React, { Component } from 'react'
import Sidebar from '../../../component/Sidebar'
import Header from "../../../component/Header";
import './timeslot.css'
import Modal from 'react-modal';
import { Divider } from '@material-ui/core';
import { NavLink } from 'react-router-dom'

import { API_URL } from '../../../../BaseURL';
import moment from 'moment';
import Loader from '../../../component/Loader';
import axios from 'axios';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function getCurrentWeek(date) {
  var currentDate = moment(date);

  var weekStart = currentDate.clone().startOf('week');
  var weekEnd = currentDate.clone().endOf('week');

  var days = [];

  for (var i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').format("ddd D MMMM"));
  }

  const rangeString = `${weekStart.format("D")} - ${weekEnd.format("D")} ${weekStart.format("MMMM YYYY")}`

  console.log("range", rangeString)
  console.log(days);

  return {
    days,
    rangeString,
    weekStart,
    weekEnd
  }
}

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(
      moment(weekStart)
        .add(i, 'days')
        .toDate()
    );
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date)
      .startOf('week')
      .toDate(),
    to: moment(date)
      .endOf('week')
      .toDate(),
  };
}



export default class TimeSlot extends Component {
  constructor() {
    super();
    this.state = {
      setIsOpen: false,
      date: moment(),
      dateRange: "",
      weekArr: [],
      isLoading: false,
      weekStart: "",
      weekEnd: "",
      data: [],
      showdiv: "none",
      showdiv2: "none",
      showdiv3: "block",
      startDate: new Date(),

      // form state

      saloon_staff_id: 0,
      shift_date: "",
      shift_start: "",
      shift_end: "",
      repeat: "",
      end_repeat: "",
      username: "",
      ongoing_end_date: "",
      date_time_arr: []
    }
  }

  openModal(val, id, j, d) {

    console.log("val is =>", val)
    console.log("j is =>", j)
    console.log("Date is =>", d)
    this.setState({
      setIsOpen: true,
      username: j,
      shift_date: d,
      shift_start: val?.shift_start,
      shift_end: val?.shift_end,
      repeat: val?.repeat,
      end_repeat: val?.end_repeat,
      saloon_staff_id: id,

    })
    console.log("Date after setting state is = >", this.state.shift_date)
  }

  closeModal() {
    this.setState({ setIsOpen: false })
  }

  handleChange = (val) => {
    if (val == "weekly") {
      this.setState({
        showdiv: "block",
        repeat: "weekly"
      })
    }
    else {
      this.setState({ showdiv: "none", repeat: "no", end_repeat: "", ongoing_end_date: "" })
    }
  }

  handleCalendar = (val) => {
    if (val == "specific") {
      this.setState({ showdiv2: "block", showdiv3: "none", end_repeat: val, ongoing_end_date: "" })
    } else {
      console.clear();
      this.setState({ showdiv2: "none", showdiv3: "block", end_repeat: val })
      console.log("End_Repeat Values", this.state.end_repeat);
    }
  }

  getData = async () => {
    const { weekStart, weekEnd } = this.state
    this.setState({ isLoading: true })
    let saloonId = localStorage.getItem("user_id")
    try {
      // api/staff/working/hour <== Post
      //api/staff/working/hour/_start
      let response = await axios.post(`${API_URL}/api/staff/working/hour/list?saloon_d=${saloonId}`,{
        to: weekEnd.format("YYYY-MM-DD"),
        from: weekStart.format("YYYY-MM-DD")
      },{
        headers:{
         'mode': "no-cors",
          'Authorization': 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
          
        }
      })
      // console.clear();
      console.log(weekEnd.format("YYYY-MM-DD"))
      console.log(weekStart.format("YYYY-MM-DD"))
      console.log("get slots response", response)
      this.setState({ isLoading: false, })
      if (response.data.status) {
        this.setState({ data: response.data.message, date_time_arr: response.data.date_array })
        console.log('api back ', response.data)
      }
    } catch ({ message }) {
      this.setState({ isLoading: false })
      console.log(message)
    }
  }

  componentDidMount() {
    const { date } = this.state
    const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(date)
    this.setState({ dateRange: rangeString, weekArr: days, weekStart, weekEnd }, () => {
      this.getData()
    })
  }
  DateRangePicker = (date) => {
    // const { date } = this.state
    const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(date)
    this.setState({ dateRange: rangeString, weekArr: days, weekStart, weekEnd }, () => {
      this.getData()
    })
  }

  handleNextPrev = (isNext) => {
    const { date } = this.state
    const nextWeek = moment(date).add(1, "week")
    const prev = moment(date).subtract(1, "week")
    const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(isNext ? nextWeek.toDate() : prev.toDate())
    this.setState({
      dateRange: rangeString,
      weekArr: days,
      date: isNext ? nextWeek : prev,
      weekStart, weekEnd
    }, () => {

      this.getData()
    })
  }

  handleToday = () => {
    const { days, rangeString, weekStart, weekEnd } = getCurrentWeek(moment().toDate())
    this.setState({
      dateRange: rangeString,
      weekArr: days,
      date: moment(),
      weekStart,
      weekEnd
    }, () => {
      this.getData()
    })
  }

  HandleFormSubmit = () => {
    
    axios.post(`${API_URL}/api/staff/working/hour`, this.state, {
      method: "POST",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    })
      .then((response) => {
       
        let status = JSON.stringify(response.status);
        if (response.data.status == true) {
          swal({
            title: "Done!",
            text: response.data.message + "",
            icon: "success",
            timer: 2000,
            button: false,
          });
          this.setState({ setIsOpen: false }, () => {

            this.getData()
          })
          // this.props.history.push("/staff-list");
        }
      })

      .catch(function (error) {
        console.log(error)
        swal({
          title: "Opps!",
          text: error + "",
          icon: "error",
          timer: 2000,
          button: false,
        });
      });

    // console.log(this.state);
  }


  render() {
    const { weekArr, isLoading, data, date_time_arr } = this.state

    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="section container mb-5">
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-pills" id="myTab3" role="tablist">
                  <li className="nav-item">
                    <NavLink to="/time-slot" className="nav-link">Staff Working Hours</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/close-date" className="nav-link">Open Date</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/staff-list" className="nav-link">Staff Member</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/user-permission" className="nav-link">User Permission</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group row">
                    <div className="col-md-12 text-right">
                      <div className="float-right">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            <li className="page-item"><button className="page-link" onClick={() => this.handleNextPrev(false)}><i className="fa fa-chevron-left text-bold" ></i></button></li>
                            <li className="page-item"><button className="page-link" onClick={() => { this.handleToday() }}>Today</button></li>
                            <li className="page-item">
                              <button className="page-link" onClick={() => { this.dataPicker.setOpen(true) }}>
                                {this.state.dateRange}
                                <DatePicker ref={ref => this.dataPicker = ref} className={"d-none"} selected={this.state.startDate} onChange={date => this.DateRangePicker(date)} />
                              </button>
                            </li>
                            <li className="page-item"><button className="page-link" onClick={() => this.handleNextPrev(true)}><i className="fa fa-chevron-right text-bold" ></i></button></li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <table className="schedule-table">
                    <thead>
                      <tr style={{ height: '39px' }}>
                        <th>Staff</th>
                        {weekArr.map(val => <th>{val}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((val, index) => {
                        const workArr = val.working_hour
                        var formatWorkHour = []
                        for (let waIndex = 0; waIndex < workArr?.length; waIndex++) {
                          const date = workArr[waIndex];
                          const dayAvailableIndex = weekArr.findIndex(x => x == date.day)
                          console.log(dayAvailableIndex)
                          formatWorkHour = weekArr.map((d, ind) => {
                            if (!date) {
                              return {}
                            } else {
                              if (dayAvailableIndex !== -1 && ind == dayAvailableIndex) {
                                return date
                              } else {
                                return {}
                              }
                            }
                          })
                        }
                        return (
                          <tr style={{}}>

                            <td className="employee-name">
                              <div className="schedule-employee-name js-full-text">{val.full_name}</div>
                            </td>

                            <td
                              className={"range"}
                            >
                              <div
                                onClick={(e) => {
                                  // alert()
                                  // let date = moment(weekArr[weekArr[index]]).format('YYYY-MM-DD')
                                  let date = date_time_arr[0]
                                  this.openModal(val.sun, val.id, val.full_name, date)
                                }}
                                title={(val.sun == null || val.sun?.is_closed == true) ? "Closed Day" : ""} className={(val.sun == null || val.sun?.is_closed == true) ? "bg-dark text-white schedule-hours js-schedule-cell clickable" : "schedule-hours js-schedule-cell clickable"}>
                                {((val.sun == null || val.sun?.is_closed == true) ? "closed" : (val.sun?.shift_start + val.sun?.shift_end ? val.sun?.shift_start + "-" + val.sun?.shift_end : " "))}
                              </div>
                            </td>
                            <td className="range">
                              <div onClick={(e) => {
                                let date = date_time_arr[1]
                                // let date = moment(weekArr[weekArr[index]]).format('YYYY-MM-DD')
                                this.openModal(val.mon, val.id, val.full_name, date)
                              }} title={(val.mon == null || val.mon?.is_closed == true) ? "Closed Day" : ""} className={(val.mon == null || val.mon?.is_closed == true) ? "bg-dark text-white schedule-hours js-schedule-cell clickable" : "schedule-hours js-schedule-cell clickable"}>
                                {(val.mon == null || val.mon?.is_closed == true) ? "closed" : `${val.mon?.shift_start} - ${val.mon?.shift_end}`}
                              </div>
                            </td>
                            <td className="range" className={"range"}>
                              <div title={(val.tue == null || val.tue?.is_closed == true) ? "Closed Day" : ""} className={(val.tue == null || val.tue?.is_closed == true) ? "bg-dark text-white schedule-hours js-schedule-cell clickable" : "schedule-hours js-schedule-cell clickable"}
                                onClick={(e) => {
                                  let date = date_time_arr[2]
                                  this.openModal(val.tue, val.id, val.full_name, date)
                                }
                                } title data-toggle>
                                {(val.tue == null || val.tue?.is_closed == true) ? "closed" : `${val.tue?.shift_start} - ${val.tue?.shift_end}`}
                              </div>
                            </td>
                            <td className="range">
                              <div onClick={
                                (e) => {
                                  let date = date_time_arr[3]
                                  this.openModal(val.wed, val.id, val.full_name, date)
                                }
                              } title={(val.wed == null || val.wed?.is_closed == true) ? "Closed Day" : ""} className={(val.wed == null || val.wed?.is_closed == true) ? "bg-dark text-white schedule-hours js-schedule-cell clickable" : "schedule-hours js-schedule-cell clickable"}>
                                {(val.wed == null || val.wed?.is_closed == true) ? "closed" : `${val.wed?.shift_start} - ${val.wed?.shift_end}`}
                              </div>
                            </td>
                            <td className="range">
                              <div onClick={(e) => {
                                let date = date_time_arr[4]

                                this.openModal(val.thu, val.id, val.full_name, date)
                              }} title={(val.thu == null || val.thu?.is_closed == true) ? "Closed Day" : ""} className={(val.thu == null || val.thu?.is_closed == true) ? "bg-dark text-white schedule-hours js-schedule-cell clickable" : "schedule-hours js-schedule-cell clickable"}>
                                {(val.thu == null || val.thu?.is_closed == true) ? "closed" : `${val.thu?.shift_start} - ${val.thu?.shift_end}`}
                              </div>
                            </td>
                            <td className="range">
                              <div onClick={(e) => {
                                let date = date_time_arr[5]
                                this.openModal(val.fri, val.id, val.full_name, date)
                              }} title={(val.fri == null || val.fri?.is_closed == true) ? "Closed Day" : ""} className={(val.fri == null || val.fri?.is_closed == true) ? "bg-dark text-white schedule-hours js-schedule-cell clickable" : "schedule-hours js-schedule-cell clickable"}>
                                {(val.fri == null || val.fri?.is_closed == true) ? "closed" : `${val.fri?.shift_start} - ${val.fri?.shift_end}`}
                              </div>
                            </td>
                            <td className="range">
                              <div onClick={(e) => {
                                let date = date_time_arr[6]
                                this.openModal(val.sat, val.id, val.full_name, date)
                              }} title={(val.sat == null || val.sat?.is_closed == true) ? "Closed Day" : ""} className={(val.sat == null || val.sat?.is_closed == true) ? "bg-dark text-white schedule-hours js-schedule-cell clickable" : "schedule-hours js-schedule-cell clickable"}>
                                {(val.sat == null || val.sat?.is_closed == true) ? "closed" : `${val.sat?.shift_start} - ${val.sat?.shift_end}`}
                              </div>
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {isLoading && <Loader />}
          </section>
        </div>

        {/* <Loader/> */}
        <Modal
          isOpen={this.state.setIsOpen}
          onRequestClose={(e) => this.closeModal()}
          contentLabel="Example Modal"

        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header text-center">
                <div className="text-center">
                  <h5 class="modal-title text-center">{this.state.username}'s Shift</h5>
                  <p>{this.state.shift_date}</p>
                </div>
                <button type="button" class="close" onClick={(e) => this.closeModal()}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

                <form className="row form-group">
                  <div className="col-md-6">
                    <label>Shift Start</label>
                    <select onChange={(event) => { this.setState({ shift_start: event.target.value }) }} defaultValue={this.state.shift_start} className="form-control">
                      <option value="00:05">00:05</option>
                      <option value="00:10">00:10</option>
                      <option value="00:15">00:15</option>
                      <option value="00:20">00:20</option>
                      <option value="00:25">00:25</option>
                      <option value="00:30">00:30</option>
                      <option value="00:35">00:35</option>
                      <option value="00:40">00:40</option>
                      <option value="00:45">00:45</option>
                      <option value="00:50">00:50</option>
                      <option value="00:55">00:55</option>
                      <option value="01:00">01:00</option>
                      <option value="01:05">01:05</option>
                      <option value="01:10">01:10</option>
                      <option value="01:15">01:15</option>
                      <option value="01:20">01:20</option>
                      <option value="01:25">01:25</option>
                      <option value="01:30">01:30</option>
                      <option value="01:35">01:35</option>
                      <option value="01:40">01:40</option>
                      <option value="01:45">01:45</option>
                      <option value="01:50">01:50</option>
                      <option value="01:55">01:55</option>
                      <option value="02:00">02:00</option>
                      <option value="02:05">02:05</option>
                      <option value="02:10">02:10</option>
                      <option value="02:15">02:15</option>
                      <option value="02:20">02:20</option>
                      <option value="02:25">02:25</option>
                      <option value="02:30">02:30</option>
                      <option value="02:35">02:35</option>
                      <option value="02:40">02:40</option>
                      <option value="02:45">02:45</option>
                      <option value="02:50">02:50</option>
                      <option value="02:55">02:55</option>
                      <option value="03:00">03:00</option>
                      <option value="03:05">03:05</option>
                      <option value="03:10">03:10</option>
                      <option value="03:15">03:15</option>
                      <option value="03:20">03:20</option>
                      <option value="03:25">03:25</option>
                      <option value="03:30">03:30</option>
                      <option value="03:35">03:35</option>
                      <option value="03:40">03:40</option>
                      <option value="03:45">03:45</option>
                      <option value="03:50">03:50</option>
                      <option value="03:55">03:55</option>
                      <option value="04:00">04:00</option>
                      <option value="04:05">04:05</option>
                      <option value="04:10">04:10</option>
                      <option value="04:15">04:15</option>
                      <option value="04:20">04:20</option>
                      <option value="04:25">04:25</option>
                      <option value="04:30">04:30</option>
                      <option value="04:35">04:35</option>
                      <option value="04:40">04:40</option>
                      <option value="04:45">04:45</option>
                      <option value="04:50">04:50</option>
                      <option value="04:55">04:55</option>
                      <option value="05:00">05:00</option>
                      <option value="05:05">05:05</option>
                      <option value="05:10">05:10</option>
                      <option value="05:15">05:15</option>
                      <option value="05:20">05:20</option>
                      <option value="05:25">05:25</option>
                      <option value="05:30">05:30</option>
                      <option value="05:35">05:35</option>
                      <option value="05:40">05:40</option>
                      <option value="05:45">05:45</option>
                      <option value="05:50">05:50</option>
                      <option value="05:55">05:55</option>
                      <option value="06:00">06:00</option>
                      <option value="06:05">06:05</option>
                      <option value="06:10">06:10</option>
                      <option value="06:15">06:15</option>
                      <option value="06:20">06:20</option>
                      <option value="06:25">06:25</option>
                      <option value="06:30">06:30</option>
                      <option value="06:35">06:35</option>
                      <option value="06:40">06:40</option>
                      <option value="06:45">06:45</option>
                      <option value="06:50">06:50</option>
                      <option value="06:55">06:55</option>
                      <option value="07:00">07:00</option>
                      <option value="07:05">07:05</option>
                      <option value="07:10">07:10</option>
                      <option value="07:15">07:15</option>
                      <option value="07:20">07:20</option>
                      <option value="07:25">07:25</option>
                      <option value="07:30">07:30</option>
                      <option value="07:35">07:35</option>
                      <option value="07:40">07:40</option>
                      <option value="07:45">07:45</option>
                      <option value="07:50">07:50</option>
                      <option value="07:55">07:55</option>
                      <option value="08:00">08:00</option>
                      <option value="08:05">08:05</option>
                      <option value="08:10">08:10</option>
                      <option value="08:15">08:15</option>
                      <option value="08:20">08:20</option>
                      <option value="08:25">08:25</option>
                      <option value="08:30">08:30</option>
                      <option value="08:35">08:35</option>
                      <option value="08:40">08:40</option>
                      <option value="08:45">08:45</option>
                      <option value="08:50">08:50</option>
                      <option value="08:55">08:55</option>
                      <option value="09:00">09:00</option>
                      <option value="09:05">09:05</option>
                      <option value="09:10">09:10</option>
                      <option value="09:15">09:15</option>
                      <option value="09:20">09:20</option>
                      <option value="09:25">09:25</option>
                      <option value="09:30">09:30</option>
                      <option value="09:35">09:35</option>
                      <option value="09:40">09:40</option>
                      <option value="09:45">09:45</option>
                      <option value="09:50">09:50</option>
                      <option value="09:55">09:55</option>
                      <option value="10:00">10:00</option>
                      <option value="10:05">10:05</option>
                      <option value="10:10">10:10</option>
                      <option value="10:15">10:15</option>
                      <option value="10:20">10:20</option>
                      <option value="10:25">10:25</option>
                      <option value="10:30">10:30</option>
                      <option value="10:35">10:35</option>
                      <option value="10:40">10:40</option>
                      <option value="10:45">10:45</option>
                      <option value="10:50">10:50</option>
                      <option value="10:55">10:55</option>
                      <option value="11:00">11:00</option>
                      <option value="11:05">11:05</option>
                      <option value="11:10">11:10</option>
                      <option value="11:15">11:15</option>
                      <option value="11:20">11:20</option>
                      <option value="11:25">11:25</option>
                      <option value="11:30">11:30</option>
                      <option value="11:35">11:35</option>
                      <option value="11:40">11:40</option>
                      <option value="11:45">11:45</option>
                      <option value="11:50">11:50</option>
                      <option value="11:55">11:55</option>
                      <option value="12:00">12:00</option>
                      <option value="12:05">12:05</option>
                      <option value="12:10">12:10</option>
                      <option value="12:15">12:15</option>
                      <option value="12:20">12:20</option>
                      <option value="12:25">12:25</option>
                      <option value="12:30">12:30</option>
                      <option value="12:35">12:35</option>
                      <option value="12:40">12:40</option>
                      <option value="12:45">12:45</option>
                      <option value="12:50">12:50</option>
                      <option value="12:55">12:55</option>
                      <option value="13:00">13:00</option>
                      <option value="13:05">13:05</option>
                      <option value="13:10">13:10</option>
                      <option value="13:15">13:15</option>
                      <option value="13:20">13:20</option>
                      <option value="13:25">13:25</option>
                      <option value="13:30">13:30</option>
                      <option value="13:35">13:35</option>
                      <option value="13:40">13:40</option>
                      <option value="13:45">13:45</option>
                      <option value="13:50">13:50</option>
                      <option value="13:55">13:55</option>
                      <option value="14:00">14:00</option>
                      <option value="14:05">14:05</option>
                      <option value="14:10">14:10</option>
                      <option value="14:15">14:15</option>
                      <option value="14:20">14:20</option>
                      <option value="14:25">14:25</option>
                      <option value="14:30">14:30</option>
                      <option value="14:35">14:35</option>
                      <option value="14:40">14:40</option>
                      <option value="14:45">14:45</option>
                      <option value="14:50">14:50</option>
                      <option value="14:55">14:55</option>
                      <option value="15:00">15:00</option>
                      <option value="15:05">15:05</option>
                      <option value="15:10">15:10</option>
                      <option value="15:15">15:15</option>
                      <option value="15:20">15:20</option>
                      <option value="15:25">15:25</option>
                      <option value="15:30">15:30</option>
                      <option value="15:35">15:35</option>
                      <option value="15:40">15:40</option>
                      <option value="15:45">15:45</option>
                      <option value="15:50">15:50</option>
                      <option value="15:55">15:55</option>
                      <option value="16:00">16:00</option>
                      <option value="16:05">16:05</option>
                      <option value="16:10">16:10</option>
                      <option value="16:15">16:15</option>
                      <option value="16:20">16:20</option>
                      <option value="16:25">16:25</option>
                      <option value="16:30">16:30</option>
                      <option value="16:35">16:35</option>
                      <option value="16:40">16:40</option>
                      <option value="16:45">16:45</option>
                      <option value="16:50">16:50</option>
                      <option value="16:55">16:55</option>
                      <option value="17:00">17:00</option>
                      <option value="17:05">17:05</option>
                      <option value="17:10">17:10</option>
                      <option value="17:15">17:15</option>
                      <option value="17:20">17:20</option>
                      <option value="17:25">17:25</option>
                      <option value="17:30">17:30</option>
                      <option value="17:35">17:35</option>
                      <option value="17:40">17:40</option>
                      <option value="17:45">17:45</option>
                      <option value="17:50">17:50</option>
                      <option value="17:55">17:55</option>
                      <option value="18:00">18:00</option>
                      <option value="18:05">18:05</option>
                      <option value="18:10">18:10</option>
                      <option value="18:15">18:15</option>
                      <option value="18:20">18:20</option>
                      <option value="18:25">18:25</option>
                      <option value="18:30">18:30</option>
                      <option value="18:35">18:35</option>
                      <option value="18:40">18:40</option>
                      <option value="18:45">18:45</option>
                      <option value="18:50">18:50</option>
                      <option value="18:55">18:55</option>
                      <option value="19:00">19:00</option>
                      <option value="19:05">19:05</option>
                      <option value="19:10">19:10</option>
                      <option value="19:15">19:15</option>
                      <option value="19:20">19:20</option>
                      <option value="19:25">19:25</option>
                      <option value="19:30">19:30</option>
                      <option value="19:35">19:35</option>
                      <option value="19:40">19:40</option>
                      <option value="19:45">19:45</option>
                      <option value="19:50">19:50</option>
                      <option value="19:55">19:55</option>
                      <option value="20:00">20:00</option>
                      <option value="20:05">20:05</option>
                      <option value="20:10">20:10</option>
                      <option value="20:15">20:15</option>
                      <option value="20:20">20:20</option>
                      <option value="20:25">20:25</option>
                      <option value="20:30">20:30</option>
                      <option value="20:35">20:35</option>
                      <option value="20:40">20:40</option>
                      <option value="20:45">20:45</option>
                      <option value="20:50">20:50</option>
                      <option value="20:55">20:55</option>
                      <option value="21:00">21:00</option>
                      <option value="21:05">21:05</option>
                      <option value="21:10">21:10</option>
                      <option value="21:15">21:15</option>
                      <option value="21:20">21:20</option>
                      <option value="21:25">21:25</option>
                      <option value="21:30">21:30</option>
                      <option value="21:35">21:35</option>
                      <option value="21:40">21:40</option>
                      <option value="21:45">21:45</option>
                      <option value="21:50">21:50</option>
                      <option value="21:55">21:55</option>
                      <option value="22:00">22:00</option>
                      <option value="22:05">22:05</option>
                      <option value="22:10">22:10</option>
                      <option value="22:15">22:15</option>
                      <option value="22:20">22:20</option>
                      <option value="22:25">22:25</option>
                      <option value="22:30">22:30</option>
                      <option value="22:35">22:35</option>
                      <option value="22:40">22:40</option>
                      <option value="22:45">22:45</option>
                      <option value="22:50">22:50</option>
                      <option value="22:55">22:55</option>
                      <option value="23:00">23:00</option>
                      <option value="23:05">23:05</option>
                      <option value="23:10">23:10</option>
                      <option value="23:15">23:15</option>
                      <option value="23:20">23:20</option>
                      <option value="23:25">23:25</option>
                      <option value="23:30">23:30</option>
                      <option value="23:35">23:35</option>
                      <option value="23:40">23:40</option>
                      <option value="23:45">23:45</option>
                      <option value="23:50">23:50</option>
                      <option value="23:55">23:55</option>
                    </select>

                  </div>
                  <div className="col-md-6">
                    <label>End Shift</label>
                    <select onChange={(event) => { this.setState({ shift_end: event.target.value }) }} defaultValue={this.state.shift_end} className="form-control">
                      <option value="00:05">00:05</option>
                      <option value="00:10">00:10</option>
                      <option value="00:15">00:15</option>
                      <option value="00:20">00:20</option>
                      <option value="00:25">00:25</option>
                      <option value="00:30">00:30</option>
                      <option value="00:35">00:35</option>
                      <option value="00:40">00:40</option>
                      <option value="00:45">00:45</option>
                      <option value="00:50">00:50</option>
                      <option value="00:55">00:55</option>
                      <option value="01:00">01:00</option>
                      <option value="01:05">01:05</option>
                      <option value="01:10">01:10</option>
                      <option value="01:15">01:15</option>
                      <option value="01:20">01:20</option>
                      <option value="01:25">01:25</option>
                      <option value="01:30">01:30</option>
                      <option value="01:35">01:35</option>
                      <option value="01:40">01:40</option>
                      <option value="01:45">01:45</option>
                      <option value="01:50">01:50</option>
                      <option value="01:55">01:55</option>
                      <option value="02:00">02:00</option>
                      <option value="02:05">02:05</option>
                      <option value="02:10">02:10</option>
                      <option value="02:15">02:15</option>
                      <option value="02:20">02:20</option>
                      <option value="02:25">02:25</option>
                      <option value="02:30">02:30</option>
                      <option value="02:35">02:35</option>
                      <option value="02:40">02:40</option>
                      <option value="02:45">02:45</option>
                      <option value="02:50">02:50</option>
                      <option value="02:55">02:55</option>
                      <option value="03:00">03:00</option>
                      <option value="03:05">03:05</option>
                      <option value="03:10">03:10</option>
                      <option value="03:15">03:15</option>
                      <option value="03:20">03:20</option>
                      <option value="03:25">03:25</option>
                      <option value="03:30">03:30</option>
                      <option value="03:35">03:35</option>
                      <option value="03:40">03:40</option>
                      <option value="03:45">03:45</option>
                      <option value="03:50">03:50</option>
                      <option value="03:55">03:55</option>
                      <option value="04:00">04:00</option>
                      <option value="04:05">04:05</option>
                      <option value="04:10">04:10</option>
                      <option value="04:15">04:15</option>
                      <option value="04:20">04:20</option>
                      <option value="04:25">04:25</option>
                      <option value="04:30">04:30</option>
                      <option value="04:35">04:35</option>
                      <option value="04:40">04:40</option>
                      <option value="04:45">04:45</option>
                      <option value="04:50">04:50</option>
                      <option value="04:55">04:55</option>
                      <option value="05:00">05:00</option>
                      <option value="05:05">05:05</option>
                      <option value="05:10">05:10</option>
                      <option value="05:15">05:15</option>
                      <option value="05:20">05:20</option>
                      <option value="05:25">05:25</option>
                      <option value="05:30">05:30</option>
                      <option value="05:35">05:35</option>
                      <option value="05:40">05:40</option>
                      <option value="05:45">05:45</option>
                      <option value="05:50">05:50</option>
                      <option value="05:55">05:55</option>
                      <option value="06:00">06:00</option>
                      <option value="06:05">06:05</option>
                      <option value="06:10">06:10</option>
                      <option value="06:15">06:15</option>
                      <option value="06:20">06:20</option>
                      <option value="06:25">06:25</option>
                      <option value="06:30">06:30</option>
                      <option value="06:35">06:35</option>
                      <option value="06:40">06:40</option>
                      <option value="06:45">06:45</option>
                      <option value="06:50">06:50</option>
                      <option value="06:55">06:55</option>
                      <option value="07:00">07:00</option>
                      <option value="07:05">07:05</option>
                      <option value="07:10">07:10</option>
                      <option value="07:15">07:15</option>
                      <option value="07:20">07:20</option>
                      <option value="07:25">07:25</option>
                      <option value="07:30">07:30</option>
                      <option value="07:35">07:35</option>
                      <option value="07:40">07:40</option>
                      <option value="07:45">07:45</option>
                      <option value="07:50">07:50</option>
                      <option value="07:55">07:55</option>
                      <option value="08:00">08:00</option>
                      <option value="08:05">08:05</option>
                      <option value="08:10">08:10</option>
                      <option value="08:15">08:15</option>
                      <option value="08:20">08:20</option>
                      <option value="08:25">08:25</option>
                      <option value="08:30">08:30</option>
                      <option value="08:35">08:35</option>
                      <option value="08:40">08:40</option>
                      <option value="08:45">08:45</option>
                      <option value="08:50">08:50</option>
                      <option value="08:55">08:55</option>
                      <option value="09:00">09:00</option>
                      <option value="09:05">09:05</option>
                      <option value="09:10">09:10</option>
                      <option value="09:15">09:15</option>
                      <option value="09:20">09:20</option>
                      <option value="09:25">09:25</option>
                      <option value="09:30">09:30</option>
                      <option value="09:35">09:35</option>
                      <option value="09:40">09:40</option>
                      <option value="09:45">09:45</option>
                      <option value="09:50">09:50</option>
                      <option value="09:55">09:55</option>
                      <option value="10:00">10:00</option>
                      <option value="10:05">10:05</option>
                      <option value="10:10">10:10</option>
                      <option value="10:15">10:15</option>
                      <option value="10:20">10:20</option>
                      <option value="10:25">10:25</option>
                      <option value="10:30">10:30</option>
                      <option value="10:35">10:35</option>
                      <option value="10:40">10:40</option>
                      <option value="10:45">10:45</option>
                      <option value="10:50">10:50</option>
                      <option value="10:55">10:55</option>
                      <option value="11:00">11:00</option>
                      <option value="11:05">11:05</option>
                      <option value="11:10">11:10</option>
                      <option value="11:15">11:15</option>
                      <option value="11:20">11:20</option>
                      <option value="11:25">11:25</option>
                      <option value="11:30">11:30</option>
                      <option value="11:35">11:35</option>
                      <option value="11:40">11:40</option>
                      <option value="11:45">11:45</option>
                      <option value="11:50">11:50</option>
                      <option value="11:55">11:55</option>
                      <option value="12:00">12:00</option>
                      <option value="12:05">12:05</option>
                      <option value="12:10">12:10</option>
                      <option value="12:15">12:15</option>
                      <option value="12:20">12:20</option>
                      <option value="12:25">12:25</option>
                      <option value="12:30">12:30</option>
                      <option value="12:35">12:35</option>
                      <option value="12:40">12:40</option>
                      <option value="12:45">12:45</option>
                      <option value="12:50">12:50</option>
                      <option value="12:55">12:55</option>
                      <option value="13:00">13:00</option>
                      <option value="13:05">13:05</option>
                      <option value="13:10">13:10</option>
                      <option value="13:15">13:15</option>
                      <option value="13:20">13:20</option>
                      <option value="13:25">13:25</option>
                      <option value="13:30">13:30</option>
                      <option value="13:35">13:35</option>
                      <option value="13:40">13:40</option>
                      <option value="13:45">13:45</option>
                      <option value="13:50">13:50</option>
                      <option value="13:55">13:55</option>
                      <option value="14:00">14:00</option>
                      <option value="14:05">14:05</option>
                      <option value="14:10">14:10</option>
                      <option value="14:15">14:15</option>
                      <option value="14:20">14:20</option>
                      <option value="14:25">14:25</option>
                      <option value="14:30">14:30</option>
                      <option value="14:35">14:35</option>
                      <option value="14:40">14:40</option>
                      <option value="14:45">14:45</option>
                      <option value="14:50">14:50</option>
                      <option value="14:55">14:55</option>
                      <option value="15:00">15:00</option>
                      <option value="15:05">15:05</option>
                      <option value="15:10">15:10</option>
                      <option value="15:15">15:15</option>
                      <option value="15:20">15:20</option>
                      <option value="15:25">15:25</option>
                      <option value="15:30">15:30</option>
                      <option value="15:35">15:35</option>
                      <option value="15:40">15:40</option>
                      <option value="15:45">15:45</option>
                      <option value="15:50">15:50</option>
                      <option value="15:55">15:55</option>
                      <option value="16:00">16:00</option>
                      <option value="16:05">16:05</option>
                      <option value="16:10">16:10</option>
                      <option value="16:15">16:15</option>
                      <option value="16:20">16:20</option>
                      <option value="16:25">16:25</option>
                      <option value="16:30">16:30</option>
                      <option value="16:35">16:35</option>
                      <option value="16:40">16:40</option>
                      <option value="16:45">16:45</option>
                      <option value="16:50">16:50</option>
                      <option value="16:55">16:55</option>
                      <option value="17:00">17:00</option>
                      <option value="17:05">17:05</option>
                      <option value="17:10">17:10</option>
                      <option value="17:15">17:15</option>
                      <option value="17:20">17:20</option>
                      <option value="17:25">17:25</option>
                      <option value="17:30">17:30</option>
                      <option value="17:35">17:35</option>
                      <option value="17:40">17:40</option>
                      <option value="17:45">17:45</option>
                      <option value="17:50">17:50</option>
                      <option value="17:55">17:55</option>
                      <option value="18:00">18:00</option>
                      <option value="18:05">18:05</option>
                      <option value="18:10">18:10</option>
                      <option value="18:15">18:15</option>
                      <option value="18:20">18:20</option>
                      <option value="18:25">18:25</option>
                      <option value="18:30">18:30</option>
                      <option value="18:35">18:35</option>
                      <option value="18:40">18:40</option>
                      <option value="18:45">18:45</option>
                      <option value="18:50">18:50</option>
                      <option value="18:55">18:55</option>
                      <option value="19:00">19:00</option>
                      <option value="19:05">19:05</option>
                      <option value="19:10">19:10</option>
                      <option value="19:15">19:15</option>
                      <option value="19:20">19:20</option>
                      <option value="19:25">19:25</option>
                      <option value="19:30">19:30</option>
                      <option value="19:35">19:35</option>
                      <option value="19:40">19:40</option>
                      <option value="19:45">19:45</option>
                      <option value="19:50">19:50</option>
                      <option value="19:55">19:55</option>
                      <option value="20:00">20:00</option>
                      <option value="20:05">20:05</option>
                      <option value="20:10">20:10</option>
                      <option value="20:15">20:15</option>
                      <option value="20:20">20:20</option>
                      <option value="20:25">20:25</option>
                      <option value="20:30">20:30</option>
                      <option value="20:35">20:35</option>
                      <option value="20:40">20:40</option>
                      <option value="20:45">20:45</option>
                      <option value="20:50">20:50</option>
                      <option value="20:55">20:55</option>
                      <option value="21:00">21:00</option>
                      <option value="21:05">21:05</option>
                      <option value="21:10">21:10</option>
                      <option value="21:15">21:15</option>
                      <option value="21:20">21:20</option>
                      <option value="21:25">21:25</option>
                      <option value="21:30">21:30</option>
                      <option value="21:35">21:35</option>
                      <option value="21:40">21:40</option>
                      <option value="21:45">21:45</option>
                      <option value="21:50">21:50</option>
                      <option value="21:55">21:55</option>
                      <option value="22:00">22:00</option>
                      <option value="22:05">22:05</option>
                      <option value="22:10">22:10</option>
                      <option value="22:15">22:15</option>
                      <option value="22:20">22:20</option>
                      <option value="22:25">22:25</option>
                      <option value="22:30">22:30</option>
                      <option value="22:35">22:35</option>
                      <option value="22:40">22:40</option>
                      <option value="22:45">22:45</option>
                      <option value="22:50">22:50</option>
                      <option value="22:55">22:55</option>
                      <option value="23:00">23:00</option>
                      <option value="23:05">23:05</option>
                      <option value="23:10">23:10</option>
                      <option value="23:15">23:15</option>
                      <option value="23:20">23:20</option>
                      <option value="23:25">23:25</option>
                      <option value="23:30">23:30</option>
                      <option value="23:35">23:35</option>
                      <option value="23:40">23:40</option>
                      <option value="23:45">23:45</option>
                      <option value="23:50">23:50</option>
                      <option value="23:55">23:55</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Repeat</label>
                    <select defaultValue={this.state.repeat} onChange={(event) => { this.handleChange(event.target.value) }} className="form-control">
                      <option value="no">Don't Repeat</option>
                      <option value="weekly">Weekly</option>
                    </select>
                  </div>
                  <div className="col-md-6" style={{ display: this.state.showdiv }}>
                    <label>End Repeat</label>
                    <input className="form-control" onChange={(event) => { this.setState({ ongoing_end_date: event.target.value }) }} type="date" style={{ display: this.state.showdiv2 }} /><span style={{ display: this.state.showdiv2 }} onClick={(event) => { this.setState({ showdiv2: "none", showdiv3: "block" }) }} className="cross">X</span>
                    <select defaultValue={this.state.end_repeat} onChange={(event) => { this.handleCalendar(event.target.value) }} style={{ display: this.state.showdiv3 }} className="form-control">
                      <option value="ongoing">ongoing</option>
                      <option value="specific">Specific Date</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="modal-footer bg-whitesmoke br">
                <button type="button" onClick={this.HandleFormSubmit} class="btn btn-primary">Save</button>
                <button type="button" onClick={(event) => { this.closeModal() }} class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </Modal>

      </div>

    )
  }
}
