import React, { Component, forwardRef } from 'react'
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import Header from '../../../component/Header';
import Sidebar from '../../../component/Sidebar';
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Footer from '../../../component/Footer';
import { NavLink } from 'react-router-dom';
// import userimg from '../../assets/img/user.png'

import './index.css'
import $ from 'jquery'
import axios from 'axios';
class StaffList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      image: [],
      actions: [
        {
          icon: "edit",
          color: "green",
          tooltip: "Edit this user",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "green",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) =>
            this.props.history.push("/edit-staff/" + rowData.id),

        },
        {
          icon: "delete",
          tooltip: "Delete User",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "red",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) => {
            swal({
              title: "Are you sure?",
              text: "Are you sure that you want to delete this user?",
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                //   Delete APi
                fetch(
                  API_URL + "/api/staff/" + rowData.id,
                  {
                    method: "DELETE",
                    headers:{
                      mode: "no-cors",
                      Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                      
                    }
                  }
                ).then((result) => {
                  // console.clear();
                  console.log("Delete", result.status)
                  if (result.status == 0) {
                    swal(
                      "Error!",
                      "Something went wrong .. Please try again!",
                      "error"
                    );
                  }
                  else {
                    swal(
                      "Sucess!",
                      "Category Deleted Successfully !",
                      "success"
                    );
                    this.getData();
                  }
                  // 
                });
              }
            });
          },
        },
      ],
      nextUrl: "",
      prevUrl: ""
    };
  }
  componentDidMount() {
    this.getData();
    $(document).ready(function () {

      $('button').on('click', function (e) {
        if ($(this).hasClass('list')) {
          $('#container ul').addClass('list').removeClass('grid');
          $('#container ul li').addClass('text-left').removeClass('text-center');
          $('.sidebar-user-details-card').addClass('list-content');

        } else if ($(this).hasClass('grid')) {
          $('#container ul').addClass('grid').removeClass('list');
          $('#container ul li').addClass('text-center').removeClass('text-left');
          $('.sidebar-user-details-card').removeClass('list-content');

        }
      });
    });
  }
  // Fetch Staff Function
  getData() {
    let saloonId = localStorage.getItem("user_id")
    fetch(API_URL + "/api/staff?saloon_id="+saloonId, {
      method: 'GET',
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((data) => {
      return data.json()
    }).then((result) => {
      if (result.status == true) {
        console.log("staffs", result)
        
        this.setState({
          data: result.staffs.data,
          nextUrl: result.staffs.next_page_url,
          prevUrl: result.staffs.prev_page_url,
        })  

      }
    });

  }

  deletePost = async (id) => {
    let res = await axios.post(`${API_URL}/api/staff/${id}`, {}, {
      method: "POST",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    })

  }

  handleNextPrev = async (isNext) => {
    const { nextUrl, prevUrl } = this.state
    try {
      let response = await axios.get(isNext ? nextUrl : prevUrl)
      console.log("next prev res", response)
      if(response.data.status){
        this.setState({
          data: response.data.staffs.data,
          nextUrl: response.data.staffs.next_page_url,
          prevUrl: response.data.staffs.prev_page_url,
        })
      }
    } catch (error) {
      
    }
  }

  render() {
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
    };


    const { data } = this.state
    const { actions } = this.state
    const columns = [
      { title: "Image", field: "profile_pic", render: rowData => <img style={{ border: "2px solid white" }} src={rowData.img} alt="" border="3" width="50" /> },
      { title: "Staff Member Name", field: "full_name" },
      { title: "Email", field: "email" },
      { title: "Calendar Booking", field: "calendar_bookings" },
    ];
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="section container mb-5">
            <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-pills" id="myTab3" role="tablist">
                    <li className="nav-item">
                      <NavLink to="/time-slot" className="nav-link">Staff Working Hours</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/close-date" className="nav-link">Open Date</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/staff-list" className="nav-link">Staff Member</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/user-permission" className="nav-link">User Permission</NavLink>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Staff list</h4>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-12 text-right">
                          <NavLink to="/add-staff" className="btn btn-primary">Add New Staff</NavLink>
                        </div>
                      </div>
                      {/* <div class="table-responsive">
                        <MaterialTable
                          title=""
                          data={data ? data : []}
                          columns={columns}
                          actions={actions}
                          icons={tableIcons}
                          options={{
                            actionsColumnIndex: -1,
                            sorting: true,
                          }}
                          onRowClick={(event, rowData) => {
                            this.props.history.push(
                              "/add-staff/"
                            );
                          }}
                        />

                      </div> */}
                      <div id="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-2">
                              <button className="grid square-btn"><i className="fa fa-th-large fa-2x" /></button>
                              <button className="list square-btn"><i className="fa fa-bars fa-2x" /></button>
                            </div>
                          </div>
                        </div>
                        <ul className="container grid">
                          {data && data.length > 0 ? data.map((value) => {
                            return (
                              <li className="text-center" >
                                <NavLink to={"/edit-staff/" + value.id}>
                                  <div className="sidebar-user  mt-3">
                                    <div className="sidebar-user-picture">
                                      <img
                                        alt="image"
                                        className="mb-3 staffImg1"
                                        ref={ref => {
                                          this[`profileImageRef${value.id}`] = ref
                                        }}
                                        id={`staffImg${value.id}`}
                                        src={value.img}
                                        onError={(er) => {
                                          this[`profileImageRef${value.id}`].onerror = null
                                          this[`profileImageRef${value.id}`].src = 'assets/img/user.png'
                                        }}
                                        width={100}
                                        height={100} />
                                    </div>
                                    <div className="sidebar-user-details sidebar-user-details-card">
                                      <div className="user-name text-bold">{value.full_name}</div>
                                      <div className="user-role">{value.email}</div>
                                    </div>
                                  </div>
                                  {/* <div className="row justify-content-center mt-2"> */}
                                 <div className="row my-5">
                                      <div className="col-md-12 text-center">
                                      <a href={`mailto:${value.email}`} onClick={(e) => e.stopPropagation()} className="btn-primary btn-sm text-white email-btn" style={{ margin: 20 }}>
                                        {/* <i className="fa fa-envelope"></i> */}
                                        <svg style={{width: "10%"}} viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 8.372a.6.6 0 01.6.6v5.534c0 .331.13.648.362.881.232.233.545.364.871.364h16.5c.326 0 .64-.13.871-.364a1.25 1.25 0 00.363-.88V8.971a.6.6 0 111.2 0v5.534a2.45 2.45 0 01-.712 1.727 2.428 2.428 0 01-1.722.718h-16.5a2.428 2.428 0 01-1.722-.718A2.45 2.45 0 01.4 14.506V8.972a.6.6 0 01.6-.6z" fill="#F19101"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M1.111.873A2.428 2.428 0 012.833.156h16.5c.647 0 1.266.258 1.722.717a2.45 2.45 0 01.712 1.727v2.767a.6.6 0 01-.312.526l-10.083 5.534a.6.6 0 01-.577 0L.71 5.893A.6.6 0 01.4 5.367V2.6c0-.647.255-1.268.711-1.727zm1.722.483c-.326 0-.639.13-.87.363A1.25 1.25 0 001.6 2.6v2.412l9.483 5.204 9.484-5.204V2.6c0-.331-.131-.648-.363-.881a1.227 1.227 0 00-.87-.363h-16.5z" fill="#F19101"></path></svg>
                                      </a>
                                      </div>
                                 </div>
                                  {/* <button className="btn-danger btn-sm ml-2">
                                      <i className="fa fa-trash"></i>
                                    </button>  */}
                                  {/* </div>*/}
                                </NavLink>
                              </li>
                            )
                          }) : <p className="text-center bg-warning p-3">No Staff Available Please Add one.</p>}

                          {/* <div className="row justify-content-center">
                            <button className="btn-lg ml-2">
                              <i className="fa fa-trash"></i>
                            </button>
                            <button className="btn-lg ml-2">
                              <i className="fa fa-trash"></i>
                            </button>
                          </div> */}

                          {/* <li className="text-center">
                            <NavLink to="/edit-staff/">
                              <div className="sidebar-user  mt-3">
                                <div className="sidebar-user-picture">
                                  <img alt="image" src="assets/img/user.png" width={100} height={100} />
                                </div>
                                <div className="sidebar-user-details sidebar-user-details-card">
                                  <div className="user-name text-bold">Test User </div>
                                  <div className="user-role">Admin</div>
                                </div>
                              </div>
                              <button className="btn-primary btn-sm">
                                <i className="fa fa-envelope"></i>
                              </button>
                            </NavLink>
                          </li>
                          <li className="text-center">
                            <NavLink to="/edit-staff/">
                              <div className="sidebar-user  mt-3">
                                <div className="sidebar-user-picture">
                                  <img alt="image" src="assets/img/user.png" width={100} height={100} />
                                </div>
                                <div className="sidebar-user-details sidebar-user-details-card">
                                  <div className="user-name text-bold">Test User </div>
                                  <div className="user-role">Admin</div>
                                </div>
                              </div>
                              <button className="btn-primary btn-sm">
                                <i className="fa fa-envelope"></i>
                              </button>
                            </NavLink>
                          </li>
                          <li className="text-center">
                            <NavLink to="/edit-staff/">
                              <div className="sidebar-user  mt-3">
                                <div className="sidebar-user-picture">
                                  <img alt="image" src="assets/img/user.png" width={100} height={100} />
                                </div>
                                <div className="sidebar-user-details sidebar-user-details-card">
                                  <div className="user-name text-bold">Test User </div>
                                  <div className="user-role">Admin</div>
                                </div>
                              </div>
                              <button className="btn-primary btn-sm">
                                <i className="fa fa-envelope"></i>
                              </button>
                            </NavLink>
                          </li>
                          <li className="text-center">
                            <NavLink to="/edit-staff/">
                              <div className="sidebar-user  mt-3">
                                <div className="sidebar-user-picture">
                                  <img alt="image" src="assets/img/user.png" width={100} height={100} />
                                </div>
                                <div className="sidebar-user-details sidebar-user-details-card">
                                  <div className="user-name text-bold">Test User </div>
                                  <div className="user-role">Admin</div>
                                </div>
                              </div>
                              <button className="btn-primary btn-sm">
                                <i className="fa fa-envelope"></i>
                              </button>
                            </NavLink>
                          </li>
                          <li className="text-center">
                            <NavLink to="/edit-staff/">
                              <div className="sidebar-user  mt-3">
                                <div className="sidebar-user-picture">
                                  <img alt="image" src="assets/img/user.png" width={100} height={100} />
                                </div>
                                <div className="sidebar-user-details sidebar-user-details-card">
                                  <div className="user-name text-bold">Test User </div>
                                  <div className="user-role">Admin</div>
                                </div>
                              </div>
                              <button className="btn-primary btn-sm">
                                <i className="fa fa-envelope"></i>
                              </button>
                            </NavLink>
                          </li> */}

                        </ul>
                      </div>
                    </div>
                    <nav aria-label="Page navigation example" className="align-self-center">
                      <ul className="pagination">
                        <li className={`page-item ${this.state.prevUrl ? "" : "disabled"} primary`}><button className="page-link" onClick={() => this.handleNextPrev(false)}>Previous</button></li>
                        {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                        <li className={`page-item ${this.state.nextUrl ? "" : "disabled"}`}><button className="page-link" onClick={() => this.handleNextPrev(true)}>Next</button></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
export default StaffList