import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <header id="header" className="u-header u-header-left-aligned-nav">
        <div className="u-header__section">
          {/* Topbar */}
          <div className="u-header-topbar py-2 d-none d-xl-block">
            <div className="container">
              <div className="d-flex align-items-center">
                <div className="topbar-left">
                  <a
                    href="#"
                    className="text-gray-110 text-white font-size-13 hover-on-dark"
                  >
                    Welcome to Glow{" "}
                  </a>
                </div>
                <div className="topbar-right ml-auto">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                      <a
                        href="contact-v2.html"
                        className="u-header-topbar__nav-link"
                      >
                        <i className="ec ec-map-pointer mr-1" /> Saloon Locator
                      </a>
                    </li>
                    <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                      <a
                        href="https://transvelo.github.io/electro-html/2.0/html/shop/track-your-order.html"
                        className="u-header-topbar__nav-link"
                      >
                        <i className="ec ec-transport mr-1" /> Track Your
                        Booking
                      </a>
                    </li>

                    <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                      {/* Account Sidebar Toggle Button */}
                      <NavLink
                        to="/login"

id="sidebarNavToggler"
                        role="button"
                        className="u-header-topbar__nav-link"
                        aria-controls="sidebarContent"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-unfold-event="click"
                        data-unfold-hide-on-scroll="false"
                        data-unfold-target="#sidebarContent"
                        data-unfold-type="css-animation"
                        data-unfold-animation-in="fadeInRight"
                        data-unfold-animation-out="fadeOutRight"
                        data-unfold-duration={500}
                      >
                        <i className="ec ec-user mr-1" /> Register{" "}
                        <span className="text-gray-50">or</span> Sign in
                      </NavLink>
                      {/* End Account Sidebar Toggle Button */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* End Topbar */}
          {/* Logo-Search-header-icons */}
          <div className="bg-primary-down-lg">
            <div className="container my-0dot5 my-xl-0">
              <div className="row align-items-center">
                {/* Logo-offcanvas-menu */}
                <div className="col-md-4">
                  {/* Nav */}
                  <nav className=" u-header__navbar py-0 justify-content-xl-between max-width-270 min-width-270">
                    {/* Logo */}
                    <h2>
                      <NavLink
                        className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                        to="/"
                        aria-label="Electro"
                      >
                        Saloon LOGO
                      </NavLink>
                    </h2>
                    {/* End Logo */}
                  </nav>
                  {/* End Nav */}
                </div>
                {/* End Logo-offcanvas-menu */}
                {/* Search Bar */}
                <div className="col-md-8">
                  <div className="d-none d-xl-block container">
                    <div className="row">
                      {/* Secondary Menu */}
                      <div className="col-md-6"></div>
                      <div className="col-md-6 text-right">
                        {/* Nav */}
                        <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
                          {/* Navigation */}
                          <div
                            id="navBar"
                            className="collapse navbar-collapse u-header__navbar-collapse"
                          >
                            <ul className="navbar-nav u-header__navbar-nav">
                              {/* Home */}
                              <li className="nav-item u-header__nav-last-item">
                                <NavLink
                                  className="nav-link u-header__nav-link"
                                  to="saloon-list"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  aria-labelledby="pagesSubMenu"
                                >
                                  Home
                                </NavLink>
                              </li>
                              {/* End Home */}
                              {/* Featured Brands */}
                              <li className="nav-item u-header__nav-last-item">
                                <a
                                  className="nav-link u-header__nav-link"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  aria-labelledby="pagesSubMenu"
                                >
                                  Blog
                                </a>
                              </li>
                              {/* End Featured Brands */}
                              {/* Trending Styles */}
                              <li className="nav-item u-header__nav-last-item">
                                <a
                                  className="nav-link u-header__nav-link"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  aria-labelledby="blogSubMenu"
                                >
                                  Saloons
                                </a>
                              </li>
                              {/* End Trending Styles */}
                              {/* Gift Cards */}
                              <li className="nav-item u-header__nav-last-item">
                                <a
                                  className="nav-link u-header__nav-link"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Contact us
                                </a>
                              </li>
                              {/* End Gift Cards */}
                            </ul>
                          </div>
                          {/* End Navigation */}
                        </nav>
                        {/* End Nav */}
                      </div>
                      {/* End Secondary Menu */}
                    </div>
                  </div>
                </div>
                {/* End Search Bar */}
              </div>
            </div>
          </div>
          {/* End Logo-Search-header-icons */}
          {/* Vertical-and-secondary-menu */}

          {/* End Vertical-and-secondary-menu */}
        </div>
      </header>
    );
  }
}

export default Header;
