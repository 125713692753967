import React, { Component } from 'react'
import Footer from '../../component/Footer'
import Header from '../../component/Header'

export default class SaloonList extends Component {
    render() {
        return (
            <>
            <Header/>
            <main id="content" role="main">
  
  <div className=" mt-3 container">
    {/* Single Product Body */}
    <div className="mb-2">
      <div className="row">
        <div className="col-md-12 mb-md-6 mb-lg-0">
          <div className="mb-2">
            <div className="border-bottom mb-3 pb-md-1 pb-3">
              <h2 className="font-size-25 text-lh-1dot2">Saloon Name 
              <a href="#" className="text-gray-6 font-size-25 mr-2 ml-2"><i className="ec ec-favorites mr-1 font-size-15" /></a></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Single Product Body */}
    {/* Single Product Tab */}
    <div className="mb-8">
      <div className="position-relative position-md-static px-md-6">
        <ul className="nav nav-classic nav-tab nav-tab-lg justify-content-xl-center flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble border-0 pb-1 pb-xl-0 mb-n1 mb-xl-0" id="pills-tab-8" role="tablist">
          <li className="nav-item flex-shrink-0 flex-xl-shrink-1 z-index-2">
            <a className="nav-link active" id="Jpills-one-example1-tab" data-toggle="pill" href="#Jpills-one-example1" role="tab" aria-controls="Jpills-one-example1" aria-selected="true">Basic Info</a>
          </li>
          <li className="nav-item flex-shrink-0 flex-xl-shrink-1 z-index-2">
            <a className="nav-link" id="Jpills-two-example1-tab" data-toggle="pill" href="#Jpills-two-example1" role="tab" aria-controls="Jpills-two-example1" aria-selected="false">Services</a>
          </li>
          <li className="nav-item flex-shrink-0 flex-xl-shrink-1 z-index-2">
            <a className="nav-link" id="Jpills-three-example1-tab" data-toggle="pill" href="#Jpills-three-example1" role="tab" aria-controls="Jpills-three-example1" aria-selected="false">Pictures</a>
          </li>
          <li className="nav-item flex-shrink-0 flex-xl-shrink-1 z-index-2">
            <a className="nav-link" id="Jpills-four-example1-tab" data-toggle="pill" href="#Jpills-four-example1" role="tab" aria-controls="Jpills-four-example1" aria-selected="false">Reviews</a>
          </li>
        </ul>
      </div>
      {/* Tab Content */}
      <div className="borders-radius-17 border p-4 mt-4 mt-md-0 px-lg-10 py-lg-9">
        <div className="tab-content" id="Jpills-tabContent">
          <div className="tab-pane fade active show" id="Jpills-one-example1" role="tabpanel" aria-labelledby="Jpills-one-example1-tab">
            <div className="row">
            
            </div>
          </div>
          <div className="tab-pane fade" id="Jpills-two-example1" role="tabpanel" aria-labelledby="Jpills-two-example1-tab">
            <h3 className="font-size-24 mb-3">Perfectly Done</h3>
            <p>Praesent ornare, ex a interdum consectetur, lectus diam sodales elit, vitae egestas est enim ornare nisl. Nullam in lectus nec sem semper viverra. In lobortis egestas massa. Nam nec massa nisi. Suspendisse potenti. Quisque suscipit vulputate dui quis volutpat. Ut id elit facilisis, feugiat est in, tempus lacus. Ut ultrices dictum metus, a ultricies ex vulputate ac. Ut id cursus tellus, non tempor quam. Morbi porta diam nisi, id finibus nunc tincidunt eu.</p>
            <div className="row">
              <div className="col-md-6">
                <div className="pt-lg-8 pt-xl-10">
                  <h3 className="font-size-24 mb-3">Wireless</h3>
                  <p className="mb-6">Fusce vitae nibh mi. Integer posuere, libero et ullamcorper facilisis, enim eros tincidunt orci, eget vestibulum sapien nisi ut leo. Cras finibus vel est ut mollis. Donec luctus condimentum ante et euismod.</p>
                  <h3 className="font-size-24 mb-3">Fresh Design</h3>
                  <p className="mb-6">Integer bibendum aliquet ipsum, in ultrices enim sodales sed. Quisque ut urna vitae lacus laoreet malesuada eu at massa. Pellentesque nibh augue, pellentesque nec dictum vel, pretium a arcu. Duis eu urna suscipit, lobortis elit quis, ullamcorper massa.</p>
                  <h3 className="font-size-24 mb-3">Fabolous Sound</h3>
                  <p className="mb-6">Cras rutrum, nibh a sodales accumsan, elit sapien ultrices sapien, eget semper lectus ex congue elit. Nullam dui elit, fermentum a varius at, iaculis non dolor. In hac habitasse platea dictumst.</p>
                </div>
              </div>
              <div className="col-md-6 text-right">
                <img className="img-fluid mr-n4 mr-lg-n10" src="client/assets/img/580X580/img1.jpg" alt="Image Description" />
              </div>
              <div className="col-md-6 text-left">
                <img className="img-fluid ml-n4 ml-lg-n10" src="client/assets/img/580X580/img2.jpg" alt="Image Description" />
              </div>
              <div className="col-md-6 align-self-center">
                <div className="pt-lg-8 pt-xl-10 text-right">
                  <h3 className="font-size-24 mb-3">Inteligent Bass</h3>
                  <p className="mb-6">Fusce vitae nibh mi. Integer posuere, libero et ullamcorper facilisis, enim eros tincidunt orci, eget vestibulum sapien nisi ut leo. Cras finibus vel est ut mollis. Donec luctus condimentum ante et euismod.</p>
                  <h3 className="font-size-24 mb-3">Battery Life</h3>
                  <p className="mb-6">Integer bibendum aliquet ipsum, in ultrices enim sodales sed. Quisque ut urna vitae lacus laoreet malesuada eu at massa. Pellentesque nibh augue, pellentesque nec dictum vel, pretium a arcu. Duis eu urna suscipit, lobortis elit quis, ullamcorper massa.</p>
                </div>
              </div>
            </div>
            <ul className="nav flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble">
              <li className="nav-item text-gray-111 flex-shrink-0 flex-xl-shrink-1"><strong>SKU:</strong> <span className="sku">FW511948218</span></li>
              <li className="nav-item text-gray-111 mx-3 flex-shrink-0 flex-xl-shrink-1">/</li>
              <li className="nav-item text-gray-111 flex-shrink-0 flex-xl-shrink-1"><strong>Category:</strong> <a href="#" className="text-blue">Headphones</a></li>
              <li className="nav-item text-gray-111 mx-3 flex-shrink-0 flex-xl-shrink-1">/</li>
              <li className="nav-item text-gray-111 flex-shrink-0 flex-xl-shrink-1"><strong>Tags:</strong> <a href="#" className="text-blue">Fast</a>, <a href="#" className="text-blue">Gaming</a>, <a href="#" className="text-blue">Strong</a></li>
            </ul>
          </div>
          <div className="tab-pane fade" id="Jpills-three-example1" role="tabpanel" aria-labelledby="Jpills-three-example1-tab">
            <div className="mx-md-5 pt-1">
              <div className="table-responsive mb-4">
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th className="px-4 px-xl-5 border-top-0">Weight</th>
                      <td className="border-top-0">7.25kg</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Dimensions</th>
                      <td>90 x 60 x 90 cm</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Size</th>
                      <td>One Size Fits all</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">color</th>
                      <td>Black with Red, White with Gold</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Guarantee</th>
                      <td>5 years</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h3 className="font-size-18 mb-4">Technical Specifications</h3>
              <div className="table-responsive">
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th className="px-4 px-xl-5 border-top-0">Brand</th>
                      <td className="border-top-0">Apple</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Item Height</th>
                      <td>18 Millimeters</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Item Width</th>
                      <td>31.4 Centimeters</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Screen Size</th>
                      <td>13 Inches</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Item Weight</th>
                      <td>1.6 Kg</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Product Dimensions</th>
                      <td>21.9 x 31.4 x 1.8 cm</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Item model number</th>
                      <td>MF841HN/A</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Processor Brand</th>
                      <td>Intel</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Processor Type</th>
                      <td>Core i5</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Processor Speed</th>
                      <td>2.9 GHz</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">RAM Size</th>
                      <td>8 GB</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Hard Drive Size</th>
                      <td>512 GB</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Hard Disk Technology</th>
                      <td>Solid State Drive</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Graphics Coprocessor</th>
                      <td>Intel Integrated Graphics</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Graphics Card Description</th>
                      <td>Integrated Graphics Card</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Hardware Platform</th>
                      <td>Mac</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Operating System</th>
                      <td>Mac OS</td>
                    </tr>
                    <tr>
                      <th className="px-4 px-xl-5">Average Battery Life (in hours)</th>
                      <td>9</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="Jpills-four-example1" role="tabpanel" aria-labelledby="Jpills-four-example1-tab">
            <div className="row mb-8">
              <div className="col-md-6">
                <div className="mb-3">
                  <h3 className="font-size-18 mb-6">Based on 3 reviews</h3>
                  <h2 className="font-size-30 font-weight-bold text-lh-1 mb-0">4.3</h2>
                  <div className="text-lh-1">overall</div>
                </div>
                {/* Ratings */}
                <ul className="list-unstyled">
                  <li className="py-1">
                    <a className="row align-items-center mx-gutters-2 font-size-1" href="javascript:;">
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="text-warning text-ls-n2 font-size-16" style={{width: '80px'}}>
                          <small className="fas fa-star" />
                          <small className="fas fa-star" />
                          <small className="fas fa-star" />
                          <small className="fas fa-star" />
                          <small className="far fa-star text-muted" />
                        </div>
                      </div>
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="progress ml-xl-5" style={{height: '10px', width: '200px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '100%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="col-auto text-right">
                        <span className="text-gray-90">205</span>
                      </div>
                    </a>
                  </li>
                  <li className="py-1">
                    <a className="row align-items-center mx-gutters-2 font-size-1" href="javascript:;">
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="text-warning text-ls-n2 font-size-16" style={{width: '80px'}}>
                          <small className="fas fa-star" />
                          <small className="fas fa-star" />
                          <small className="fas fa-star" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                        </div>
                      </div>
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="progress ml-xl-5" style={{height: '10px', width: '200px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '53%'}} aria-valuenow={53} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="col-auto text-right">
                        <span className="text-gray-90">55</span>
                      </div>
                    </a>
                  </li>
                  <li className="py-1">
                    <a className="row align-items-center mx-gutters-2 font-size-1" href="javascript:;">
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="text-warning text-ls-n2 font-size-16" style={{width: '80px'}}>
                          <small className="fas fa-star" />
                          <small className="fas fa-star" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                        </div>
                      </div>
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="progress ml-xl-5" style={{height: '10px', width: '200px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '20%'}} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="col-auto text-right">
                        <span className="text-gray-90">23</span>
                      </div>
                    </a>
                  </li>
                  <li className="py-1">
                    <a className="row align-items-center mx-gutters-2 font-size-1" href="javascript:;">
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="text-warning text-ls-n2 font-size-16" style={{width: '80px'}}>
                          <small className="fas fa-star" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                        </div>
                      </div>
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="progress ml-xl-5" style={{height: '10px', width: '200px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '0%'}} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="col-auto text-right">
                        <span className="text-muted">0</span>
                      </div>
                    </a>
                  </li>
                  <li className="py-1">
                    <a className="row align-items-center mx-gutters-2 font-size-1" href="javascript:;">
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="text-warning text-ls-n2 font-size-16" style={{width: '80px'}}>
                          <small className="fas fa-star" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                        </div>
                      </div>
                      <div className="col-auto mb-2 mb-md-0">
                        <div className="progress ml-xl-5" style={{height: '10px', width: '200px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '1%'}} aria-valuenow={1} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="col-auto text-right">
                        <span className="text-gray-90">4</span>
                      </div>
                    </a>
                  </li>
                </ul>
                {/* End Ratings */}
              </div>
              <div className="col-md-6">
                <h3 className="font-size-18 mb-5">Add a review</h3>
                {/* Form */}
                <form className="js-validate">
                  <div className="row align-items-center mb-4">
                    <div className="col-md-4 col-lg-3">
                      <label htmlFor="rating" className="form-label mb-0">Your Review</label>
                    </div>
                    <div className="col-md-8 col-lg-9">
                      <a href="#" className="d-block">
                        <div className="text-warning text-ls-n2 font-size-16">
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                          <small className="far fa-star text-muted" />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="js-form-message form-group mb-3 row">
                    <div className="col-md-4 col-lg-3">
                      <label htmlFor="descriptionTextarea" className="form-label">Your Review</label>
                    </div>
                    <div className="col-md-8 col-lg-9">
                      <textarea className="form-control" rows={3} id="descriptionTextarea" data-msg="Please enter your message." data-error-class="u-has-error" data-success-class="u-has-success" defaultValue={""} />
                    </div>
                  </div>
                  <div className="js-form-message form-group mb-3 row">
                    <div className="col-md-4 col-lg-3">
                      <label htmlFor="inputName" className="form-label">Name <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-md-8 col-lg-9">
                      <input type="text" className="form-control" name="name" id="inputName" aria-label="Alex Hecker" required data-msg="Please enter your name." data-error-class="u-has-error" data-success-class="u-has-success" />
                    </div>
                  </div>
                  <div className="js-form-message form-group mb-3 row">
                    <div className="col-md-4 col-lg-3">
                      <label htmlFor="emailAddress" className="form-label">Email <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-md-8 col-lg-9">
                      <input type="email" className="form-control" name="emailAddress" id="emailAddress" aria-label="alexhecker@pixeel.com" required data-msg="Please enter a valid email address." data-error-class="u-has-error" data-success-class="u-has-success" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-md-4 offset-lg-3 col-auto">
                      <button type="submit" className="btn btn-primary-dark btn-wide transition-3d-hover">Add Review</button>
                    </div>
                  </div>
                </form>
                {/* End Form */}
              </div>
            </div>
            {/* Review */}
            <div className="border-bottom border-color-1 pb-4 mb-4">
              {/* Review Rating */}
              <div className="d-flex justify-content-between align-items-center text-secondary font-size-1 mb-2">
                <div className="text-warning text-ls-n2 font-size-16" style={{width: '80px'}}>
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="far fa-star text-muted" />
                  <small className="far fa-star text-muted" />
                </div>
              </div>
              {/* End Review Rating */}
              <p className="text-gray-90">Fusce vitae nibh mi. Integer posuere, libero et ullamcorper facilisis, enim eros tincidunt orci, eget vestibulum sapien nisi ut leo. Cras finibus vel est ut mollis. Donec luctus condimentum ante et euismod.</p>
              {/* Reviewer */}
              <div className="mb-2">
                <strong>John Doe</strong>
                <span className="font-size-13 text-gray-23">- April 3, 2019</span>
              </div>
              {/* End Reviewer */}
            </div>
            {/* End Review */}
            {/* Review */}
            <div className="border-bottom border-color-1 pb-4 mb-4">
              {/* Review Rating */}
              <div className="d-flex justify-content-between align-items-center text-secondary font-size-1 mb-2">
                <div className="text-warning text-ls-n2 font-size-16" style={{width: '80px'}}>
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                </div>
              </div>
              {/* End Review Rating */}
              <p className="text-gray-90">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Suspendisse eget facilisis odio. Duis sodales augue eu tincidunt faucibus. Etiam justo ligula, placerat ac augue id, volutpat porta dui.</p>
              {/* Reviewer */}
              <div className="mb-2">
                <strong>Anna Kowalsky</strong>
                <span className="font-size-13 text-gray-23">- April 3, 2019</span>
              </div>
              {/* End Reviewer */}
            </div>
            {/* End Review */}
            {/* Review */}
            <div className="pb-4">
              {/* Review Rating */}
              <div className="d-flex justify-content-between align-items-center text-secondary font-size-1 mb-2">
                <div className="text-warning text-ls-n2 font-size-16" style={{width: '80px'}}>
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="far fa-star text-muted" />
                </div>
              </div>
              {/* End Review Rating */}
              <p className="text-gray-90">Sed id tincidunt sapien. Pellentesque cursus accumsan tellus, nec ultricies nulla sollicitudin eget. Donec feugiat orci vestibulum porttitor sagittis.</p>
              {/* Reviewer */}
              <div className="mb-2">
                <strong>Peter Wargner</strong>
                <span className="font-size-13 text-gray-23">- April 3, 2019</span>
              </div>
              {/* End Reviewer */}
            </div>
            {/* End Review */}
          </div>
        </div>
      </div>
      {/* End Tab Content */}
    </div>
    {/* End Single Product Tab */}
    {/* Related products */}
    <div className="mb-6">
      <div className="d-flex justify-content-between align-items-center border-bottom border-color-1 flex-lg-nowrap flex-wrap mb-4">
        <h3 className="section-title mb-0 pb-2 font-size-22">Related Saloon</h3>
      </div>
      <ul className="row list-unstyled products-group no-gutters">
        <li className="col-6 col-md-3 col-xl-2gdot4-only col-wd-2 product-item">
          <div className="product-item__outer h-100">
            <div className="product-item__inner px-xl-4 p-3">
              <div className="product-item__body pb-xl-2">
                
                <h5 className="mb-1 product-item__title"><a href="../shop/single-product-fullwidth.html" className="text-blue font-weight-bold">Wireless Audio System Multiroom 360 degree Full base audio</a></h5>
                <div className="mb-2">
                  <a href="../shop/single-product-fullwidth.html" className="d-block text-center"><img className="img-fluid" src="client/assets/img/212X200/img1.jpg" alt="Image Description" /></a>
                </div>
                <div className="flex-center-between mb-1">
                  <div className="prodcut-price">
                    <div className="text-gray-100">$685,00</div>
                  </div>
                  <div className="d-none d-xl-block prodcut-add-cart">
                    <a href="../shop/single-product-fullwidth.html" className="btn-add-cart btn-primary transition-3d-hover"><i className="ec ec-add-to-cart" /></a>
                  </div>
                </div>
              </div>
              <div className="product-item__footer">
                <div className="border-top pt-2 flex-center-between flex-wrap">
                  <a href="../shop/compare.html" className="text-gray-6 font-size-13"><i className="ec ec-compare mr-1 font-size-15" /> Compare</a>
                  <a href="../shop/wishlist.html" className="text-gray-6 font-size-13"><i className="ec ec-favorites mr-1 font-size-15" /> Wishlist</a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="col-6 col-md-3 col-xl-2gdot4-only col-wd-2 product-item">
          <div className="product-item__outer h-100">
            <div className="product-item__inner px-xl-4 p-3">
              <div className="product-item__body pb-xl-2">
                <div className="mb-2"><a href="../shop/product-categories-7-column-full-width.html" className="font-size-12 text-gray-5">Speakers</a></div>
                <h5 className="mb-1 product-item__title"><a href="../shop/single-product-fullwidth.html" className="text-blue font-weight-bold">Tablet White EliteBook Revolve 810 G2</a></h5>
                <div className="mb-2">
                  <a href="../shop/single-product-fullwidth.html" className="d-block text-center"><img className="img-fluid" src="client/assets/img/212X200/img2.jpg" alt="Image Description" /></a>
                </div>
                <div className="flex-center-between mb-1">
                  <div className="prodcut-price d-flex align-items-center position-relative">
                    <ins className="font-size-20 text-red text-decoration-none">$1999,00</ins>
                    <del className="font-size-12 tex-gray-6 position-absolute bottom-100">$2 299,00</del>
                  </div>
                  <div className="d-none d-xl-block prodcut-add-cart">
                    <a href="../shop/single-product-fullwidth.html" className="btn-add-cart btn-primary transition-3d-hover"><i className="ec ec-add-to-cart" /></a>
                  </div>
                </div>
              </div>
              <div className="product-item__footer">
                <div className="border-top pt-2 flex-center-between flex-wrap">
                  <a href="../shop/compare.html" className="text-gray-6 font-size-13"><i className="ec ec-compare mr-1 font-size-15" /> Compare</a>
                  <a href="../shop/wishlist.html" className="text-gray-6 font-size-13"><i className="ec ec-favorites mr-1 font-size-15" /> Wishlist</a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="col-6 col-md-3 col-xl-2gdot4-only col-wd-2 product-item">
          <div className="product-item__outer h-100">
            <div className="product-item__inner px-xl-4 p-3">
              <div className="product-item__body pb-xl-2">
                <div className="mb-2"><a href="../shop/product-categories-7-column-full-width.html" className="font-size-12 text-gray-5">Speakers</a></div>
                <h5 className="mb-1 product-item__title"><a href="../shop/single-product-fullwidth.html" className="text-blue font-weight-bold">Purple Solo 2 Wireless</a></h5>
                <div className="mb-2">
                  <a href="../shop/single-product-fullwidth.html" className="d-block text-center"><img className="img-fluid" src="client/assets/img/212X200/img3.jpg" alt="Image Description" /></a>
                </div>
                <div className="flex-center-between mb-1">
                  <div className="prodcut-price">
                    <div className="text-gray-100">$685,00</div>
                  </div>
                  <div className="d-none d-xl-block prodcut-add-cart">
                    <a href="../shop/single-product-fullwidth.html" className="btn-add-cart btn-primary transition-3d-hover"><i className="ec ec-add-to-cart" /></a>
                  </div>
                </div>
              </div>
              <div className="product-item__footer">
                <div className="border-top pt-2 flex-center-between flex-wrap">
                  <a href="../shop/compare.html" className="text-gray-6 font-size-13"><i className="ec ec-compare mr-1 font-size-15" /> Compare</a>
                  <a href="../shop/wishlist.html" className="text-gray-6 font-size-13"><i className="ec ec-favorites mr-1 font-size-15" /> Wishlist</a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="col-6 col-md-3 col-xl-2gdot4-only col-wd-2 product-item remove-divider-md-lg">
          <div className="product-item__outer h-100">
            <div className="product-item__inner px-xl-4 p-3">
              <div className="product-item__body pb-xl-2">
                <div className="mb-2"><a href="../shop/product-categories-7-column-full-width.html" className="font-size-12 text-gray-5">Speakers</a></div>
                <h5 className="mb-1 product-item__title"><a href="../shop/single-product-fullwidth.html" className="text-blue font-weight-bold">Smartphone 6S 32GB LTE</a></h5>
                <div className="mb-2">
                  <a href="../shop/single-product-fullwidth.html" className="d-block text-center"><img className="img-fluid" src="client/assets/img/212X200/img4.jpg" alt="Image Description" /></a>
                </div>
                <div className="flex-center-between mb-1">
                  <div className="prodcut-price">
                    <div className="text-gray-100">$685,00</div>
                  </div>
                  <div className="d-none d-xl-block prodcut-add-cart">
                    <a href="../shop/single-product-fullwidth.html" className="btn-add-cart btn-primary transition-3d-hover"><i className="ec ec-add-to-cart" /></a>
                  </div>
                </div>
              </div>
              <div className="product-item__footer">
                <div className="border-top pt-2 flex-center-between flex-wrap">
                  <a href="../shop/compare.html" className="text-gray-6 font-size-13"><i className="ec ec-compare mr-1 font-size-15" /> Compare</a>
                  <a href="../shop/wishlist.html" className="text-gray-6 font-size-13"><i className="ec ec-favorites mr-1 font-size-15" /> Wishlist</a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="col-6 col-md-3 col-xl-2gdot4-only col-wd-2 product-item remove-divider-xl">
          <div className="product-item__outer h-100">
            <div className="product-item__inner px-xl-4 p-3">
              <div className="product-item__body pb-xl-2">
                <div className="mb-2"><a href="../shop/product-categories-7-column-full-width.html" className="font-size-12 text-gray-5">Speakers</a></div>
                <h5 className="mb-1 product-item__title"><a href="../shop/single-product-fullwidth.html" className="text-blue font-weight-bold">Widescreen NX Mini F1 SMART NX</a></h5>
                <div className="mb-2">
                  <a href="../shop/single-product-fullwidth.html" className="d-block text-center"><img className="img-fluid" src="client/assets/img/212X200/img5.jpg" alt="Image Description" /></a>
                </div>
                <div className="flex-center-between mb-1">
                  <div className="prodcut-price">
                    <div className="text-gray-100">$685,00</div>
                  </div>
                  <div className="d-none d-xl-block prodcut-add-cart">
                    <a href="../shop/single-product-fullwidth.html" className="btn-add-cart btn-primary transition-3d-hover"><i className="ec ec-add-to-cart" /></a>
                  </div>
                </div>
              </div>
              <div className="product-item__footer">
                <div className="border-top pt-2 flex-center-between flex-wrap">
                  <a href="../shop/compare.html" className="text-gray-6 font-size-13"><i className="ec ec-compare mr-1 font-size-15" /> Compare</a>
                  <a href="../shop/wishlist.html" className="text-gray-6 font-size-13"><i className="ec ec-favorites mr-1 font-size-15" /> Wishlist</a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="col-6 col-md-3 col-xl-2gdot4-only col-wd-2 product-item remove-divider-wd d-xl-none d-wd-block">
          <div className="product-item__outer h-100">
            <div className="product-item__inner px-xl-4 p-3">
              <div className="product-item__body pb-xl-2">
                <div className="mb-2"><a href="../shop/product-categories-7-column-full-width.html" className="font-size-12 text-gray-5">Speakers</a></div>
                <h5 className="mb-1 product-item__title"><a href="../shop/single-product-fullwidth.html" className="text-blue font-weight-bold">Tablet White EliteBook Revolve 810 G2</a></h5>
                <div className="mb-2">
                  <a href="../shop/single-product-fullwidth.html" className="d-block text-center"><img className="img-fluid" src="client/assets/img/212X200/img2.jpg" alt="Image Description" /></a>
                </div>
                <div className="flex-center-between mb-1">
                  <div className="prodcut-price d-flex align-items-center position-relative">
                    <ins className="font-size-20 text-red text-decoration-none">$1999,00</ins>
                    <del className="font-size-12 tex-gray-6 position-absolute bottom-100">$2 299,00</del>
                  </div>
                  <div className="d-none d-xl-block prodcut-add-cart">
                    <a href="../shop/single-product-fullwidth.html" className="btn-add-cart btn-primary transition-3d-hover"><i className="ec ec-add-to-cart" /></a>
                  </div>
                </div>
              </div>
              <div className="product-item__footer">
                <div className="border-top pt-2 flex-center-between flex-wrap">
                  <a href="../shop/compare.html" className="text-gray-6 font-size-13"><i className="ec ec-compare mr-1 font-size-15" /> Compare</a>
                  <a href="../shop/wishlist.html" className="text-gray-6 font-size-13"><i className="ec ec-favorites mr-1 font-size-15" /> Wishlist</a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    {/* End Related products */}
    {/* Brand Carousel */}
    <div className="mb-8">
      <div className="py-2 border-top border-bottom">
        <div className="js-slick-carousel u-slick my-1" data-slides-show={5} data-slides-scroll={1} data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-normal u-slick__arrow-centered--y" data-arrow-left-classes="fa fa-angle-left u-slick__arrow-classic-inner--left z-index-9" data-arrow-right-classes="fa fa-angle-right u-slick__arrow-classic-inner--right" data-responsive="[{
                          &quot;breakpoint&quot;: 992,
                          &quot;settings&quot;: {
                              &quot;slidesToShow&quot;: 2
                          }
                      }, {
                          &quot;breakpoint&quot;: 768,
                          &quot;settings&quot;: {
                              &quot;slidesToShow&quot;: 1
                          }
                      }, {
                          &quot;breakpoint&quot;: 554,
                          &quot;settings&quot;: {
                              &quot;slidesToShow&quot;: 1
                          }
                      }]">
          <div className="js-slide">
            <a href="#" className="link-hover__brand">
              <img className="img-fluid m-auto max-height-50" src="client/assets/img/200X60/img1.png" alt="Image Description" />
            </a>
          </div>
          <div className="js-slide">
            <a href="#" className="link-hover__brand">
              <img className="img-fluid m-auto max-height-50" src="client/assets/img/200X60/img2.png" alt="Image Description" />
            </a>
          </div>
          <div className="js-slide">
            <a href="#" className="link-hover__brand">
              <img className="img-fluid m-auto max-height-50" src="client/assets/img/200X60/img3.png" alt="Image Description" />
            </a>
          </div>
          <div className="js-slide">
            <a href="#" className="link-hover__brand">
              <img className="img-fluid m-auto max-height-50" src="client/assets/img/200X60/img4.png" alt="Image Description" />
            </a>
          </div>
          <div className="js-slide">
            <a href="#" className="link-hover__brand">
              <img className="img-fluid m-auto max-height-50" src="client/assets/img/200X60/img5.png" alt="Image Description" />
            </a>
          </div>
          <div className="js-slide">
            <a href="#" className="link-hover__brand">
              <img className="img-fluid m-auto max-height-50" src="client/assets/img/200X60/img6.png" alt="Image Description" />
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* End Brand Carousel */}
  </div>
</main>

            <Footer/>
            </>
        )
    }
}
