import React, { Component, forwardRef } from "react";
import $ from 'jquery';
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import Header from '../../../component/Header';
import Sidebar from '../../../component/Sidebar';
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Footer from '../../../component/Footer';
import { NavLink } from 'react-router-dom';
class CloseDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      actions: [
        {
          icon: "edit",
          color: "green",
          tooltip: "Edit this Close Date",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "green",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) =>
            this.props.history.push("/edit-close-date/" + rowData.id),
        },
        {
          icon: "delete",
          tooltip: "Delete User",
          iconProps: {
            style: {
              height: "2rem",
              width: "2rem",
              "padding-top": "3px",
              color: "#fff",
              background: "red",
              "border-radius": "5px",
              border: "none",
            },
          },
          onClick: (event, rowData) => {
            swal({
              title: "Are you sure?",
              text: "Are you sure that you want to delete this Close Date?",
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                //   Delete APi
                let saloonId = localStorage.getItem("user_id")
                fetch(
                  API_URL + "/api/close/date/delete/" + rowData.id + "?saloon_id="+saloonId,
                  {
                    method: "GET",
                    headers:{
                      mode: "no-cors",
                      Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
                      
                    }
                  }
                ).then((result) => {
                  // console.clear();
                  console.log("Delete", result.status)
                  if (result.status == 0) {
                    swal(
                      "Error!",
                      "Something went wrong .. Please try again!",
                      "error"
                    );
                  }
                  else {
                    swal(
                      "Sucess!",
                      "Close Date Deleted Successfully !",
                      "success"
                    );
                    this.getData();
                  }
                  // 
                });
              }
            });
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.getData();
  }
  // Fetch Services Function
  getData() {
    let saloonId = localStorage.getItem("user_id")
    fetch(API_URL + "/api/close/date/list?saloon_id=" + saloonId, {
      method: 'get',
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      },
    }).then((data) => {

      data.json().then((result) => {
       
        if (result.status == true) {
          this.setState({ data: result.data })
        }
        // console.log("Status is " ,result.service[0].category["name"])
      });
    });
  }
  render() {
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
    };

    //   const columns = [
    //     { title: "S.no", field: "id" },
    //     { title: "Service Name", field: "name" },
    //     { title: "Category", field: "category.name" },
    //     { title: "Duration", field: "duration" },
    //     { title: "Description", field: "description" },
    //     { title: "Price", field: "price" },
    //     { title: "Status", field: "status" },
    //   ];

    const columns = [
      { title: "Start Date", field: "start_date" },
      { title: "End Date", field: "end_date" },
      { title: "No. Of Days", field: "interval" },
      {
        title: "Description", field: "description", cellStyle: {
         width: 1990
        }
      }
    ]
    const { data } = this.state



    const { actions } = this.state


    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
        <div className="section container mb-5">
            <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-pills" id="myTab3" role="tablist">
                    <li className="nav-item">
                      <NavLink to="/time-slot" className="nav-link">Staff Working Hours</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/close-date" className="nav-link">Open Date</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/staff-list" className="nav-link">Staff Member</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/user-permission" className="nav-link">User Permission</NavLink>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
          <section className="section">
            {/* <p noof></p> */}

            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Open Date Schedule</h4>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-12 text-right">
                          <NavLink to="/add-close-date" className="btn btn-primary">New Open Date</NavLink>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <MaterialTable
                          title=""
                          data={data ? data : []}
                          columns={columns}
                          actions={actions}
                          icons={tableIcons}
                          options={{
                            actionsColumnIndex: -1,
                            sorting: true,
                            // columnResizable: {
                            //   lineHeight: "1.5em",
                            //   height: "3em",
                            //   overflow: "hidden"
                            // }
                          }}
                          onRowClick={(event, rowData) => {
                            this.props.history.push(
                              "/edit-close-date/" + rowData.id
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
export default CloseDate;