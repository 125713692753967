import React, { Component } from "react";
import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
class AddCloseDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      cat_id: 0,
      category: "",
      duration: 0,
      price: 0.0,
      description: "",
      status: "",
      option: "",
      startDate: "",
      endDate: "",
      description: "",
      selectedDates: [],
      opening_time: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      closing_time: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
    };
    this.values = [new Date('1/1/2020'), new Date('1/15/2020'), new Date('1/3/2020'), new Date('1/25/2020')];
  }
  componentDidMount() {
    //Fetch Category
    const { opening_time, closing_time, selectedDates } = this.state
    fetch(API_URL + "/api/categories", {
      method: "get",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((response) => {
      response.json().then((result1) => {
        console.clear();
        let arr = result1.categories.map((val, i) => {
          return (
            <option key={i} value={val.id}>
              {val.name}
            </option>
          );
        });

        this.setState({ option: arr });
      });
    });

    $(".needs-validation").submit((event) => {
      var form = $(event.target);
      // console.clear();
      let saloonId = localStorage.getItem("user_id")
      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        event.preventDefault();
        console.clear();
        console.log(this.state);
        const data = [
          {
            day: "Mon",
            opening_time: opening_time[0],
            closing_time: closing_time[0]
          },
          {
            day: "Tue",
            opening_time: opening_time[1],
            closing_time: closing_time[1]
          },
          {
            day: "Wed",
            opening_time: opening_time[2],
            closing_time: closing_time[2]
          },
          {
            day: "Thu",
            opening_time: opening_time[3],
            closing_time: closing_time[3]
          },
          {
            day: "Fri",
            opening_time: opening_time[4],
            closing_time: closing_time[4]
          },
          {
            day: "Sat",
            opening_time: opening_time[5],
            closing_time: closing_time[5]
          },
          {
            day: "Sun",
            opening_time: opening_time[6],
            closing_time: closing_time[6]
          },
        ]
        // axios
        //   .post(API_URL + "/api/close/date", {
        //     end_date: this.state.endDate,
        //     saloon_id: saloonId,
        //     start_date: this.state.startDate,
        //     description: this.state.description
        //   }, {
        //     mode: "no-cors",
        //   })
        //   .then((response) => {
        //     let status = JSON.stringify(response.status);
        //     console.clear();
        //     console.log(response);
        //     if (response.data.status == true) {
        //       swal({
        //         title: "Done!",
        //         text: response.data.message + "",
        //         icon: "success",
        //         timer: 2000,
        //         button: false,
        //       });
        //       this.props.history.push("/close-date");
        //     }
        //   })
        //   .catch(function (error) {
        //     swal({
        //       title: "Opps!",
        //       text: error + "",
        //       icon: "error",
        //       timer: 2000,
        //       button: false,
        //     });
        //   });
        axios
          .post(API_URL + "/api/store_saloon_timing", {
            dates: this.state.selectedDates.sort((a, b) => a - b),
            days: data,
            saloon_id: saloonId,
          }, {
            headers:{
              mode: "no-cors",
              Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
              
            }
          })
          .then((response) => {
            let status = JSON.stringify(response.status);
            // console.clear();
            console.log(response);
            if (response.data.status == true) {
              swal({
                title: "Done!",
                text: response.data.message + "",
                icon: "success",
                timer: 2000,
                button: false,
              });
              this.props.history.push("/close-date");
            }
          })
          .catch(function (error) {
            swal({
              title: "Opps!",
              text: error + "",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });
      }
      form.addClass("was-validated");
    });
  }

  handleInputChange = (key, value) => {
    this.setState({ [key]: value })
  }

  render() {
    const { selectedDates, opening_time, closing_time } = this.state
    // const { opening_time, closing_time } = this.state
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Add New Open Date</h4>
                    </div>
                    <div className="card-body">
                      <div className="container">
                        <div className="row mt-2 mb-2">
                          <div className="col-md-12">
                            <div style={{ background: "#03a9f440 !important" }} className="bg-primary text-white">
                              <p className="text-primary p-2">Online bookings can not be placed during closed dates</p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-9 m-auto">
                            <form
                              method="POST"
                              action="#"
                              className="needs-validation"
                              noValidate
                            >
                              <div className="row mt-4">
                                <div className="col-md-8 mr-3">
                                  {/* <div className="text-center"> */}
                                      <CalendarComponent
                                        id="calendar"
                                        isMultiSelection={true}
                                        values={selectedDates}
                                        onChange={e => {
                                          console.log(e.target.values);
                                          this.setState({ selectedDates: e.target.values })
                                        }}
                                      />
                                  {/* </div> */}
                                </div>
                              </div>
                              <div className="row mt-5">
                                <div className="form-group col-2 m-auto">
                                  <label htmlFor="frist_name">
                                    Monday
                                  </label>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    name="opening_time[]"
                                    onChange={(event) => {
                                      opening_time[0] = event.target.value
                                      this.setState({
                                        opening_time: opening_time
                                      })
                                    }}
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      closing_time[0] = event.target.value
                                      this.setState({
                                        closing_time: closing_time
                                      })
                                    }}
                                    name="closing_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-2 m-auto">
                                  <label htmlFor="frist_name">
                                    Tuesday
                                  </label>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      opening_time[1] = event.target.value
                                      this.setState({
                                        opening_time: opening_time
                                      })
                                    }}
                                    name="opening_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      closing_time[1] = event.target.value
                                      this.setState({
                                        closing_time: closing_time
                                      })
                                    }}
                                    name="closing_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-2 m-auto">
                                  <label htmlFor="frist_name">
                                    Wednesday
                                  </label>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      opening_time[2] = event.target.value
                                      this.setState({
                                        opening_time: opening_time
                                      })
                                    }}
                                    name="opening_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      closing_time[2] = event.target.value
                                      this.setState({
                                        closing_time: closing_time
                                      })
                                    }}
                                    name="closing_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-2 m-auto">
                                  <label htmlFor="frist_name">
                                    Thursday
                                  </label>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      opening_time[3] = event.target.value
                                      this.setState({
                                        opening_time: opening_time
                                      })
                                    }}
                                    name="opening_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      closing_time[3] = event.target.value
                                      this.setState({
                                        closing_time: closing_time
                                      })
                                    }}
                                    name="closing_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-2 m-auto">
                                  <label htmlFor="frist_name">
                                    Friday
                                  </label>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      opening_time[4] = event.target.value
                                      this.setState({
                                        opening_time: opening_time
                                      })
                                    }}
                                    name="opening_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      closing_time[4] = event.target.value
                                      this.setState({
                                        closing_time: closing_time
                                      })
                                    }}
                                    name="closing_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-2 m-auto">
                                  <label htmlFor="frist_name">
                                    Saturday
                                  </label>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      opening_time[5] = event.target.value
                                      this.setState({
                                        opening_time: opening_time
                                      })
                                    }}
                                    name="opening_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      closing_time[5] = event.target.value
                                      this.setState({
                                        closing_time: closing_time
                                      })
                                    }}
                                    name="closing_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-2 m-auto">
                                  <label htmlFor="frist_name">
                                    Sunday
                                  </label>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      opening_time[6] = event.target.value
                                      this.setState({
                                        opening_time: opening_time
                                      })
                                    }}
                                    name="opening_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                                <div className="form-group col-4">
                                  <input type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      closing_time[6] = event.target.value
                                      this.setState({
                                        closing_time: closing_time
                                      })
                                    }}
                                    name="closing_time[]"
                                  />
                                  <div className="invalid-feedback">
                                    Please select valid hour
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Start Date
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange("startDate", e.target.value)}
                                    
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your start date
                                  </div>
                                </div>
                              </div>



                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    End Date
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange("endDate", e.target.value)}
                                    
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your end date
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Description
                                  </label>
                                  <textarea
                                    id="frist_name"
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange("description", e.target.value)}
                                    
                                  ></textarea>
                                  <div className="invalid-feedback">
                                    Please fill in your description
                                  </div>
                                </div>
                              </div> */}

                              <div className="row mt-4">
                                <div className="col-md-6 m-auto ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default AddCloseDate;
