import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import swal from "sweetalert";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import {API_URL} from '../BaseURL';
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }
  componentDidMount() {
    // Bootstrap 4 Validation
    $(".needs-validation").submit((event) => {
      var form = $(event.target);
      console.clear();
      console.log();
      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        axios
          .post(API_URL+"/api/login", this.state, {
            mode: "no-cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("res", response);
            let status = JSON.stringify(response.status);
            console.log(response.data.status);
            if (response.data.status == 200) {
              localStorage.setItem("user_id", JSON.stringify(response.data.user.id));
              localStorage.setItem("user_name", JSON.stringify(response.data.user.firstName + " " + response.data.user.lastName));
              localStorage.setItem(
                "authToken",
                JSON.stringify(response.data.token)
              );
              localStorage.setItem('role_id', JSON.stringify(response.data.user.role_id));
              localStorage.getItem("authToken");

              swal({
                title: "Done!",
                text: "Login Succesfull",
                icon: "success",
                timer: 2000,
                button: false,
              });
              if(response.data.user.role_id == 1){
                this.props.history.push('/saloon')
              }else{
                this.props.history.push("/calendar");
              }
            } else {
              swal({
                title: response.data.message,
                icon: "error",
              });
            }
          })

          .catch(function ({message}) {
            console.log(message)
            swal({
              title: "Opps!",
              text: "something went wrong",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });
        // console.log(this.state);
      }
      form.addClass("was-validated");
    });
  }
  
  render() {
    const responseFacebook = (response) => {
      console.log(response)
      this.setState({
        email:response.email,
        password:response.id
      })
      console.log(this.state)
      axios
      .post(API_URL+"/api/login", this.state, {
        mode: "no-cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("res", response);
        let status = JSON.stringify(response.status);
        console.log(response.data.status);
        if (response.data.status == 200) {
          localStorage.setItem("user_id", JSON.stringify(response.data.user.id));
          localStorage.setItem("user_name", JSON.stringify(response.data.user.firstName + " " + response.data.user.lastName));
          localStorage.setItem(
            "authToken",
            JSON.stringify(response.data.token)
          );
          localStorage.getItem("authToken");
          swal({
            title: "Done!",
            text: "Registration Succesfull",
            icon: "success",
            timer: 2000,
            button: false,
          });
          this.props.history.push("/calendar");
        } else {
          swal({
            title: response.data.message,
            icon: "error",
          });
        }
      })

      .catch(function (error) {
        swal({
          title: "Opps!",
          text: error,
          icon: "error",
          timer: 2000,
          button: false,
        });
      });
    }
    const responseGoogle = (response) => {
      console.log(response);
      this.setState({
        email:response.profileObj.email,
        password:response.profileObj.googleId
      })
      console.log(this.state)
      axios
      .post(API_URL+"/api/login", this.state, {
        mode: "no-cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("res", response);
        let status = JSON.stringify(response.status);
        console.log(response.data.status);
        if (response.data.status == 200) {
          localStorage.setItem("user_id", JSON.stringify(response.data.user.id));
          localStorage.setItem("user_name", JSON.stringify(response.data.user.firstName + " " + response.data.user.lastName));
          localStorage.setItem(
            "authToken",
            JSON.stringify(response.data.token)
          );
          localStorage.getItem("authToken");
          swal({
            title: "Done!",
            text: "Registration Succesfull",
            icon: "success",
            timer: 2000,
            button: false,
          });
          this.props.history.push("/calendar");
        } else {
          swal({
            title: response.data.message,
            icon: "error",
          });
        }
      })

      .catch(function ({message}) {
        swal({
          title: "Opps!",
          text: message,
          icon: "error",
          timer: 2000,
          button: false,
        });
      });
    }
    return (
      <section className="section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="card card-primary ">
                <div className="card-header">
                  <h4 className="text-center">Login </h4>
                </div>
                <div className="card-body">
                  <form
                    method="POST"
                    action="#"
                    className="needs-validation"
                    noValidate
                  >
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        name="email"
                        tabIndex={1}
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                        required
                        autofocus
                      />
                      <div className="invalid-feedback">
                        Please fill in your email
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-block">
                        <label htmlFor="password" className="control-label">
                          Password
                        </label>
                        <div className="float-right">
                          <Link
                            to="/forget-password"
                            className="text-small"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={(event) => {
                          this.setState({ password: event.target.value });
                        }}
                        tabIndex={2}
                        required
                      />
                      <div className="invalid-feedback">
                        please fill in your password
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="remember"
                          className="custom-control-input"
                          tabIndex={3}
                          id="remember-me"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                        tabIndex={4}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                  <div className="text-center mt-4 mb-3">
                    <div className="text-job text-muted">Login With Social</div>
                  </div>
                  <div className="row sm-gutters">
                    <div className="col-6">
                      {/* <a className="btn btn-primary btn-social btn-facebook">
                        <span className="fab fa-facebook" /> Facebook
                      </a> */}
                      <FacebookLogin
                        appId="2950024365323503" //APP ID NOT CREATED YET
                        fields="email,id"
                        callback={responseFacebook}
                        className="btn btn-primary btn-social btn-facebook"
                        buttonText="Facebook"
                        icon="fa-facebook fa-1x"
                        textButton="  Facebook"
                        cssClass="btn btn-lg btn-primary w-100"
                      />
                    </div>
                    <div className="col-6">
                      <GoogleLogin
                        clientId="458664555984-7siqcl4o6gfo5313k3hlo9mdc450pv8r.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                        className="btn google-btn-login btn-light w-100 text-center"
                        disabled={false}
                        theme="dark"
                        icon={true}
                        onSuccess={responseGoogle}
                        onFailure={(e) => console.log(e)}
                      >
                        <span>Google</span>
                      </GoogleLogin>
                    </div>
                    <div className="col-md-12 mt-1">
                      <div className="mt-5 text-muted text-center">
                        Don't have an account?{" "}
                        <Link to="/register">Register</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
