import React from 'react';
import {Redirect , Route} from 'react-router-dom';
const ProtectedRoute = ({component:Cmp,...rest}) => {
    return(
    <Route 
        {...rest}
        render={ (props) =>
            localStorage.getItem("authToken") !== null ?
                <Cmp {...props} />
           :
                <Redirect to="/login" />   
        }
    />
)}

export default ProtectedRoute;
