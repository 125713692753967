import React, { Component } from "react";
import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
import "./index.css"
import {NavLink} from 'react-router-dom'

class UserPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      cat_id: 0,
      category: "",
      duration: 0,
      price: 0.0,
      description: "",
      status: "",
      option: "",
    };
  }
  componentDidMount() {
    //Fetch Category

    fetch(API_URL + "/api/categories", {
      method: "get",
    }).then((response) => {
      response.json().then((result1) => {
        console.clear();
        console.log("api responsive", result1.categories);
        let arr = result1.categories.map((val, i) => {
          return (
            <option key={i} value={val.id}>
              {val.name}
            </option>
          );
        });

        this.setState({ option: arr });
      });
    });

    $(".needs-validation").submit((event) => {
      var form = $(event.target);
      console.clear();
      // console.log();
      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        event.preventDefault();
        console.clear();
        console.log(this.state);
        axios
          .post(API_URL + "/api/store_service", this.state, {
            mode: "no-cors",
          })
          .then((response) => {
            let status = JSON.stringify(response.status);
            console.clear();
            console.log(response);
            if (response.data.status == true) {
              swal({
                title: "Done!",
                text: response.data.message + "",
                icon: "success",
                timer: 2000,
                button: false,
              });
              this.props.history.push("/service");
            }
          })
          .catch(function (error) {
            swal({
              title: "Opps!",
              text: error + "",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });
      }
      form.addClass("was-validated");
    });
  }
  render() {
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
        <div className="section container mb-5">
            <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-pills" id="myTab3" role="tablist">
                    <li className="nav-item">
                      <NavLink to="/time-slot" className="nav-link">Staff Working Hours</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/close-date" className="nav-link">Open Date</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/staff-list" className="nav-link">Staff Member</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/user-permission" className="nav-link">User Permission</NavLink>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
          <section className="section">
            
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>User Permission</h4>
                    </div>
                    <div className="card-body">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <form
                              method="POST"
                              action="#"
                              className="needs-validation"
                              noValidate
                            >
                             <div className="container">
                                <div className="row">
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                            <tr>
                                                <th>Bookings & Clients</th>
                                                <th>Basic</th>
                                                <th>Low</th>
                                                <th>Medium</th>
                                                <th>High</th>
                                                <th>Owner</th>    
                                            </tr>
                                            <tr>
                                                <td>Access own calendar</td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td >
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Access other staff calendars</td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Can book appointments</td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Home</td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Clients</td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Can see client contact info</td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Can download clients</td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Messages</td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="pretty p-svg p-curve">
                                                        <input type="checkbox" />
                                                        <div className="state p-success">
                                                            {/* svg path */}
                                                            <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                            <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                            </svg>
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                                <tr>
                                                    <th>Services</th>
                                                    <th>Basic</th>
                                                    <th>Low</th>
                                                    <th>Medium</th>
                                                    <th>High</th>
                                                    <th>Owner</th>    
                                                </tr>
                                                <tr>
                                                    <td>Services</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td >
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Paid Plans</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </table>
                                    </div>
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                                <tr>
                                                    <th>Sales</th>
                                                    <th>Basic</th>
                                                    <th>Low</th>
                                                    <th>Medium</th>
                                                    <th>High</th>
                                                    <th>Owner</th>    
                                                </tr>
                                                <tr>
                                                    <td>Can check out sales</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td >
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Can edit prices at checkout</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Can void invoices</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Daily sales</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Appointments</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Invoices</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Vouchers</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Paid Plans</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        
                                        
                                        </table>
                                    </div>
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                                <tr>
                                                    <th>Staff</th>
                                                    <th>Basic</th>
                                                    <th>Low</th>
                                                    <th>Medium</th>
                                                    <th>High</th>
                                                    <th>Owner</th>    
                                                </tr>
                                                <tr>
                                                    <td>Working hours</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Closed dates</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Staff members</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Permission levels</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Gets notifications about tips</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </table>
                                    </div>
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                                <tr>
                                                    <th>Inventory</th>
                                                    <th>Basic</th>
                                                    <th>Low</th>
                                                    <th>Medium</th>
                                                    <th>High</th>
                                                    <th>Owner</th>    
                                                </tr>
                                                <tr>
                                                    <td>Products</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </table>
                                    </div>
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                                <tr>
                                                    <th>Reports</th>
                                                    <th>Basic</th>
                                                    <th>Low</th>
                                                    <th>Medium</th>
                                                    <th>High</th>
                                                    <th>Owner</th>    
                                                </tr>
                                                <tr>
                                                    <td>All reports</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </table>
                                    </div>
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                                <tr>
                                                    <th>Setup</th>
                                                    <th>Basic</th>
                                                    <th>Low</th>
                                                    <th>Medium</th>
                                                    <th>High</th>
                                                    <th>Owner</th>    
                                                </tr>
                                                <tr>
                                                    <td>Account setup</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Point of Sale</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Client settings</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Online Booking</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Wallet and card processing</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>                                       
                                        </table>
                                    </div>
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                                <tr>
                                                    <th>Vouchers</th>
                                                    <th>Basic</th>
                                                    <th>Low</th>
                                                    <th>Medium</th>
                                                    <th>High</th>
                                                    <th>Owner</th>    
                                                </tr>
                                                <tr>
                                                    <td>View voucher list</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Manage Vouchers</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </table>
                                    </div>
                                    <div className="col-md-12 table-responsive">
                                        <table className="table permission-table table-bordered">
                                                <tr>
                                                    <th>Consultation forms</th>
                                                    <th>Basic</th>
                                                    <th>Low</th>
                                                    <th>Medium</th>
                                                    <th>High</th>
                                                    <th>Owner</th>    
                                                </tr>
                                                <tr>
                                                    <td>Manage consultation forms</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>View Client responses</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Complete consultation forms</td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="pretty p-svg p-curve">
                                                            <input type="checkbox" />
                                                            <div className="state p-success">
                                                                {/* svg path */}
                                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: 'white', fill: 'white'}} />
                                                                </svg>
                                                                <label></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </table>
                                    </div>
                                </div>
                             </div>
                              <div className="row mt-4">
                                <div className="col-md-4 text-left">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block"
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default UserPermission;
