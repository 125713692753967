import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer>
        
        {/* Footer-newsletter */}
        <div className="bg-yellow py-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 mb-md-3 mb-lg-0">
                <div className="row ">
                  <div className="col-md-12 text-center">
                    <h2 className="text-center font-size-30 mb-0 ml-3 text-dark">
                      We believe that everyone deserves to glow
                    </h2>
                  </div>
                 </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Footer-newsletter */}
        {/* Footer-bottom-widgets */}
        <div className="pt-8 pb-4">
          <div className="container mt-1">
            <div className="row">
              <div className="col-lg-5">
                <div className="mb-6">
                  <a href="#" className="d-inline-block">
                    <h2 className="text-dark">Logo</h2>
                  </a>
                </div>
                <div className="mb-4">
                  <div className="row no-gutters">
                    <div className="col-auto">
                      <i className="ec ec-support text-dark font-size-56" />
                    </div>
                    <div className="col pl-3">
                      <div className="font-size-13 text-dark">
                        Got questions? Call us 24/7!
                      </div>
                      <a
                        href="tel:+8008001823232588"
                        className="font-size-20 text-gray-90"
                      >
                        (800) 8000-8288,{" "}
                      </a>
                      
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h6 className="mb-1 font-weight-bold">Contact info</h6>
                  <address className>
                    Lorem Ipsum is simply dummy text of the printing and 
                  </address>
                </div>
                <div className="my-4 my-md-4">
                  <ul className="list-inline mb-0 opacity-7">
                    <li className="list-inline-item mr-0">
                      <a
                        className="btn font-size-20 btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                        href="#"
                      >
                        <span className="fab fa-facebook-f btn-icon__inner" />
                      </a>
                    </li>
                    <li className="list-inline-item mr-0">
                      <a
                        className="btn font-size-20 btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                        href="#"
                      >
                        <span className="fab fa-google btn-icon__inner" />
                      </a>
                    </li>
                    <li className="list-inline-item mr-0">
                      <a
                        className="btn font-size-20 btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                        href="#"
                      >
                        <span className="fab fa-twitter btn-icon__inner" />
                      </a>
                    </li>
                    <li className="list-inline-item mr-0">
                      <a
                        className="btn font-size-20 btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                        href="#"
                      >
                        <span className="fab fa-github btn-icon__inner" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-12 col-md mb-4 mb-md-0">
                    <h6 className="mb-3 font-weight-bold">Partners</h6>
                    {/* List Group */}
                    <ul className="list-group list-group-flush list-group-borderless mb-0 list-group-transparent">
                      <li>
                        <a
                          className="list-group-item list-group-item-action"
                          href=""
                        >
                          Partner Name
                        </a>
                      </li>
                      <li>
                        <a
                          className="list-group-item list-group-item-action"
                          href=""
                        >
                          Partner Name
                        </a>
                      </li>
                      <li>
                        <a
                          className="list-group-item list-group-item-action"
                          href=""
                        >
                          Partner Name
                        </a>
                      </li>
                      <li>
                        <a
                          className="list-group-item list-group-item-action"
                          href=""
                        >
                          Partner Name
                        </a>
                      </li>
                     </ul>
                    {/* End List Group */}
                  </div>
                 <div className="col-12 col-md mb-4 mb-md-0">
                    <h6 className="mb-3 font-weight-bold">Glow Space</h6>
                    {/* List Group */}
                    <ul className="list-group list-group-flush list-group-borderless mb-0 list-group-transparent">
                      <li>
                        <a
                          className="list-group-item list-group-item-action"
                          href=""
                        >
                          Content
                        </a>
                      </li>
                      <li>
                        <a
                          className="list-group-item list-group-item-action"
                          href=""
                        >
                          Content
                        </a>
                      </li>
                    </ul>
                    {/* End List Group */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Footer-bottom-widgets */}
        {/* Footer-copy-right */}
        <div className="py-2">
          <div className="container">
            <div className="flex-center-between d-block d-md-flex">
              <div className="mb-3 mb-md-0">
                ©{" "}
                <a href="#" className="font-weight-bold text-gray-90">
                  Saloon
                </a>{" "}
                - All rights Reserved
              </div>
              <div className="text-md-right">
                <span className="d-inline-block bg-white border rounded p-1">
                  <img
                    className="max-width-5"
                    src="client/assets/img/100X60/img1.jpg"
                    alt="Image Description"
                  />
                </span>
                <span className="d-inline-block bg-white border rounded p-1">
                  <img
                    className="max-width-5"
                    src="client/assets/img/100X60/img2.jpg"
                    alt="Image Description"
                  />
                </span>
                <span className="d-inline-block bg-white border rounded p-1">
                  <img
                    className="max-width-5"
                    src="client/assets/img/100X60/img3.jpg"
                    alt="Image Description"
                  />
                </span>
                <span className="d-inline-block bg-white border rounded p-1">
                  <img
                    className="max-width-5"
                    src="client/assets/img/100X60/img4.jpg"
                    alt="Image Description"
                  />
                </span>
                <span className="d-inline-block bg-white border rounded p-1">
                  <img
                    className="max-width-5"
                    src="client/assets/img/100X60/img5.jpg"
                    alt="Image Description"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* End Footer-copy-right */}
      </footer>
    );
  }
}
