import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="footer-left">
          Copyright © 2020 <div className="bullet" /> Design By{" "}
          <a href="#">HNH TECH SOLUTION</a>
        </div>
        <div className="footer-right"></div>
      </footer>
    );
  }
}
