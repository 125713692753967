import React, { Component } from "react";

import $ from "jquery";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../../BaseURL";
class AddService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      cat_id: 0,
      category: "",
      duration: 0,
      price: 0.0,
      description: "",
      saloon_id: localStorage.getItem("user_id"),
      status: "",
      option: "",
    };
  }
  componentDidMount() {
    //Fetch Category
    let saloonId = localStorage.getItem("user_id")
    fetch(API_URL + "/api/categories?saloon_id=" + saloonId, {
      method: "get",
      headers:{
        mode: "no-cors",
        Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
        
      }
    }).then((response) => {
      response.json().then((result1) => {
        console.clear();
        let arr = result1.categories.map((val, i) => {
          return (
            <option key={i} value={val.id}>
              {val.name}
            </option>
          );
        });

        this.setState({ option: arr });
      });
    });

    $(".needs-validation").submit((event) => {
      var form = $(event.target);
      console.clear();
      // console.log();
      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        event.preventDefault();
       
        axios.post(API_URL + "/api/store_service", this.state, {
          headers:{
            mode: "no-cors",
            Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
            
          }
        })
          .then((response) => {
            let status = JSON.stringify(response.status);
            console.clear();
            console.log(response);
            if (response.data.status == true) {
              swal({
                title: "Done!",
                text: response.data.message + "",
                icon: "success",
                timer: 2000,
                button: false,
              });
              this.props.history.push("/service");
            }
          })
          .catch(function (error) {
            swal({
              title: "Opps!",
              text: error + "",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });
      }
      form.addClass("was-validated");
    });
  }
  render() {
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">

            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Add Service</h4>
                    </div>
                    <div className="card-body">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-9 m-auto">
                            <form
                              method="POST"
                              action="#"
                              className="needs-validation"
                              noValidate
                            >
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Service Name
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    onChange={(event) => {
                                      this.setState({
                                        name: event.target.value,
                                      });
                                    }}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your service name
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Category Name
                                  </label>
                                  {/* <input
                                  id="frist_name"
                                  type="text"
                                  className="form-control"
                                  required
                                /> */}
                                  <select
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    onChange={(event) => {
                                      this.setState({
                                        cat_id: event.target.value,
                                      });
                                    }}
                                    required
                                  >
                                    <option value="">Select Category</option>
                                    {this.state.option}
                                  </select>
                                  <div className="invalid-feedback">
                                    Please select your category name
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Duration of Service
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="time"
                                    className="form-control"
                                    onChange={(event) => {
                                      this.setState({
                                        duration: event.target.value,
                                      });
                                    }}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your duration of service
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">Price</label>
                                  <input
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    onChange={(event) => {
                                      this.setState({
                                        price: event.target.value,
                                      });
                                    }}
                                    // pattern="^\d{1,}[.]\d{2}$"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill your price e.g 100.00
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="frist_name">
                                    Description
                                  </label>
                                  <textarea
                                    className="form-control"
                                    onChange={(event) => {
                                      this.setState({
                                        description: event.target.value,
                                      });
                                    }}
                                    rows={12}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6 m-auto">
                                  <label htmlFor="last_name">Status</label>
                                  <select
                                    className="form-control"
                                    onChange={(event) => {
                                      this.setState({
                                        status: event.target.value,
                                      });
                                    }}
                                    required
                                  >
                                    <option value=""> Select Status</option>
                                    <option value="active">Yes</option>
                                    <option value="deactive">No</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    Please select status
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-6 m-auto ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default AddService;
