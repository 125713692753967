import React, { Component } from "react";

import $ from "jquery";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../../../BaseURL";
class EditSaloon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      phonenumber:"",
      email:"",
      password:"",
      saloon_id: JSON.parse(localStorage.getItem("user_id"))
    }
  }
  
  componentDidMount() {
    fetch(API_URL+"/api/admin/saloon/"+this.props.match.params.id ,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
          },

        }).then((response)=>{
            response.json().then((result)=>{
              console.clear()  
              console.log("Api Response Iss",result)
                this.setState({
                    id : result.saloons.id,
                    firstName:result.saloons.firstName,
                    lastName:result.saloons.lastName,
                    email:result.saloons.email,
                    phonenumber:result.saloons.phonenumber,
                    password:result.saloons.password
                })
            });
        }).catch(err=> console.log(err));

    $(".needs-validation").submit((event) => {
      var form = $(event.target);
      console.clear();
      event.preventDefault();
      event.stopPropagation();
      if (!form[0].checkValidity() === false) {
        event.preventDefault();
        const config = {
          headers:{
            mode: "no-cors",
            Authorization: 'Bearer'+ ' ' + JSON.parse(localStorage.getItem('authToken'))
            
          }
        }
        axios
          .post(API_URL + "/api/admin/saloon/update/"+this.state.id,
          this.state,
          config    
          )
          .then((response) => {
            let status = JSON.stringify(response.status);
            if (response.data.status == true) {
              swal({
                title: "Done!",
                text: response.data.status + "",
                icon: "success",
                timer: 2000,
                button: false,
              });
              this.props.history.push("/saloon");
            }
          })

          .catch(function (error) {
            swal({
              title: "Opps!",
              text: error +"",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });

        // console.log(this.state);
      }
      form.addClass("was-validated");
    });
  }
  render() {
    return (
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <Sidebar />
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Edit Saloon</h4>
                    </div>
                    <div className="card-body">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-9 m-auto">
                            <form
                              method="POST"
                              action="#"
                              className="needs-validation"
                              noValidate
                            >
                              <div className="row">
                                <div className="form-group col-6">
                                  <label htmlFor="frist_name">
                                    First Name
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    value={this.state.firstName}
                                    onChange={(event) => {
                                      this.setState({ firstName: event.target.value });
                                    }}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your First name
                                  </div>
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="frist_name">
                                    Last Name
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    value={this.state.lastName}
                                    onChange={(event) => {
                                      this.setState({ lastName: event.target.value });
                                    }}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your Last name
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6">
                                  <label htmlFor="frist_name">
                                   Phone
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    value={this.state.phonenumber}
                                    onChange={(event) => {
                                      this.setState({ phonenumber: event.target.value });
                                    }}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your Last name
                                  </div>
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="frist_name">
                                    Email
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    value={this.state.email}
                                    onChange={(event) => {
                                      this.setState({ email: event.target.value });
                                    }}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in your Email
                                  </div>
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="frist_name">
                                    Password
                                  </label>
                                  <input
                                    id="frist_name"
                                    type="text"
                                    className="form-control"
                                    value={this.state.password}
                                    onChange={(event) => {
                                      this.setState({ password: event.target.value });
                                    }}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please fill in password
                                  </div>
                                </div>
                              </div>
                           
                              <div className="row mt-4">
                                <div className="col-md-6 m-auto ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default EditSaloon;
