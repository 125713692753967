import React, { Component } from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import {Link , NavLink, withRouter} from 'react-router-dom';
import LOGO from '../assets/img/logo.png';
import userimg from '../assets/img/user.png'
const feather = require('feather-icons');


class Sidebar extends Component {
  constructor(props){
    super(props);
    this.state = {
      username : ""
    }

    
  }
  componentDidMount() {
    this.setState({
      username:localStorage.getItem("user_name"),
    })
    $(document).ready(function(){
        feather.replace();
    let sidebar_nicescroll_opts = {
      cursoropacitymin: 0,
      cursoropacitymax: 0.8,
      zindex: 892,
    };

    var sidebar_nicescroll;
    function update_sidebar_nicescroll() {
      let a = setInterval(function () {
        if (sidebar_nicescroll != null) sidebar_nicescroll.resize();
      }, 10);

      setTimeout(function () {
        clearInterval(a);
      }, 600);
    }
    function toggle_sidebar_mini(mini) {
      let body = $("body");

      if (!mini) {
        body.removeClass("sidebar-mini");
        $(".main-sidebar").css({
          overflow: "hidden",
        });
        setTimeout(function () {
          $(".main-sidebar").niceScroll(sidebar_nicescroll_opts);
          sidebar_nicescroll = $(".main-sidebar").getNiceScroll();
        }, 500);
        $(".main-sidebar .sidebar-menu > li > ul .dropdown-title").remove();
        $(".main-sidebar .sidebar-menu > li > a").removeAttr("data-toggle");
        $(".main-sidebar .sidebar-menu > li > a").removeAttr(
          "data-original-title"
        );
        $(".main-sidebar .sidebar-menu > li > a").removeAttr("title");
      } else {
        body.addClass("sidebar-mini");
        body.removeClass("sidebar-show");
        sidebar_nicescroll.remove();
        sidebar_nicescroll = null;
        $(".main-sidebar .sidebar-menu > li").each(function () {
          let me = $(this);

          if (me.find("> .dropdown-menu").length) {
            me.find("> .dropdown-menu").hide();
            me.find("> .dropdown-menu").prepend(
              '<li class="dropdown-title pt-3">' +
                me.find("> a").text() +
                "</li>"
            );
          } else {
            me.find("> a").attr("data-toggle", "tooltip");
            me.find("> a").attr("data-original-title", me.find("> a").text());
            $("[data-toggle='tooltip']").tooltip({
              placement: "right",
            });
          }
        });
      }
    }

    $("[data-toggle='sidebar']").click(function () {
      var body = $("body"),
        w = $(window);

      if (w.outerWidth() <= 1024) {
        body.removeClass("search-show search-gone");
        if (body.hasClass("sidebar-gone")) {
          body.removeClass("sidebar-gone");
          body.addClass("sidebar-show");
        } else {
          body.addClass("sidebar-gone");
          body.removeClass("sidebar-show");
        }

        // update_sidebar_nicescroll();
      } else {
        body.removeClass("search-show search-gone");
        if (body.hasClass("sidebar-mini")) {
          // toggle_sidebar_mini(false);
        } else {
          // toggle_sidebar_mini(true);
        }
      }

      return false;
    });
    $(".main-sidebar .sidebar-menu li a.has-dropdown")
      .off("click")
      .on("click", function () {
        var me = $(this);

        me.parent()
          .find("> .dropdown-menu")
          .slideToggle(500, function () {
            return false;
          });
        return false;
      });
    $(".menu-toggle").on("click", function (e) {
      var $this = $(this);
      $this.toggleClass("toggled");
    });

    $(document).on("click", function (e) {
      $(".nav-collapse .navbar-nav").removeClass("show");
    });
    });
  }

  render() {
    const role = localStorage.getItem('role_id')
    return (
        
      <div className="main-sidebar sidebar-style-2">
          
      <ReactTooltip/>
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <a href="">
              <img
                alt="image"
                src={LOGO}
                className="header-logo"
              />
              <span className="logo-name">Saloon</span>
            </a>
          </div>
          <div className="sidebar-user">
            <div className="sidebar-user-picture">
              <img alt="image" src={userimg} />
            </div>
            <div className="sidebar-user-details">
              <div className="user-name">{ this.state.username }</div>
              <div className="user-role">Admin</div>
            </div>
          </div>
          <ul className="sidebar-menu">
          {role && role == "1"?
            <li>
              <NavLink className="nav-link" to="/saloon">
                <i data-feather="file" />
                <span>Saloon</span>
              </NavLink>
            </li>
            :
            <>            
            <li>
              <NavLink to="/time-slot" className="nav-link">
                <i data-feather="briefcase" />
                <span>Staff</span>
              </NavLink>
            </li>
  
            <li>
              <NavLink className="nav-link" to="/service">
                <i data-feather="file" />
                <span>Services</span>
              </NavLink>
            </li>
            
            <li>
              <NavLink className="nav-link" to="/calendar">
                <i data-feather="calendar" />
                <span>Calendar</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link" to="/paid-plan">
                <i data-feather="file" />
                <span>Paid Plans</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link" to="/category">
                <i data-feather="file" />
                <span>Category</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link" to="/gallery">
                <i data-feather="file" />
                <span>Gallery</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink className="nav-link" to="/business-hour">
                <i data-feather="file" />
                <span>Business Hour</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink className="nav-link" to="/client">
                <i data-feather="users" />
                <span>Clients</span>
              </NavLink>
            </li>
            <li>
              <a className="nav-link" onClick={() => {
                                  localStorage.clear()
                                  this.props.history.push("/login")
                                }} href="javascript:void(0)">
                <i data-feather="log-out" />
                <span>Log out</span>
              </a>
            </li>
         </>
        }
         
         </ul>
        </aside>
      </div>
    );
  }
}

export default withRouter(Sidebar);