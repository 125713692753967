import React from 'react'
import { Spinner } from 'reactstrap'

export default function Loader() {
    return (
        <div className="schedule-loading-overlay js-loading-overlay ">
            {/* <i class="fa fa-spin fa-refresh" />
            */}
            <Spinner size={30} />
            <h4 className={"mt-4"}>Loading...</h4> 
        </div>
    )
}
