import React, { Component } from 'react'
import {withRouter} from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from '../BaseURL';
class Forget extends Component {
    constructor(props){
        super(props);
        this.state = {
            email:'',
            
        }
    }
    componentDidMount() {
        
        // Bootstrap 4 Validation
        $(".needs-validation").submit((event) => {
          var form = $(event.target);
          console.clear();
          console.log();
          event.preventDefault();
          event.stopPropagation();
          if (!form[0].checkValidity() === false) {
            axios
              .post(API_URL+"/api/GetResetPasswordToken", this.state, {
                mode: "no-cors",
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log("res", response);
                let status = JSON.stringify(response.status);
                console.log(response.data.status);
                if (response.data.status == true) {
                    localStorage.setItem("forgotpwd",JSON.stringify(response.data.token))
                    const email = this.state;
                    this.props.history.push({
                      pathname: '/reset-password',
                      state: { email: email }
                  })
                  swal({
                    title: "Done!",
                    text: response.data.message,
                    icon: "success",
                    timer: 2000,
                    button: false,
                  });
                } else {
                  swal({
                    title: response.data.message + "",
                    icon: "error",
                  });
                }
              })
    
              .catch(function (error) {
                swal({
                  title: "Opps!",
                  text: error + "",
                  icon: "error",
                  timer: 2000,
                  button: false,
                });
              });
            // console.log(this.state);
          }
          form.addClass("was-validated");
        });
      }
    render() {
        return (
            <section className="section">
              <div className="container mt-5">
                <div className="row">
                  <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="card card-primary forget-form">
                      <div className="card-header">
                        <h4 className="text-center">Forget Password </h4>
                      </div>
                      <div className="card-body">
                        <form
                          method="POST"
                          action="#"
                          className="needs-validation"
                          noValidate
                        >
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              id="email"
                              type="email"
                              className="form-control"
                              name="email"
                              tabIndex={1}
                              onChange={(event) => {
                                this.setState({ email: event.target.value });
                              }}
                              required
                              autofocus
                            />
                            <div className="invalid-feedback">
                              Please fill in your email
                            </div>
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg btn-block"
                              tabIndex={4}
                            >
                              Login
                            </button>
                          </div>
                        </form>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
    }
}

export default withRouter(Forget)